// @ts-nocheck
import { createAuth } from '@websanova/vue-auth/src/v3.js';
import driverAuthJWT from './jwtDriver';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';

import router from '@/router';
import { axiosLegacy } from '@/config/http';

import type { RouteRecord } from 'vue-router';
import type { App } from 'vue';

export default (app: App<Element>) => {
  app.use(
    createAuth({
      plugins: {
        http: axiosLegacy, // Axios
        router: router,
      },
      drivers: {
        auth: driverAuthJWT,
        http: driverHttpAxios, // Axios
        router: driverRouterVueRouter,
      },
      options: {
        rolesKey: 'type',
        notFoundRedirect: (transition: RouteRecord) => {
          if (transition.meta?.auth === false) {
            return {
              name: 'home', // or whatever your desired route is named
            };
          }
          return { name: 'page-not-found' };
        },
        forbiddenRedirect: { name: 'login' },
        authRedirect: { name: 'login' },
        loginData: {
          url: 'auth/login/',
          method: 'POST',
          redirect: '/',
          fetchUser: false,
          staySignedIn: true,
        },
        refreshData: {
          url: 'auth/refresh/',
          method: 'POST',
          interval: 5,
        },
        logoutData: {
          redirect: { name: 'login' },
          makeRequest: false,
        },
        fetchData: {
          // it's called automatically unless disabled
          enabled: false,
        },
        tokenDefaultKey: 'access',
        refreshTokenKey: 'refresh',
        staySignedInKey: 'auth_stay_signed_in', // need this or impersonate breaks
        stores: ['storage'],
      },
    }),
  );
};
