import { defineStore } from 'pinia';
import ToursApiService from '@/api/tours';

export const useTourStepsStore = defineStore('tourSteps', {
  state: () => ({
    tourNames: [] as PartnerUserTourInterface[],
    steps: [] as PartnerUserTourStepsInterface[],
  }),
  getters: {},
  actions: {
    async getPartnerUserTour() {
      const response = await ToursApiService.getPartnerUserTours();
      const { partners_profile } = response.data;
      this.tourNames = partners_profile.partner_user_tours;
    },
    async getPartnerUserTourSteps(tourId: string) {
      const response = await ToursApiService.getPartnerUserTourSteps({
        tour_id: tourId,
      });
      const user_tour_step: PartnerUserTourStepsInterface[] =
        response.data.user_tour_step;
      this.steps = user_tour_step;
    },
  },
});

export interface PartnerUserTourInterface {
  id: string;
  tour_name: string;
  tour_pointer_view: string;
}

export interface PartnerUserTourStepsInterface {
  attachTo: AttachTo;
  content: Content;
  options: Options;
}

export interface AttachTo {
  element: string;
}

export interface Content {
  title: string;
  description: string;
}

export interface Options {
  overlay: Overlay;
  scrollToStep: ScrollToStep;
  popper: Popper;
}

export interface Overlay {
  enabled: boolean;
}

export interface ScrollToStep {
  enabled: boolean;
}

export interface Popper {
  placement: string;
}
