import currentSiteMapper from '@/config/currentSiteMapper';

export function instanceWebsocket(route: string) {
  const url = currentSiteMapper();
  const { protocol } = location;

  const wsProtocols = {
    'http:': 'ws:',
    'https:': 'wss:',
  } as const;

  type WsProtocol = keyof typeof wsProtocols;

  const newProtocol = wsProtocols[protocol as WsProtocol];
  const backendUrl = `${newProtocol}//${url.replace('8000', '8330')}/ws/`;
  const socket = new WebSocket(backendUrl + route);
  return socket;
}
