import { createRouter, createWebHistory } from 'vue-router';

import PartnersApp from '@/components/PartnersApp/PartnersApp.vue';
import type { RouterScrollBehavior } from 'vue-router';

const routes = [
  {
    path: '/menu/',
    name: 'QRMenu',
    component: () => import('@/views/QrMenuView/QrMenuView.vue'),
    children: [
      {
        path: ':partnerSlug/',
        name: 'menu-list',
        component: () => import('@/views/QrMenuLocations/QrMenuLocations.vue'),
      },
      {
        path: ':partnerSlug/:partnerLocation/',
        name: 'menu-list-activities',
        component: () => import('@/views/QrMenuListView/QrMenuListView.vue'),
      },
      {
        path: ':partnerSlug/:partnerLocation/:activitySlug/',
        name: 'menu-information',
        component: () =>
          import('@/views/QrMenuInformationView/QrMenuInformationView.vue'),
      },
    ],
  },
  {
    path: '/dev/impersonate',
    name: 'dev-view',
    component: () => import('@/views/DevView/DevView.vue'),
  },
  {
    path: '/',
    name: 'base',
    component: PartnersApp,
    meta: { auth: true, roles: ['admin'], rolesKey: 'type' },
    redirect: { name: 'home' },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/HomeView/HomeView.vue'),
        meta: { auth: true },
      },
      {
        path: 'agenda/',
        name: 'schedule',
        component: () => import('@/views/ScheduleView/ScheduleView.vue'),
        meta: { auth: true },
      },
      {
        path: 'liquidaciones/',
        name: 'liquidations',
        component: () =>
          import('@/views/LiquidationsView/LiquidationsView.vue'),
        meta: { auth: true },
      },
      {
        path: 'liquidaciones/upload/',
        name: 'uploadLiquidation',
        component: () =>
          import('@/views/LiquidationUploadView/LiquidationUploadView.vue'),
        meta: { auth: true },
      },
      {
        path: 'facturacion/crear',
        name: 'createLiquidation',
        component: () =>
          import(
            '@/views/ElectronicLiquidationsUploaderView/ElectronicLiquidationsUploaderView.vue'
          ),
        meta: { auth: true },
      },
      {
        path: 'facturacion/estado/:slug',
        name: 'invoiceUploadStatus',
        component: () =>
          import('@/views/InvoiceUploadStatusView/InvoiceUploadStatusView.vue'),
        meta: { auth: true },

        props: true,
      },
      {
        path: 'liquidacion/:id/',
        name: 'liquidation',
        component: () =>
          import(
            '@/views/CompletedLiquidationDetailsView/CompletedLiquidationDetailsView.vue'
          ),
        meta: { auth: true },

        props: true,
      },
      {
        path: 'configuracion-reservas/',
        name: 'reservation-configuration',
        component: () =>
          import(
            '@/views/ReservationConfigurationView/ReservationConfigurationView.vue'
          ),
        meta: { auth: true },
      },
      {
        path: 'mis-experiencias/',
        name: 'my-experiences',
        component: () => import('@/views/ExperiencesView/ExperiencesView.vue'),
        meta: { auth: true },
      },
      {
        path: `${encodeURIComponent('reseñas')}/`,
        name: 'reviews',
        component: () => import('@/views/ReviewsView/ReviewsView.vue'),
        meta: { auth: true },
      },
      {
        path: 'perfil/',
        name: 'profile',
        component: () => import('@/views/ProfileView/ProfileView.vue'),
        meta: { auth: true },
      },
      // {
      //   path: 'centro-de-ayuda/',
      //   name: 'help-center',
      //   component: () => import('@/views/HelpCenterView/HelpCenterView.vue'),
      //   meta: { auth: true },
      // },

      // {
      //   path: 'centro-de-ayuda/:categorySlug',
      //   name: 'help-center-faq',
      //   component: () =>
      //     import('@/views/HelpCenterFaqView/HelpCenterFaqView.vue'),
      //   meta: { auth: true },
      // },
      {
        path: 'metricas/',
        name: 'charts',
        component: () => import('@/views/MetricsView/MetricsView.vue'),
        meta: { auth: true },
      },
      {
        path: 'beneficios/',
        name: 'benefits',
        component: () => import('@/views/BenefitsView/BenefitsView.vue'),
        meta: { auth: true },
      },
      {
        path: 'inicio/',
        redirect: { name: 'home' },
        meta: { auth: true },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    meta: { auth: false },
    component: () =>
      import(
        /*webpackChunkName: "login"*/ '@/views/BaseLoginView/BaseLoginView.vue'
      ),
  },
  {
    path: '/restablecer-contrasena/:hash',
    name: 'restore-password',
    component: () =>
      import('@/views/RestorePasswordView/RestorePasswordView.vue'),
  },
  {
    path: '/pagina-no-encontrada/',
    name: 'page-not-found',
    component: () => import('@/views/PageNotFoundView/PageNotFoundView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: { name: 'page-not-found', params: {} },
  },
];

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  }
  return { top: 0, left: 0 };
};

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior,
});
