import { defineStore } from 'pinia';

import getCurrency from '@/helpers/getCurrency';
import { formatDMYSlash } from '@/helpers/dateHelpers';
import liquidationStatus from '@/helpers/liquitadionStatus';
import formatedCurrency from '@/helpers/formatedCurrency';
import uniqBy from 'lodash/uniqBy';

import {
  type Liquidation,
  type NewLiquidationData,
  type Validation,
  type AccountingDocument,
  type LiquidationList,
  type PartnerRefund,
} from '@/models/LiquidationInterface';
import type { DetailColumn } from '@/components/Liquidations/TableData/TableData.vue';

import LiquidationsApiService from '@/api/liquidations';

export const useLiquidationsStore = defineStore('liquidations', {
  state: () => ({
    liqs: [] as LiquidationList,
    currentLiquidation: {
      id: '',
      amount: 0,
      codes_amount: 0,
      date_created: '',
      date_modified: '',
      status: '',
      files_amount: 0,
      status_display: '',
      rejected_reason: '',
      documents: [],
      validations: [],
      cancellations: [],
    } as Liquidation,
    newLiquidationData: {
      taxCompanyNumber: '',
      type: '',
      codes: [],
      totalAmount: 0,
      errors: [],
    } as NewLiquidationData,
    refunds: [] as PartnerRefund[],
    retentions: [] as AccountingDocument[],
    codes: [] as Validation[],
    travelCodes: [] as Validation[],
    prepaidCodes: [] as Validation[],
    travelPrepaidCodes: [] as Validation[],
    currency: getCurrency(),
    filteredCode: '',
    liquidationPreferences: {
      date_allowed_to_liquidate: null,
      has_preference: false,
      liquidation_enabled: false,
    },
  }),
  actions: {
    async getLiquidations() {
      const response = await LiquidationsApiService.getLiquidations({
        code: this.filteredCode,
      });

      const { partners_liquidations } = response.data;
      this.liqs = partners_liquidations;
    },
    async getLiquidation(id: string) {
      const response = await LiquidationsApiService.getLiquidation({
        id,
      });
      const { partners_liquidation } = response.data;
      this.currentLiquidation = partners_liquidation;
    },
    async getRefunds() {
      const response = await LiquidationsApiService.getRefunds();
      const { partner_refunds } = response.data;
      this.refunds = partner_refunds;
    },
    async getReservationValidations() {
      const response = await LiquidationsApiService.getReservationValidations();
      const { reservation_validations } = response.data;
      this.codes = reservation_validations;
    },
    async getTravelReservationValidations() {
      const response =
        await LiquidationsApiService.getTravelReservationValidations();
      const { travel_reservation_validations } = response.data;
      this.travelCodes = travel_reservation_validations;
    },

    async getPrepaidValidations() {
      const response = await LiquidationsApiService.getPrepaidValidations();
      const { prepaid_validations } = response.data;

      this.prepaidCodes = prepaid_validations;
    },
    async getTravelPrepaidValidations() {
      const response =
        await LiquidationsApiService.getPrepaidTravelValidations();
      const { travel_prepaid_validations } = response.data;

      this.travelPrepaidCodes = travel_prepaid_validations;
    },
    async getRetentions() {
      const response = await LiquidationsApiService.getRetentions();
      this.retentions = response.data.partners_retentions;
    },

    async setFilteredCode(code: string) {
      this.filteredCode = code;
    },
    setNewLiquidation(payload: NewLiquidationData) {
      this.newLiquidationData = { ...this.newLiquidationData, ...payload };
    },
    async getLiquidationsPreferences() {
      const response =
        await LiquidationsApiService.getsLiquidationPreferences();

      this.liquidationPreferences = response.data.partners_profile;
    },
  },
  getters: {
    documents(state) {
      return state.currentLiquidation.documents.map(item => {
        return {
          id: {
            display: 'ID',
            value: item.id,
          },
          bill_date: {
            display: 'Fecha',
            value: formatDMYSlash(item.bill_date),
            className: 'bb-text-left',
          },
          bill_number: {
            display: 'Factura',
            value: item.bill_number,
            className: 'bb-text-left',
          },
          bill_type: {
            display: 'Tipo',
            value: item.bill_type,
            className: 'bb-text-left',
          },
          url: {
            display: 'Documento',
            value: item.url.split('/').pop() ?? '',
            download: item.url,
            className: 'bb-text-left',
          },
        };
      });
    },
    liquidationCancellations: state =>
      state.currentLiquidation.cancellations.map(item => {
        return {
          id: {
            display: 'ID',
            value: item.code.code,
          },
          code: {
            display: 'Código',
            value: item.code.code,
            className: 'bb-text-left',
          },
          name: {
            display: 'Actividad',
            value: item.activity_name,
            className: 'bb-text-left',
          },
          reservation_date: {
            display: 'Fecha de reserva',
            value: item.reservation_date
              ? formatDMYSlash(item.reservation_date)
              : 'No aplica',
            className: 'bb-text-left',
          },
          cancellation_price: {
            display: 'Precio',
            value: state.currency + formatedCurrency(item.cancellation_price),
            className: 'bb-text-left',
          },
          canellation_reason: {
            display: 'Motivo de Cancelación',
            value: item.cancellation_reason,
            className: 'bb-text-left',
          },
        };
      }),
    allRefunds: state =>
      state.refunds.map(item => {
        return {
          id: {
            display: 'Nota de Crédito',
            value: `NC # ${item.id}`,
            className: 'bb-text-right',
          },
          date_created: {
            display: 'Fecha',
            value: formatDMYSlash(item.date_created),
            className: 'bb-text-right',
          },
          cancellations_amount: {
            display: 'Códigos',
            value: item.cancellations_amount.toString(),
            className: 'bb-text-right',
          },
          bill_number: {
            display: 'Nº de factura',
            value: item.bill_number,
            className: 'bb-text-right',
          },
          status: {
            display: 'Estado',
            value: liquidationStatus(item.status),
            className: 'bb-text-right',
          },
          amount: {
            display: 'Monto Total',
            value: state.currency + formatedCurrency(item.amount),
            className: 'bb-text-right',
          },
        };
      }),
    liquidationBoxes: state =>
      state.currentLiquidation.validations.map(item => {
        return {
          id: {
            display: 'ID',
            value: item.code.code,
          },
          code: {
            display: 'Código',
            value: item.code.code,
            className: 'bb-text-left',
          },
          name: {
            display: 'Actividad',
            value: item.activity_name,
            className: 'bb-text-left',
          },
          first_name: {
            display: 'Nombre',
            value: item.activation_user?.first_name ?? '',
            className: 'bb-text-left',
          },
          last_name: {
            display: 'Apellido',
            value: item.activation_user?.last_name ?? '',
            className: 'bb-text-left',
          },
          reservation_date: {
            display: 'Fecha de reserva',
            value: item.reservation_date
              ? formatDMYSlash(item.reservation_date)
              : 'No aplica',
            className: 'bb-text-left',
          },
          validation_date: {
            display: 'Fecha de validación',
            value: item.validation_date
              ? formatDMYSlash(item.validation_date)
              : '-',
            className: 'bb-text-left',
          },
          validation_price: {
            display: 'Precio',
            value: state.currency + formatedCurrency(item.validation_price),
            className: 'bb-text-left',
          },
        };
      }),

    allLiqs: state =>
      state.liqs.map(item => {
        return {
          id: {
            display: 'ID',
            value: item.id,
          },
          code: {
            display: 'Liquidación',
            value: `Liq # ${item.id}`,
            className: '',
          },
          date_created: {
            display: 'Fecha',
            value: formatDMYSlash(item.date_created),
            className: 'bb-text-right',
          },
          codes_amount: {
            display: 'Códigos',
            value: item.codes_amount.toString(),
            className: 'bb-text-right',
          },
          bill_number: {
            display: 'Nº de factura',
            value: item.bill_number ?? '',
            className: 'bb-text-right',
          },
          status: {
            display: 'Estado',
            value: liquidationStatus(item.status),
            className: 'bb-text-right',
          },
          amount: {
            display: 'Monto Total',
            value: state.currency + formatedCurrency(item.amount),
            className: 'bb-text-right',
          },
          estimated_payment: {
            display: 'Pago Estimado',
            value: item.estimated_payment
              ? formatDMYSlash(item.estimated_payment)
              : '',
            className: 'bb-text-right',
          },
        };
      }) as Record<string, DetailColumn>[],
    liqsInProcess: state =>
      state.liqs
        .filter(liq => {
          return liq.status === 'IN_PROCESS';
        })
        .map(item => {
          return {
            id: {
              display: 'ID',
              value: item.id,
            },
            code: {
              display: 'Liquidación',
              value: `Liq # ${item.id}`,
              className: '',
            },
            date_created: {
              display: 'Fecha',
              value: formatDMYSlash(item.date_created),
              className: 'bb-text-right',
            },
            codes_amount: {
              display: 'Cant. de códigos',
              value: item.codes_amount.toString(),
              className: 'bb-text-right',
            },
            bill_number: {
              display: 'Nº de factura',
              value: item.bill_number,
              className: 'bb-text-right',
            },
            status: {
              display: 'Estado',
              value: liquidationStatus(item.status),
              className: 'bb-text-right',
            },
            amount: {
              display: 'Monto Total',
              value: state.currency + formatedCurrency(item.amount),
              className: 'bb-text-right',
            },
          };
        }) as Record<string, DetailColumn>[],
    liqsPending: state =>
      state.liqs
        .filter(liq => {
          return liq.status === 'PENDING';
        })
        .map(item => {
          return {
            id: {
              display: 'ID',
              value: item.id,
            },
            code: {
              display: 'Liquidación',
              value: `Liq # ${item.id}`,
              className: '',
            },
            date_created: {
              display: 'Fecha',
              value: formatDMYSlash(item.date_created),
              className: 'bb-text-right',
            },
            codes_amount: {
              display: 'Cant. de códigos',
              value: item.codes_amount.toString(),
              className: 'bb-text-right',
            },
            bill_number: {
              display: 'Nº de factura',
              value: item.bill_number,
              className: 'bb-text-right',
            },
            status: {
              display: 'Estado',
              value: liquidationStatus(item.status),
              className: 'bb-text-right',
            },
            amount: {
              display: 'Monto Total',
              value: state.currency + formatedCurrency(item.amount),
              className: 'bb-text-right',
            },
          };
        }) as Record<string, DetailColumn>[],
    liqsApproved: state =>
      state.liqs
        .filter(liq => {
          return liq.status === 'APPROVED';
        })
        .map(item => {
          return {
            id: {
              display: 'ID',
              value: item.id,
            },
            code: {
              display: 'Liquidación',
              value: `Liq # ${item.id}`,
              className: '',
            },
            date_created: {
              display: 'Fecha',
              value: formatDMYSlash(item.date_created),
              className: 'bb-text-right',
            },
            codes_amount: {
              display: 'Cant. de códigos',
              value: item.codes_amount.toString(),
              className: 'bb-text-right',
            },
            bill_number: {
              display: 'Nº de factura',
              value: item.bill_number,
              className: 'bb-text-right',
            },
            status: {
              display: 'Estado',
              value: liquidationStatus(item.status),
              className: 'bb-text-right',
            },
            amount: {
              display: 'Monto Total',
              value: state.currency + formatedCurrency(item.amount),
              className: 'bb-text-right',
            },
          };
        }) as Record<string, DetailColumn>[],
    liqsRejected: state =>
      state.liqs
        .filter(liq => {
          return liq.status === 'REJECTED';
        })
        .map(item => {
          return {
            id: {
              display: 'ID',
              value: item.id,
            },
            code: {
              display: 'Liquidación',
              value: `Liq # ${item.id}`,
              className: '',
            },
            date_created: {
              display: 'Fecha',
              value: formatDMYSlash(item.date_created),
              className: 'bb-text-right',
            },
            codes_amount: {
              display: 'Cant. de códigos',
              value: item.codes_amount.toString(),
              className: 'bb-text-right',
            },
            bill_number: {
              display: 'Nº de factura',
              value: item.bill_number,
              className: 'bb-text-right',
            },
            status: {
              display: 'Estado',
              value: liquidationStatus(item.status),
              className: 'bb-text-right',
            },
            amount: {
              display: 'Monto Total',
              value: item.amount.toString(),
              className: 'bb-text-right',
            },
            rejected_reason: {
              display: 'Motivo de rechazo',
              value: item.rejected_reason,
              className: 'bb-text-left',
            },
          };
        }) as Record<string, DetailColumn>[],
    liqsPayed: state =>
      state.liqs
        .filter(liq => {
          return liq.status === 'PAYED';
        })
        .map(item => {
          return {
            id: {
              display: 'ID',
              value: item.id,
            },
            code: {
              display: 'Liquidación',
              value: `Liq # ${item.id}`,
              className: '',
            },
            date_created: {
              display: 'Fecha',
              value: formatDMYSlash(item.date_created),
              className: 'bb-text-right',
            },
            codes_amount: {
              display: 'Cant. de códigos',
              value: item.codes_amount.toString(),
              className: 'bb-text-right',
            },
            bill_number: {
              display: 'Nº de factura',
              value: item.bill_number,
              className: 'bb-text-right',
            },
            status: {
              display: 'Estado',
              value: liquidationStatus(item.status),
              className: 'bb-text-right',
            },
            amount: {
              display: 'Monto Total',
              value: state.currency + formatedCurrency(item.amount),
              className: 'bb-text-right',
            },
          };
        }) as Record<string, DetailColumn>[],
    codesPending: state =>
      state.codes.map(item => {
        return {
          id: item.id,
          code: item.code.code,
          activity_name: item.activity_name,
          validation_date: formatDMYSlash(item.validation_date),
          validation_price: item.validation_price,
        };
      }),
    travelCodesPending: state =>
      state.travelCodes.map(item => {
        return {
          id: item.id,
          code: item.code.code,
          activity_name: item.activity_name,
          validation_date: formatDMYSlash(item.validation_date),
          validation_price: item.validation_price,
        };
      }),
    prepaidCodesPending: state =>
      state.prepaidCodes.map(item => {
        return {
          id: item.id,
          code: item.code.code,
          activity_name: item.activity_name,
          validation_date: formatDMYSlash(item.validation_date),
          validation_price: item.validation_price,
        };
      }),
    travelPrepaidCodesPending: state =>
      state.travelPrepaidCodes.map(item => {
        return {
          id: item.id,
          code: item.code.code,
          activity_name: item.activity_name,
          validation_date: formatDMYSlash(item.validation_date),
          validation_price: item.validation_price,
        };
      }),
    allRetentions(state) {
      return state.retentions.map(item => {
        return {
          id: {
            display: 'ID',
            value: item.id,
          },
          retention_id: {
            display: 'Número de retenciones',
            value: `# ${item.id}`,
            className: 'bb-text-left',
          },
          bill_date: {
            display: 'Fecha',
            value: formatDMYSlash(item.bill_date),
            className: 'bb-text-center',
          },
          url: {
            display: 'Certificado de retenciones',
            value: item.url.split('/').pop()?.replace('new.', '') ?? '',
            download: item.url,
            className: 'bb-text-left',
          },
        };
      }) as Record<string, DetailColumn>[];
    },
    activityNames: state => {
      const activities = uniqBy(
        state.codes.map(item => {
          return {
            name: item.activity_name,
            id: item.activity_name,
          };
        }),
        'name',
      );
      const defaultOption = {
        name: 'Todas',
        id: '',
      };
      activities.unshift(defaultOption);
      return activities;
    },
  },
});
