import type { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';
import type { Vue } from '@sentry/vue/types/types';

import { version } from '../../../package.json';
import getBackendURL from '@/config/currentSiteMapper';
import { usePartnerInfoStore } from '@/stores/partnerInfoStore';

declare type Nullable<T = void> = T | null | undefined;

const initializeSentry = (app: Vue, router: Router) => {
  const sentryDsn: Nullable<string> = import.meta.env.VITE_SENTRY_DSN;
  const environment: string = import.meta.env.VITE_ENVIRONMENT ?? 'dev';

  if (!sentryDsn || environment === 'dev') return;

  Sentry.init({
    app,
    dsn: sentryDsn,
    release: `${environment}@${version}`,
    environment: environment,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [window.location.origin, getBackendURL()],
      }),
      Sentry.replayCanvasIntegration(),
    ],
    beforeSend: event => {
      const partnerInfoStore = usePartnerInfoStore();
      event.user = partnerInfoStore.profile;
      return event;
    },
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export default initializeSentry;
