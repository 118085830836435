<template>
  <div class="ReservationForm">
    <h3
      class="bb-modal-label bb-mb-3 bb-description-3 title-form bb-text-darkgray bb-mt-4"
    >
      Datos de reserva
    </h3>
    <form class="bb-row relative">
      <div class="date-picker-wrapper">
        <label class="bb-mb-1 bb-text-darkgray bb-semi-bold">Fecha*</label>
        <date-picker
          :value="datetime.date"
          :disabled-dates="disabledRangeDate"
          @select="setDatePickerValue"
        />
        <p
          v-show="
            errors.reservation_date == 'the reservation_date field is required'
          "
          class="form-error bb-mt-1"
        >
          Este campo es requerido
        </p>
      </div>
      <div>
        <label class="bb-mb-1 bb-text-darkgray bb-semi-bold">Hora</label>
        <div class="hour-select">
          <Dropdown
            placeholder="Hora"
            :options="hoursAvailable"
            option-label="name"
            option-value="id"
            :model-value="datetime.hour"
            @update:model-value="setHourValue"
          />

          <span class="divisor">:</span>

          <Dropdown
            :options="minutesAvailable"
            option-label="name"
            option-value="id"
            :model-value="datetime.minute"
            @update:model-value="setMinuteValue"
          />
        </div>
      </div>
    </form>
    <template v-if="peopleCounter">
      <p class="bb-modal-label bb-mb-3">
        Cantidad de personas
        <span class="danger --out">*</span>
      </p>
      <counter-display
        :min="counterMin"
        :max="counterMax"
        :default-value="reservation.reservation.participants"
        @change="setCounterValue"
      /><span class="danger">*</span>
      <p v-if="errors.people" class="form-error">{{ errors.people }}</p>
    </template>
    <h3 class="bb-mb-1 bb-mt-2 bb-text-darkgray title-form">Comentarios</h3>
    <div class="relative">
      <textarea
        id="comments"
        v-model="reservation.reservation.comments"
        name="comments"
        placeholder="Comentarios (opcional)"
        class="comments-textarea bb-textarea bb-mr-0"
        :class="{ 'bb-mt-4': peopleCounter }"
        :maxlength="txtMaxCount"
      ></textarea>
      <p v-if="reservation.reservation.comments" class="comment-lenght">
        {{ reservation.reservation.comments.length }}/{{ txtMaxCount }}
      </p>
      <p v-else class="comment-lenght">0/{{ txtMaxCount }}</p>
    </div>
    <h3
      class="bb-modal-label bb-mb-3 bb-description-3 title-form bb-text-darkgray bb-mt-2"
    >
      Datos del cliente
    </h3>
    <section>
      <form class="bb-row bb-mb-4">
        <div class="bb-column">
          <label class="bb-mb-1" for="name">Nombre*</label>
          <div class="relative">
            <input
              id="name"
              v-model="reservation.partner_client.first_name"
              class="reservation-input bb-input --w-250"
              type="text"
              placeholder="Ej: Martín"
              @keyup="validate"
            />
            <p v-show="errors.first_name" class="form-error">
              Este campo es requerido
            </p>
          </div>
        </div>
        <div class="bb-column">
          <div class="relative">
            <label class="bb-mb-1" for="last_name">Apellido*</label>
            <input
              id="last_name"
              v-model="reservation.partner_client.last_name"
              type="text"
              class="reservation-input bb-input --w-250"
              placeholder="Ej: Gonzalez"
              @keyup="validate"
            />
            <p v-show="errors.last_name" class="form-error">
              Este campo es requerido
            </p>
          </div>
        </div>
      </form>
      <form class="bb-row">
        <div class="relative">
          <label class="bb-mb-1" for="email">Email</label>
          <input
            id="email"
            v-model="reservation.partner_client.email"
            type="email"
            class="reservation-input bb-input --w-250"
            placeholder="ejemplo@gmail.com"
            :disabled="disableEmailField()"
            @keyup="validate"
          />
          <p v-show="errors.email" class="form-error">
            Introduzca un email válido
          </p>
        </div>
        <div>
          <label class="bb-mb-1" for="phone">Teléfono</label>
          <input
            id="phone"
            v-model="reservation.partner_client.phone"
            type="tel"
            class="reservation-input bb-input --w-250"
            placeholder="01123456789"
            @keyup="validate"
          />
        </div>
      </form>
    </section>
    <button-custom
      text="Confirmar"
      class-name="--w-325 bb-mt-4"
      :disabled="!isValid"
      :is-loading="isLoading"
      @click="reserve"
    />
  </div>
</template>
<script setup lang="ts">
  import ButtonCustom from '@/components/Common/ButtonCustom/ButtonCustom.vue';
  import CounterDisplay from '@/components/Common/CounterDisplay/CounterDisplay.vue';
  import DatePicker from '@/components/Common/DatePicker/DatePicker.vue';

  import Validator from '@/helpers/validator';
  import dayjs from 'dayjs';
  import { reservation as reservationObject } from '@/helpers/reservationObjects';
  import { computed, ref } from 'vue';

  import { useReservationScheduleStore } from '@/stores/reservationScheduleStore';
  import Dropdown from 'primevue/dropdown';

  const reservationScheduleStore = useReservationScheduleStore();

  export interface ReservationInitialData {
    partner_client: {
      first_name: string;
      last_name: string;
      email: string;
      phone: string;
    };
    reservation: {
      reservation_date?: string | Date;
      comments: string;
      participants: number;
    };
  }

  interface RulesInterface {
    first_name: string[];
    last_name: string[];
    email: string[];
    phone: string[];
    reservation_date?: string[];
  }
  interface Error {
    reservation_date: string;
    people: string;
    first_name: string;
    last_name: string;
    email: string;
  }

  interface Option {
    id: string;
    name: string;
  }

  interface Props {
    peopleCounter?: boolean;
    // 'set initial data to reservation object'
    reservationInitialData?: ReservationInitialData | null;
    rules: RulesInterface;
    isLoading?: boolean;
  }

  interface ValidableData {
    first_name: string;
    last_name: string;
    phone?: string;
    email?: string;
    reservation_date: Date | null;
    participants?: number;
  }

  const props = withDefaults(defineProps<Props>(), {
    peopleCounter: false,
    isLoading: false,
    reservationInitialData: null,
  });

  const emit = defineEmits<{
    submit: [ReservationInitialData];
  }>();

  const reservation = ref<ReservationInitialData>(
    JSON.parse(JSON.stringify(reservationObject)),
  );
  const rulesCompleted = ref<RulesInterface>(
    JSON.parse(JSON.stringify(props.rules)),
  );
  const isValid = ref(true);
  const hoursAvailable = ref<Option[]>([]);
  const minutesAvailable = ref<Option[]>([]);
  const errors = ref<Error>({
    reservation_date: '',
    people: '',
    first_name: '',
    last_name: '',
    email: '',
  });
  const counterMax = ref(15);
  const counterMin = ref(1);
  const txtMaxCount = ref(100);

  const datetime = computed(() => {
    const { reservation_date } = reservation.value.reservation;
    const today = dayjs().set('minute', 0);

    const reservationDate = reservation_date ? dayjs(reservation_date) : today;
    return {
      date: reservationDate.format('DD/MM/YYYY'),
      hour: reservationDate.format('HH'),
      minute: reservationDate.format('mm'),
    };
  });

  const codeExpirationDate = computed(() => {
    const boxExpiration = reservationScheduleStore.currentBoxExpiration;
    return dayjs(boxExpiration).add(1, 'day').startOf('day').toDate();
  });

  const disabledRangeDate = computed(() => [
    {
      end: minDate(),
      start: null,
    },
    {
      start: codeExpirationDate.value,
      end: null,
    },
  ]);

  // created
  /**
   * Verify if there is initial data.
   * Fill hours and minutes.
   * Set reservation date rules.
   */
  fillMinutes();
  if (props.reservationInitialData) {
    copyReservationInitialData();
  }
  fillHours(dayjs(reservation.value.reservation.reservation_date));
  setReservationDateRules();

  function copyReservationInitialData() {
    reservation.value = JSON.parse(
      JSON.stringify(props.reservationInitialData),
    );
    if (!props.reservationInitialData?.reservation.reservation_date) {
      reservation.value.reservation.reservation_date = dayjs()
        .add(1, 'hour')
        .set('minute', Number(minutesAvailable.value[0].id))
        .toDate();
    }
  }

  function setReservationDateRules() {
    rulesCompleted.value.reservation_date = ['required', 'date'];
  }

  /**
   * Disable client email field reservationInitialData have
   * it already filled.
   */
  function disableEmailField() {
    return Boolean(
      props.reservationInitialData &&
        props.reservationInitialData.partner_client.email,
    );
  }

  /**
   * Fill hours selects.
   * @param reservationDate {Date} cant be null.
   */
  function fillHours(reservationDate: dayjs.Dayjs) {
    hoursAvailable.value = [];
    const today = dayjs().format('YYYY-MM-DD');
    const currentHour = new Date().getHours();
    let i =
      reservationDate.format('YYYY-MM-DD') === today ? currentHour + 1 : 0;
    while (i < 24) {
      const hour = i.toString().padStart(2, '0');
      hoursAvailable.value.push({
        name: hour,
        id: hour,
      });
      i += 1;
    }
  }

  /**
   * Fill minutes selects.
   */
  function fillMinutes() {
    let m = 0;
    while (m < 60) {
      if (m === 0) {
        minutesAvailable.value.push({
          name: '00',
          id: '00',
        });
      } else {
        minutesAvailable.value.push({
          name: `${m}`,
          id: `${m}`,
        });
      }
      m += 15;
    }
  }

  function minDate() {
    const now = new Date();
    const hour = now.getHours();
    const tomorrow = new Date().setDate(now.getDate() + 1);
    let minDate;
    if (hour > 22) {
      minDate = dayjs(tomorrow).toDate();
    } else {
      minDate = dayjs(now).subtract(1, 'day').toDate();
    }
    return minDate;
  }

  function validate() {
    const { reservation_date } = reservation.value.reservation;
    const dataToValidate: ValidableData = {
      first_name: reservation.value.partner_client.first_name,
      last_name: reservation.value.partner_client.last_name,
      phone: reservation.value.partner_client.phone,
      email: reservation.value.partner_client.email,
      reservation_date: reservation_date ? new Date(reservation_date) : null,
    };
    if (props.peopleCounter) {
      if (!reservation.value.reservation.participants) {
        reservation.value.reservation.participants = 1;
      }
      dataToValidate.participants = reservation.value.reservation.participants;
    }
    isValid.value = Validator.make(dataToValidate, rulesCompleted.value);
    errors.value = Validator.getErrors();
  }

  function updateHours() {
    fillHours(dayjs(reservation.value.reservation.reservation_date));
    if (!hoursAvailable.value.some(hour => hour.id === datetime.value.hour)) {
      datetime.value.hour = hoursAvailable.value[0].id;
    }
  }

  function setCounterValue(people: number) {
    reservation.value.reservation.participants = people;
    validate();
  }

  function setDatePickerValue(date: Date) {
    date.setHours(Number(datetime.value.hour), Number(datetime.value.minute));
    reservation.value.reservation.reservation_date = date;
    updateHours();
    validate();
  }

  function setHourValue(hour: string) {
    const reservation_date = dayjs(
      reservation.value.reservation.reservation_date,
    );
    if (reservation_date.isValid()) {
      reservation.value.reservation.reservation_date = reservation_date
        .set('hour', Number(hour))
        .toDate();
    }
    datetime.value.hour = hour;
    validate();
  }

  function setMinuteValue(minute: string) {
    const reservation_date = dayjs(
      reservation.value.reservation.reservation_date,
    );
    if (reservation_date.isValid()) {
      reservation.value.reservation.reservation_date = reservation_date
        .set('minute', Number(minute))
        .toDate();
    }
    datetime.value.minute = minute;
    validate();
  }

  /*
   * Sending reservation data to modify or create
   * with the 'submit' event.
   */
  function reserve() {
    validate();
    if (isValid.value) {
      emit('submit', reservation.value);
    }
  }
</script>
<style lang="scss" scoped>
  label {
    color: $DARK-GRAY;
    display: block;
  }

  .ButtonCustom {
    text-align: center;
  }

  .hour-select {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .title-form {
    font-weight: normal;
  }

  .danger {
    color: $PRIMARY;
    margin-left: -30px;
    margin-right: 25px;
    font-weight: bold;
    font-size: 14px;

    &.--out {
      margin-left: 5px;
    }
  }

  .code-description {
    cursor: pointer;
    position: relative;
    background: #ffffff;
    max-height: 0%;

    .show-details-icon {
      opacity: 0;
      transition: opacity 0.3s;
      margin-left: 10px;

      :deep(svg) {
        width: 25px;
      }
    }

    &:hover {
      .show-details-icon {
        opacity: 1;
      }
    }

    .back-icon {
      cursor: pointer;
      display: none;
      margin: 0 10px 0 0;
      transform: rotateZ(90deg);
      width: 15px;
      z-index: 3;
    }

    .description {
      max-height: 300px;
      overflow: auto;
      display: none;

      :deep(ul) > li {
        font-weight: bold;

        ul {
          margin-left: 20px;

          li {
            font-weight: normal;
          }
        }
      }

      :deep(p) {
        margin-top: 15px;
      }
    }

    &.active {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      max-height: 100%;
      cursor: default;

      .description,
      .back-icon {
        display: block;
      }
    }
  }

  .divisor {
    font-size: 22px;
    font-weight: bold;
    line-height: 36px;
  }

  .title {
    color: $DARK-GRAY;
    font-size: 20px;

    .activity-name {
      font-size: 18px;
    }

    span {
      font-family: $TITLE-FONT;
    }

    .category {
      color: $PRIMARY;
    }
  }
  .comments-textarea {
    height: 155px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    resize: none;
  }

  .comment-lenght {
    position: absolute;
    right: 142px;
    top: 132px;
    font-size: 14px;
    font-weight: 400;
    color: $GRAY-1;
  }

  .people {
    .icon {
      :deep(svg) {
        width: 15px;
      }
    }
  }

  .locationsSelect {
    margin-top: 25px;
  }

  .date-picker-wrapper,
  textarea {
    margin: 0 12px 15px 0;
  }
  input {
    margin: 0 12px 0 0;
  }

  .date-picker-wrapper {
    display: inline-block;
    vertical-align: top;
  }

  .WrapperFull {
    width: 100%;
  }

  textarea {
    width: 401px;
    height: 93px;
    resize: none;
  }

  // StyleFix for selectCustom
  :deep(.input-wrapper) {
    margin-right: -24px;
  }

  .date-picker-wrapper {
    width: 216px;
  }

  .form-error {
    // position: absolute;
    font-size: 14px;
    margin-left: 2px;
    margin-top: 5px;
    color: $PRIMARY;
  }

  .form-error-bottom {
    font-size: 14px;
    color: $PRIMARY;
    top: 20px;
  }

  .reservation-input {
    border-radius: 8px;
  }
</style>
