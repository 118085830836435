<template>
  <base-modal class="email-confirmation-modal" :show="show" @close="closeModal">
    <section v-if="!isSaved">
      <div class="bb-mt-2 icon-container">
        <ImportantIcon class="icon-important" />
      </div>
      <h3 class="title-modal-message bb-description-6">
        Necesitamos que actualices la información de tus contactos antes de
        continuar
      </h3>
      <div class="content-wrapper">
        <div class="content bb-column bb-align-items-center">
          <div
            class="bb-column bb-align-items-center half-width inputs-wrapper"
          >
            <div class="bb-column full-width">
              <label
                class="bb-modal-label label-start bb-mt-4 bb-mb-1"
                for="administration"
              >
                <div class="label-tooltip">
                  Email Administrativo
                  <span class="bb-tooltip-father icon-question-container">
                    <QuestionIcon
                      class="icon-question"
                      @mouseover="showTooltip = true"
                    />
                    <span v-show="showTooltip" class="bb-tooltip">
                      <p class="bb-description-2">
                        El contacto con el que conversaremos sobre
                        liquidaciones, cobranzas y reservas.
                      </p>
                    </span>
                  </span>
                </div>
              </label>
              <input
                v-model="administrationEmail"
                name="administration"
                class="bb-input full-width"
                type="email"
                placeholder="Correo para liquidaciones y cobranzas"
                required
                @keyup="validateAdministrationEmaillInput"
              />
              <div
                v-if="administrationEmailErrors"
                class="error-message bb-bold"
              >
                {{ administrationEmailErrors }}
              </div>
            </div>
            <div class="bb-column full-width">
              <label
                class="bb-modal-label label-start bb-mt-4 bb-mb-1"
                for="comercial"
              >
                <div class="label-tooltip">
                  Email Comercial
                  <span class="bb-tooltip-father icon-question-container">
                    <QuestionIcon
                      class="icon-question"
                      @mouseover="showTooltip = true"
                    />
                    <span v-show="showTooltip" class="bb-tooltip">
                      <p class="bb-description-2">
                        El contacto con el que conversaremos sobre tarifas y
                        novedades.
                      </p>
                    </span>
                  </span>
                </div>
              </label>
              <input
                v-model="comercialEmail"
                name="comercial"
                class="bb-input full-width"
                type="email"
                placeholder="Correo para tarifas y novedades"
                required
                @keyup="validateComercialEmailInput"
              />
              <div v-if="comercialEmailErrors" class="error-message bb-bold">
                {{ comercialEmailErrors }}
              </div>
            </div>
          </div>
          <div class="bb-my-6">
            <div
              class="bb-button --home --white --withBorder bb-bold close-modal-button"
              @click="closeModal"
            >
              <p>Completar más tarde</p>
            </div>

            <button
              class="bb-button --primary bb-bold bb-ml-3 bb-px-3 save-button"
              :class="{ 'disabled-wrapper': !isAllowToSave }"
              @click="submitEmails"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </section>
    <transition v-else>
      <div>
        <div class="bb-mt-2 icon-container congrats-icon-container">
          <ConfirmIcon class="icon-success" />
        </div>
        <h1 class="bb-title-3 congrats-text">
          ¡Gracias, ambos emails han sido actualizados con éxito!
        </h1>
        <button
          class="bb-button --primary bb-bold bb-ml-3 bb-px-3 save-button congrats-button"
          @click="closeModal"
        >
          ¡Continuar!
        </button>
      </div>
    </transition>
  </base-modal>
</template>
<script setup lang="ts">
  import BaseModal from '@/components/Common/BaseModal/BaseModal.vue';

  import ImportantIcon from '@/assets/icons/Important.svg';
  import QuestionIcon from '@/assets/icons/Question.svg';
  import ConfirmIcon from '@/assets/icons/ConfirmIcon.svg';

  import { computed, ref } from 'vue';
  import { usePartnerInfoStore } from '@/stores/partnerInfoStore';
  import { useMutation } from '@tanstack/vue-query';
  import ProfileApiService from '@/api/profile';

  const partnerInfoStore = usePartnerInfoStore();

  const EMAILREGEX =
    /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

  withDefaults(defineProps<{ show?: boolean }>(), { show: false });

  const emit = defineEmits<{ close: [] }>();

  const administrationEmail = ref('');
  const administrationEmailErrors = ref('');
  const comercialEmail = ref('');
  const comercialEmailErrors = ref('');
  const showTooltip = ref(false);
  const isSaved = ref(false);

  const comercialEmailState = computed(() => {
    return partnerInfoStore.companyInformation.company_email;
  });
  const administrationEmailState = computed(() => {
    return partnerInfoStore.companyInformation.email_administration;
  });
  const isAllowToSave = computed(() => {
    return !(
      Boolean(administrationEmailErrors.value) ||
      Boolean(comercialEmailErrors.value)
    );
  });

  const { mutateAsync: updateEmailsProfile } = useMutation({
    mutationFn: ProfileApiService.updatePartnerEmails,
  });

  // created
  administrationEmail.value = administrationEmailState.value;
  comercialEmail.value = comercialEmailState.value;

  function validateAdministrationEmaillInput() {
    if (!EMAILREGEX.test(administrationEmail.value)) {
      administrationEmailErrors.value = 'Debe ser un email';
    } else {
      administrationEmailErrors.value = '';
    }
  }
  function validateComercialEmailInput() {
    if (!EMAILREGEX.test(comercialEmail.value)) {
      comercialEmailErrors.value = 'Debe ser un email';
    } else {
      comercialEmailErrors.value = '';
    }
  }
  async function submitEmails() {
    await updateEmailsProfile({
      email_administration: administrationEmail.value,
      email_comercial: comercialEmail.value,
    }).then(async () => {
      /*  Refetch PartnerProfile data and save it in the store */
      await partnerInfoStore.getPartnerCompanyInformation();
      isSaved.value = true;
    });
  }
  function closeModal() {
    emit('close');
  }
</script>
<style lang="scss">
  .email-confirmation-modal {
    position: relative;
  }
  .inputs-wrapper {
    width: 440px;
    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }

  .icon-container {
    width: $HUGE;
    margin: auto;
  }

  .icon-success {
    color: $WHITE;
    fill: $SUCCESS;
    width: $XXLARGE;
  }

  .icon-question {
    color: $PRIMARY;
    width: $SMALL;
  }

  .icon-important {
    color: $ERROR;
    width: $LARGE;
  }

  .title-modal-message {
    text-align-last: center;
    color: $DARK-GRAY;
    margin: $XSMALL 70px;
  }

  .label-start {
    align-self: flex-start;
  }

  .label-tooltip {
    display: flex;
  }

  .icon-question-container {
    width: $XSMALL;
    margin-left: 10px;
  }

  .error-message {
    color: $DANGER;
    text-align-last: left;
    margin: $TINY 0;
  }

  .close-modal-button {
    & p {
      padding: 6px $BASE;
    }
  }

  .save-button {
    padding: $XXSMALL 80px;
  }

  .congrats-icon-container {
    width: 120px;
  }

  .congrats-text {
    color: $DARK-GRAY;
    margin: $BASE auto;
  }

  .congrats-button {
    margin: $XSMALL 0 $BASE 0;
  }
</style>
