<template>
  <dropdown-menu max-width="200" to-left>
    <div
      class="menu-profile bb-cursor-pointer bb-m-2 bb-row bb-fcenter-y relative"
    >
      <TwoUsers class="bb-mr-2 text-gray" />
      <p v-if="profileTitle">{{ profileTitle }}</p>
      <p v-else>Mi perfil</p>
      <ChevronIcon class="icon-chevron" />
    </div>
    <template #content="content">
      <router-link
        :to="{ name: 'profile' }"
        class="bb-row bb-pt-1 profile-menu-dropdown"
        @click="content.close"
      >
        <ProfileIcon class="icon-profile bb-pr-2 text-gray" />
        <p class="bb-description-3 full-width">Mi perfil</p>
      </router-link>

      <hr class="hr-line" />
      <div
        class="bb-row bb-pt-1 bb-cursor-pointer profile-menu-dropdown"
        @click="logout"
      >
        <LogoutIcon class="bb-pr-2 text-gray" />
        <p class="bb-description-3 full-width">Cerrar sesión</p>
      </div>
    </template>
  </dropdown-menu>
</template>
<script setup lang="ts">
  import { computed } from 'vue';

  import DropdownMenu from '@/components/Common/DropdownMenu/DropdownMenu.vue';

  import { usePartnerInfoStore } from '@/stores/partnerInfoStore';
  import { apolloClient } from '@/config/apolloClientConstructor';
  import { useAuth } from '@websanova/vue-auth/src/v3';

  import ChevronIcon from '@/assets/icons/Chevron.svg';
  import LogoutIcon from '@/assets/icons/Logout.svg';
  import TwoUsers from '@/assets/icons/TwoUsers.svg';
  import ProfileIcon from '@/assets/icons/Profile.svg';

  const partnerInfoStore = usePartnerInfoStore();
  const auth = useAuth();

  const profileTitle = computed(() => {
    if (partnerInfoStore.profile.first_name) {
      return partnerInfoStore.profile.first_name;
    } else {
      return partnerInfoStore.profile.fantasy_name.length > 19
        ? partnerInfoStore.profile.fantasy_name.slice(0, 19) + '...'
        : partnerInfoStore.profile.fantasy_name;
    }
  });

  async function logout() {
    localStorage.removeItem(auth.options!.refreshTokenKey);
    localStorage.removeItem(auth.options!.staySignedInKey);
    await apolloClient.resetStore();
    auth.logout();
  }
</script>
<style lang="scss" scoped>
  .menu-profile {
    padding: 0 10px;
    transition: all 0.3s ease;
  }

  .menu-profile:hover {
    color: $PRIMARY;
    transition: all 0.3s ease;

    :deep(svg) {
      path {
        stroke: $PRIMARY;
        transition: all 0.3s ease;
      }
    }
  }

  .text-gray {
    color: $DARK-GRAY;
  }
  .menu-profile svg {
    width: auto;
    height: 18px;
    text-align: center;

    :deep(path) {
      transition: all 0.3s ease;
    }

    @media (max-width: $screen-sm-max) {
      display: none;
    }
  }

  .icon-profile {
    width: auto;
    height: 22px;
  }

  .profile-menu-dropdown {
    :deep(svg) {
      width: 43px;
    }
  }

  .profile-menu-dropdown:hover {
    color: $PRIMARY;
    transition: all 0.8s ease;

    :deep(svg) {
      path {
        stroke: $PRIMARY;
        transition: all 0.8s ease;
      }
    }
  }
  .menu-profile .icon-chevron {
    color: $DARK-GRAY;
    width: auto;
    height: 25px;
    margin-left: 5px;
    text-align: center;
  }
</style>
