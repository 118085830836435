import type {
  RequestOptions,
  VueAuth,
  VueAuthCreateOptions,
} from '@websanova/vue-auth';

interface ThisInterface extends VueAuthCreateOptions, VueAuth {}
type ResData = {
  access?: string;
  refresh?: string;
};
type DataKey = keyof ResData;

export default {
  request: function (this: ThisInterface, req: RequestOptions, token: string) {
    // If refresh, add refresh_token to request data

    if (req.url === this.options.refreshData.url) {
      req.data = { refresh: this.token(this.options.refreshTokenKey) };
    }

    this.drivers.http.setHeaders.call(this, req, {
      Authorization: `JWT ${token}`,
    });
  },

  response: function (this: ThisInterface, res: RequestOptions) {
    const data: ResData = res.data || {};
    // No implemented yet - Check if response is 401
    // if (this.drivers.http.invalidToken(res)) {
    //   this.token(this.options.refreshTokenKey, null);
    //   this.logout();
    // }

    // Store refresh_token if present
    this.token(
      this.options.refreshTokenKey,
      data[this.options.refreshTokenKey as DataKey],
    );

    // Return access_token (auto stored)
    return data[this.options.tokenDefaultKey as DataKey];
  },
};
