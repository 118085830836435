<template>
  <div class="InputCustom">
    <div class="input-wrapper">
      <input
        :readonly="readOnly"
        :type="type"
        :placeholder="placeholder"
        :class="[
          ...parsedClassName,
          {
            disabled: disabled,
            '--h-small': short,
            'has-icon': icon,
          },
        ]"
        :disabled="disabled"
        :value="text"
        @keyup="change"
      />
      <div v-if="icon" class="icon-wrapper">
        <props.icon class="icon" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { computed, type FunctionalComponent, type SVGAttributes } from 'vue';

  type ClassName = (string | { [key: string]: boolean })[];

  type Props = {
    type?: 'password' | 'text' | 'email' | 'textarea';
    placeholder?: string;
    text?: string | Date;
    disabled?: boolean;
    className?: string | ClassName;
    icon?: FunctionalComponent<SVGAttributes> | null;
    short?: boolean;
    readOnly?: boolean;
  };

  const props = withDefaults(defineProps<Props>(), {
    type: 'text',
    placeholder: '',
    text: '',
    disabled: false,
    className: '',
    icon: null,
    short: false,
    readOnly: false,
  });

  const emit = defineEmits<{ change: [value: string] }>();

  const parsedClassName = computed<ClassName>(() => {
    const className = props.className;
    return Array.isArray(className) ? className : className.split(' ');
  });

  function change(e: Event) {
    const target = e.target as HTMLInputElement;
    emit('change', target.value);
  }
</script>
<style lang="scss" scoped>
  .InputCustom {
    position: relative;

    .input-wrapper {
      position: relative;
      display: flex;

      input {
        min-width: 170px;
        border-radius: 8px;
        border: 1px solid $GRAY-LIGHT;
        min-height: 50px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        transition: border 0.3s;
        font-size: 14px;
        font-family: 'Figtree', sans-serif;

        &:focus,
        &:active {
          border: solid 1px $PRIMARY;
          box-shadow: 0 0 1px 1px $PRIMARY;
          outline: none;
        }

        &.has-icon {
          padding-right: 35px;
        }

        &::placeholder {
          color: $GRAY-LIGHT-1;
        }

        &.--w-170 {
          width: 170px;
        }

        &.--w-200 {
          width: 200px;
        }

        &.--w-250 {
          width: 250px;
        }

        &.--w-325 {
          width: 325px;
        }

        &.--w-440 {
          min-width: 440px;
        }

        &.--w-full {
          display: flex;
          flex: 1 1;
        }

        &.--short,
        &.--h-small {
          min-height: 39px;
        }

        &.disabled {
          background-color: $INACTIVE;
          cursor: not-allowed;
          color: #b0b0b0;
        }

        &.--danger {
          border: 2px $DANGER solid;
        }

        @media (max-width: $screen-sm-min) {
          min-width: 135px;
        }
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
        padding-right: 16px;
        margin-left: -36px;
        color: $DARK-GRAY;

        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
</style>
