<template>
  <nav>
    <div
      class="bb-column navigation-sidebar"
      :class="{ 'navigation-sidebar-collapsed': collapse }"
    >
      <ul class="elements bb-mt-4 bb-ml-4">
        <li
          v-for="element in availableElements"
          :key="element.text"
          class="bb-my-2"
        >
          <button-link
            class="navigation-sidebar-element"
            :text="element.text"
            :to="element.to"
            :lottie-name="element.lottie"
            :hide-text="hideText"
            :show-new="element.showNew"
            :id-button="element.id"
          />
        </li>
      </ul>
      <ul class="elements bb-ml-4">
        <button-link
          class="bb-my-2 navigation-sidebar-element"
          id-button="help-center"
          text="Centro de ayuda"
          :to="link"
          :lottie-name="chatJSON"
          :hide-text="hideText"
        />
      </ul>
      <div
        class="collapse-button bb-row bb-justify-content-center"
        :class="{ 'collapse-button-collapsed': collapse }"
        @click="collapseSidebar"
      >
        <ArrowIcon class="arrow-icon" />
      </div>
    </div>
  </nav>
</template>
<script setup lang="ts">
  import calendarJSON from '@/assets/lotties/calendar.json';
  import chatJSON from '@/assets/lotties/chat.json';
  import chartsJSON from '@/assets/lotties/charts.json';
  import folderJSON from '@/assets/lotties/folder.json';
  import homeJSON from '@/assets/lotties/home.json';
  import paperJSON from '@/assets/lotties/document.json';
  import settingJSON from '@/assets/lotties/setting.json';
  import starJSON from '@/assets/lotties/starSidebar.json';
  import cashJSON from '@/assets/lotties/cash.json';

  import ButtonLink from '@/components/Navigation/ButtonLink/ButtonLink.vue';

  import ArrowIcon from '@/assets/icons/Arrow.svg';
  import { computed, onMounted, ref } from 'vue';
  import { usePartnerInfoStore } from '@/stores/partnerInfoStore';

  const partnerInfoStore = usePartnerInfoStore();

  const hideText = ref(false);

  const elements = ref([
    {
      name: 'home',
      text: 'Inicio',
      lottie: homeJSON,
      to: { name: 'home' },
      userAvailability: true,
      id: '1',
      showNew: false,
    },
    {
      name: 'schedule',
      text: 'Agenda',
      lottie: calendarJSON,
      to: { name: 'schedule' },
      userAvailability: true,
      id: '2',
      showNew: false,
    },
    {
      name: 'liquidations',
      text: 'Facturación',
      lottie: paperJSON,
      to: { name: 'liquidations' },
      userAvailability: true,
      id: '3',
      showNew: false,
    },
    {
      name: 'reservation-configuration',
      text: 'Configurar reservas',
      lottie: settingJSON,
      to: { name: 'reservation-configuration' },
      userAvailability: true,
      id: '4',
      showNew: false,
    },
    {
      name: 'my-experiences',
      text: 'Experiencias',
      lottie: folderJSON,
      to: { name: 'my-experiences' },
      userAvailability: true,
      id: '5',
      showNew: false,
    },
    {
      name: 'reviews',
      text: 'Reseñas',
      lottie: starJSON,
      to: { name: 'reviews' },
      userAvailability: true,
      id: '6',
      showNew: false,
    },
    {
      name: 'charts',
      text: 'Métricas',
      lottie: chartsJSON,
      to: { name: 'charts' },
      userAvailability: true,
      id: '7',
      showNew: false,
    },
    {
      name: 'benefits',
      text: 'Beneficios',
      lottie: cashJSON,
      to: { name: 'benefits' },
      userAvailability: true,
      id: '8',
      showNew: false,
    },
  ]);
  const collapse = ref(false);
  const link = ref('https://ayuda.partners.bigbox.com.ar/lang/es_ar/');

  const partnerInfoState = computed(() => {
    return partnerInfoStore.profile;
  });

  const availableElements = computed(() => {
    return elements.value.filter(element => element.userAvailability);
  });

  // createdavailableElements

  onMounted(() => {
    userNavAvailability();
  });

  function collapseSidebar() {
    collapse.value = !collapse.value;
    hideText.value = !hideText.value;
  }
  function routeName(name: string) {
    return elements.value.find(route => route.name === name);
  }
  /**
   * Show Nav links depending on user's role
   */
  function userNavAvailability() {
    const { role } = partnerInfoState.value;
    if (role === 'validation' || role === 'liquidation') {
      routeName('profile')!.userAvailability = false;
    }
    if (role === 'validation') {
      routeName('liquidations')!.userAvailability = false;
    }
  }
</script>
<style lang="scss" scoped>
  .arrow-icon {
    padding-top: 2px;
    width: 12px;
    height: auto;
    text-align: center;
    transform: rotate(90deg);
    color: $DARK-GRAY;
  }

  .navigation-sidebar {
    background-color: $WHITE;
    justify-content: space-between;
    z-index: 2;
    height: calc(100vh - 70px);
    max-width: 275px;
    position: sticky;
    top: 70px;
    left: 0;
    border-right: 1px solid $GRAY-LIGHT-3;
    &-text {
      color: $DARK-GRAY;
      max-height: 15px;
      display: inline-block;
      white-space: nowrap;
      visibility: visible;
      opacity: 1;
      transition: all 0.6s ease;
      padding-right: 24px;
    }

    &-collapsed {
      max-width: 70px;
      transition: all 0.6s ease;

      .navigation-sidebar-text {
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease;
      }
    }

    &-element {
      :deep(svg) {
        path {
          //stroke: $DARK-GRAY;
          transition: stroke 0.3s ease;
        }
        ellipse {
          transition: stroke 0.3s ease;
          stroke: $DARK-GRAY;
        }
      }
    }

    &-icon {
      :deep(svg) {
        width: 22px;
        height: 22px;
        text-align: center;
        transition: stroke 0.3s ease;
        path {
          stroke: $DARK-GRAY;
          transition: stroke 0.3s ease;
        }
      }
    }

    &-element:hover {
      .navigation-sidebar-text {
        color: $PRIMARY;
      }
      :deep(svg) {
        path {
          transition: stroke 0.3s ease;
          stroke: revert-layer;
        }
        ellipse {
          transition: stroke 0.3s ease;
          stroke: revert-layer;
        }
      }
    }
  }

  .lottie-icon {
    :deep(svg) {
      path {
        stroke: $DARK-GRAY;
      }
    }
  }

  .elements {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }

  .collapse-button {
    position: absolute;
    z-index: 30;
    top: 25px;
    right: -11px;
    width: 24px;
    height: 24px;
    background-color: $WHITE;
    border-radius: 50%;
    border: 1px solid $GRAY-LIGHT-3;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transform: rotate(0deg);
    transition: all 0.3s ease;

    &-collapsed {
      right: -13px;
      transform: rotate(180deg);
      cursor: pointer;
    }
  }
  .button-link {
    position: relative;
    cursor: pointer;

    &-text {
      max-height: 15px;
      width: 190px;
      display: inline-block;
      white-space: nowrap;
      visibility: visible;
      opacity: 1;
      transition: all 0.3s ease;
      padding-right: 24px;
    }
    &-icon {
      padding-top: 4px;
    }
    &-new {
      background-color: $PRIMARY;
      color: $WHITE;
      border-radius: 4px;
      padding: 4px;
      font-size: 12px;
      font-weight: 500;
    }
    &-container:hover {
      .button-link-text {
        color: $PRIMARY;
        transition: color 0.3s ease;
      }
    }
    .--hide {
      width: 0px;
      padding-right: 0;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
</style>
