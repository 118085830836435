<template>
  <base-modal :show="show" class-name="--w-520" @close="closeModal">
    <div class="experience-survey-modal bb-text-left">
      <div class="bb-row bb-fcenter">
        <ParnersLogoIcon class="partner-logo" />
      </div>
      <h1 class="bb-regular bb-description-6 bb-text-darkgray bb-mt-5">
        Ayudanos a mejorar tu experiencia
      </h1>
      <p class="bb-regular bb-text-darkgray bb-description-3 bb-mt-4">
        Es importante para nosotros brindarles el mejor servicio, por eso
        estamos realizando una encuesta que solo te llevará unos minutos.
      </p>
      <div class="bb-row bb-fcenter">
        <button-custom
          class-name="--w-250 bb-mt-6"
          text="Responder encuesta"
          @click="updateStatusModal"
        ></button-custom>
      </div>
    </div>
  </base-modal>
</template>
<script setup lang="ts">
  import { computed } from 'vue';

  import { usePartnerInfoStore } from '@/stores/partnerInfoStore';

  import BaseModal from '@/components/Common/BaseModal/BaseModal.vue';
  import ButtonCustom from '@/components/Common/ButtonCustom/ButtonCustom.vue';

  import ParnersLogoIcon from '@/assets/icons/LogoPartnersPrimary.svg';
  import { useMutation } from '@tanstack/vue-query';
  import HomeApiService from '@/api/home';

  const partnerInfoStore = usePartnerInfoStore();

  const emit = defineEmits<{ close: [] }>();

  defineProps({
    show: {
      type: Boolean,
      default: false,
    },
  });

  const { mutateAsync: sendExperience } = useMutation({
    mutationFn: HomeApiService.sendRequestExperienceSurvey,
  });

  const experienceSurveyForm = computed(
    () => partnerInfoStore.experienceSurvey?.external_form,
  );

  const closeModal = async () => {
    const response = await sendExperience({
      external_form: experienceSurveyForm.value!.id,
    });
    if (response.data?.hide_external_form.ok) {
      emit('close');
    }
    emit('close');
  };

  const updateStatusModal = () => {
    const win = window.open(experienceSurveyForm.value!.link, '_blank');
    if (win) {
      win.focus();
      closeModal();
    }
  };
</script>
<style lang="scss" scoped>
  .experience-survey-modal {
    .partner-logo {
      height: 40px;
    }
  }
</style>
