import axios from 'axios';
import type { App } from 'vue';

import currentSiteMapper from '@/config/currentSiteMapper';
import ssoAuth from '@/config/sso';

// Axios Client for APIs
export const axiosApi = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: { Accept: 'application/json' },
});

axiosApi.interceptors.request.use(
  config => {
    config.headers['Authorization'] = ssoAuth.getToken();

    return config;
  },
  error => Promise.reject(error),
);

// Axios Client for Backend monolith
const host = currentSiteMapper();
const apiUrl = import.meta.env.VITE_API_LEGACY_URL;

export const axiosLegacy = axios.create({
  baseURL: `https://${host}${apiUrl}`,
});

export default {
  install: (app: App<Element>) => {
    app.config.globalProperties.axios = axiosLegacy;
    app.config.globalProperties.$http = axiosLegacy;
  },
};
