import { defineStore } from 'pinia';
import { instanceWebsocket } from '@/config/websockets';
import { type PartnerNotification } from '@/models/NotificationInterface';

let socket: null | WebSocket = null;

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    partnerNotifications: [] as PartnerNotification[],
  }),
  actions: {
    stablishWebsocketConnection(payload: WebsocketConnectionPayloadType) {
      if (socket && socket.readyState === WebSocket.OPEN) return;

      const { partnerUserId } = payload;

      socket = instanceWebsocket(`partner-notifications/${partnerUserId}`);

      socket.onmessage = ({ data: rawData }) => {
        const data = JSON.parse(rawData);
        const newNotifications = this.partnerNotifications.filter(
          notif => notif.kind !== data.message.kind,
        );
        this.partnerNotifications = [data.message, ...newNotifications];
      };
    },
    watchNotifications() {
      if (!socket || socket.readyState !== WebSocket.OPEN) return;

      socket.send(
        JSON.stringify({
          event: 'read_notifications',
        }),
      );
      this.updateNotificationsStatus();
    },
    updateNotificationsStatus() {
      const notifications = this.partnerNotifications.map(notification => {
        if (notification.status === 'sent') {
          return { ...notification, status: 'read' };
        }
        return notification;
      });
      this.partnerNotifications = notifications;
    },
  },
  getters: {
    unreadNotifications: state => {
      return state.partnerNotifications.filter(not => not.status === 'sent');
    },
    readNotifications: state => {
      return state.partnerNotifications.filter(not => not.status === 'read');
    },
  },
});

interface WebsocketConnectionPayloadType {
  partnerUserId: string;
}
