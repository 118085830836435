// @ts-nocheck
import { dateStartEnd } from '@/helpers/dateHelpers';

class Validator {
  constructor() {
    this._errors = [];
    this._data = null;
  }

  make(data, rules) {
    this._errors = [];
    this._data = data;
    for (const i in rules) {
      // eslint-disable-line
      if (!(i in data)) {
        data[i] = null;
      }
    }
    for (const i in this._data) {
      // eslint-disable-line
      if (i in this._data) {
        const value = this._data[i];
        let stop = false;
        for (const j in rules[i]) {
          // eslint-disable-line
          if (j in rules[i]) {
            let rule = rules[i][j];
            let valid;
            const error = {};
            if (!stop) {
              switch (rule) {
                case 'required':
                  valid = this._required(value);
                  break;
                case 'numeric':
                  valid = this._numeric(value);
                  break;
                case 'string':
                  valid = this._string(value);
                  break;
                case 'array':
                  valid = this._array(value);
                  break;
                case 'arrayString':
                  valid = this._arrayString(value);
                  break;
                case 'date':
                  valid = this._date(value);
                  break;
                case 'mail':
                  valid = value ? this._mail(value) : true;
                  break;
                default: {
                  const evaluate = rule.split(':')[1];
                  rule = rule.split(':')[0];
                  switch (rule) {
                    case 'min':
                      valid = this._min(value, parseInt(evaluate, 10));
                      break;
                    case 'max':
                      valid = this._max(value, parseInt(evaluate, 10));
                      break;
                    case 'maxDate': {
                      const date = dateStartEnd(evaluate, 'startOf', 'day');
                      valid = this._maxDate(value, data[evaluate] || date);
                      break;
                    }
                    case 'minDate': {
                      const date = dateStartEnd(evaluate, 'startOf', 'day');
                      valid = this._minDate(value, data[evaluate] || date);
                      break;
                    }
                    default:
                      break;
                  }
                }
              }
              if (!valid) {
                error[i] = rule;
                this._errors.push(error);
                stop = true;
              }
            }
          }
        }
      }
    }
    this._attachErrors();
    return this._errors.length === 0;
  }

  check(rules, section = 'body') {
    return async (req, res, next) => {
      const data = req[section];
      const valid = await this.make(data, rules);
      if (!valid)
        res.status(400).json({ error: true, errors: this.getErrors('array') });
      else {
        next();
      }
    };
  }

  _required(value) {
    return !(
      value === '' ||
      value === null ||
      (this._type(value) === 'array' && value.length === 0)
    );
  }
  _string(value) {
    return !(this._type(value) !== 'string');
  }
  _array(value) {
    return !(this._type(value) !== 'array');
  }
  _arrayString(value) {
    if (this._type(value) !== 'array') {
      return false;
    }
    return value.every(item => this._type(item) === 'string');
  }

  // _numeric(value){ return !(this._type((isNaN(parseInt(value))) ? "string" : parseInt(value)) !== 'number'); }

  _numeric(value) {
    return !(this._type(value) !== 'number');
  }
  _date(value) {
    return !(this._type(value) !== 'date');
  }
  _mail(value) {
    return /(.+)@(.+){2,}\.(.+){2,}/.test(value);
  }
  _min(value, length) {
    return !(value.length < length);
  }
  _max(value, length) {
    return !(value.length > length);
  }
  _minDate(date, minDate) {
    return !(date < minDate);
  }
  _maxDate(date, maxDate) {
    return !(date > maxDate);
  }

  _type(obj) {
    return {}.toString
      .call(obj)
      .match(/\s([a-z|A-Z]+)/)[1]
      .toLowerCase();
  }

  _attachErrors() {
    const errors = this._errors.map(item => {
      for (const i in item) {
        // eslint-disable-line
        if (i in item) {
          const rule = item[i];
          let message;
          switch (rule) {
            case 'required':
              message = `the ${i} field is required`;
              break;
            case 'numeric':
              message = `the ${i} field must be numeric`;
              break;
            case 'date':
              message = `the ${i} field must be a date`;
              break;
            case 'mail':
              message = `the ${i} field must have a valid mail format`;
              break;
            case 'string':
              message = `the ${i} field must be a string`;
              break;
            case 'array':
              message = `the ${i} field must be an array`;
              break;
            case 'arrayString':
              message = `the elements of the array ${i} must be of a string type`;
              break;
            case 'min':
              message = `the ${i} field must have more characters`;
              break;
            case 'max':
              message = `the ${i} field must have fewer characters`;
              break;
            case 'maxDate':
              message = `the ${i} field must be less than the maximum date`;
              break;
            case 'minDate':
              message = `the ${i} field must be greater than the maximum date`;
              break;
            default:
              message = '';
              break;
          }
          item[i] = message;
        }
      }
      return item;
    });
    this._errors = errors;
  }

  getErrors(format = 'json') {
    let output;
    switch (format) {
      case 'string':
        output = JSON.stringify(this._errors);
        break;
      case 'array':
        output = this._errors;
        break;
      default: {
        const errors = {};
        this._errors.forEach(error => {
          const key = Object.keys(error)[0];
          errors[key] = error[key];
        });
        output = errors;
        break;
      }
    }
    return output;
  }
}

export default new Validator();
