import '@/assets/styles/index.scss';
import '@/assets/styles/primeVue/partnersTheme.css';
import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import http from './config/http';
import router from '@/router';
import auth from './config/auth';
import initializeSentry from '@/config/sentry';
import PrimeVue from 'primevue/config';

import {
  apolloClient,
  apolloUploadClient,
} from '@/config/apolloClientConstructor';
import { VueQueryPlugin } from '@tanstack/vue-query';

const pinia = createPinia();
//  inject apollo to Pinia
pinia.use(({ store }) => {
  store.$apollo = markRaw({ apolloClient, apolloUploadClient });
});

const app = createApp(App);

app
  .use(http)
  .use(router)
  .use(pinia)
  .use(auth)
  .use(PrimeVue)
  .use(VueQueryPlugin);

initializeSentry(app, router);
app.mount('#app');
