<template>
  <div class="bigbox-reservation-form-step">
    <div>
      <!-- Propuesta de actividad o Box
        TODO: se puede mover a otro componente -->
      <div
        v-if="descriptionActive"
        class="code-description"
        :class="{ active: descriptionActive }"
      >
        <main>
          <div class="title bb-mt-6 bb-row">
            <p class="category bb-bold" :style="{ color: categoryColor }">
              {{ currentBoxName }}
            </p>
            <span v-if="currentBoxName != ''" class="bb-mx-1">-</span>
            <p class="activity-name">
              {{ currentActivity.name }}
            </p>
          </div>
          <div class="people bb-my-6 bb-row">
            <TwoUsersIcon class="icon bb-mr-1" />
            <p>Para {{ currentActivity.participants }} {{ people }}.</p>
          </div>
          <div
            class="description bb-mt-3"
            v-html="currentActivity.benefits"
          ></div>
        </main>
        <button-custom
          text="Entendido"
          class-name="--w-250 bb-mt-8"
          @click="toggleDescription"
        />
      </div>
      <!-- Fin propuesta -->
      <div :class="{ unactive: descriptionActive }">
        <div class="title bb-row bb-fcenter-y">
          <p class="category bb-bold" :style="{ color: categoryColor }">
            {{ currentBoxName }}
          </p>
          <span v-if="currentBoxName != ''" class="bb-mx-1"> - </span>
          <p class="activity-name bb-regular">
            {{ currentActivity.name }}
          </p>
          <p
            v-if="!descriptionActive"
            class="proposal primary bb-ml-1 bb-description-3 cursor-pointer bb-bold"
            @click="toggleDescription"
          >
            Ver propuesta
          </p>
        </div>
        <div class="bb-mt-3 bb-fcenter-y">
          <div
            class="expiration bb-mr-5 bb-regular bb-description-3 bb-text-darkgray bb-row"
          >
            <CalendarIcon class="icon bb-mr-1" />
            <p>Vencimiento del código:</p>
            <p class="bb-bold bb-ml-1">{{ codeExpirationDate }}</p>
          </div>
          <div
            class="people bb-regular bb-description-3 bb-text-darkgray bb-row"
          >
            <TwoUsersIcon
              v-if="currentActivity.participants != 1"
              class="icon bb-mr-1"
            />
            <p>Para {{ currentActivity.participants }} {{ people }}.</p>
          </div>
        </div>
        <div class="bb-mt-3 bb-row bb-align-items-center">
          <LocationIcon class="location-icon bb-mr-1" />
          <span class="bb-regular bb-description-3 bb-text-darkgray">
            Sucursal: {{ bigboxReservation.reservation.location.name }}
          </span>
        </div>
        <hr class="divider bb-mb-3 bb-mt-3" />
        <reservation-form
          disable-client-inputs
          :rules="reservationRules"
          :reservation-initial-data="reservationInitialData"
          :is-loading="isLoading"
          @submit="createReservation"
        />
        <info-bar
          title="Notificaremos al agasajado por e-mail con los datos de
        la reserva"
          small
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import ButtonCustom from '@/components/Common/ButtonCustom/ButtonCustom.vue';
  import InfoBar from '@/components/Common/InfoBar/InfoBar.vue';
  import ReservationForm, {
    type ReservationInitialData,
  } from '@/components/PartnerManualReservation/ReservationForm/ReservationForm.vue';

  import CalendarIcon from '@/assets/icons/Calendar.svg';
  import LocationIcon from '@/assets/icons/Location.svg';
  import TwoUsersIcon from '@/assets/icons/TwoUsers.svg';

  import pluralizate from '@/helpers/pluralizate';
  import dayjs from 'dayjs';

  import { computed, ref } from 'vue';
  import { useReservationScheduleStore } from '@/stores/reservationScheduleStore';
  import { useAlertsStore } from '@/stores/alertsStore';
  import { type BigboxReservation } from '@/helpers/reservationObjects';
  import type { StatusModalOptions } from '@/components/Common/StatusModal/StatusModal.vue';
  import ReservationConfigurationApiService from '@/api/reservationConfiguration';

  const reservationScheduleStore = useReservationScheduleStore();
  const alertsStore = useAlertsStore();

  interface Props {
    bigboxReservation: BigboxReservation;
  }

  interface Rules {
    first_name: string[];
    last_name: string[];
    email: string[];
    phone: string[];
  }

  const props = defineProps<Props>();

  const emit = defineEmits(['toggleDisabled', 'closeModal']);

  const reservationRules = ref<Rules>({
    first_name: ['string', 'required'],
    last_name: ['string', 'required'],
    email: ['mail'],
    phone: [],
  });
  const descriptionActive = ref(false);
  const isLoading = ref(false);

  const currentBoxName = computed(() => {
    return reservationScheduleStore.currentBoxName;
  });
  const categoryColor = computed(() => {
    return (
      reservationScheduleStore.currentReservationActivity[0].category?.color ??
      '#000'
    );
  });
  /**
   * Verify the participants quantity to display an s.
   * @returns {string} cant be null.
   */
  const people = computed(() => {
    return pluralizate(currentActivity.value.participants, 'persona');
  });

  const reservationInitialData = computed<ReservationInitialData>(() => {
    return {
      partner_client: {
        first_name: props.bigboxReservation.partner_client.first_name || '',
        last_name: props.bigboxReservation.partner_client.last_name || '',
        email: props.bigboxReservation.partner_client.email || '',
        phone: props.bigboxReservation.partner_client.phone || '',
      },
      reservation: {
        reservation_date: undefined,
        comments: props.bigboxReservation.reservation.comments,
        participants:
          props.bigboxReservation.reservation.activity!.participants,
      },
    };
  });

  const codeExpirationDate = computed(() => {
    const boxExpiration = reservationScheduleStore?.currentBoxExpiration;
    return dayjs(boxExpiration).format('DD/MM/YY');
  });
  const currentActivity = computed(() => {
    return props.bigboxReservation.reservation.activity!;
  });
  const currentActivityLocation = computed(() => {
    return props.bigboxReservation.reservation.location;
  });

  async function createReservation(bigboxReservation: ReservationInitialData) {
    emit('toggleDisabled');
    isLoading.value = true;
    const newReservation = {
      ...bigboxReservation,
      reservation: {
        code: props.bigboxReservation.reservation.code!,
        comments: bigboxReservation.reservation.comments,
        activity: currentActivity.value.id,
        participants: currentActivity.value.participants,
        location: currentActivityLocation.value.id,
        reservation_date: bigboxReservation.reservation.reservation_date,
      },
      utc: new Date().getTimezoneOffset() / 60,
    };

    const response =
      await ReservationConfigurationApiService.createBigboxReservation(
        newReservation,
      );

    if (response.data!.partners_create_bigbox_reservation.ok) {
      const statusOptions = {
        title: 'Reserva creada',
        message: 'Se ha creado la reserva correctamente.',
        status: true,
        show: true,
        redirect: true,
        button: 'Ver en agenda',
        reservationInfo:
          response.data!.partners_create_bigbox_reservation.reservation,
      } as StatusModalOptions;
      emit('closeModal');
      /* TODO desvincular el modal del state */
      reservationScheduleStore.creationStatusModal = statusOptions;
      reservationScheduleStore.getMonthlyReservationsQuantity(
        reservationScheduleStore.selectedDate,
      );
    } else {
      const error = response.data!.partners_create_bigbox_reservation.errors;
      alertsStore.addReplace({
        type: 'error',
        message: Object.values(error)[0] as string,
      });
    }
    isLoading.value = false;
    emit('toggleDisabled', false);
  }
  function toggleDescription() {
    descriptionActive.value = !descriptionActive.value;
  }
</script>
<style lang="scss" scoped>
  label {
    color: $DARK-GRAY;
    display: block;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .danger {
    color: $PRIMARY;
    margin-left: -30px;
    margin-right: 25px;
    font-weight: bold;
    font-size: 14px;

    &.--out {
      margin-left: 5px;
    }
  }

  .code-description {
    position: relative;
    background: $WHITE;
    max-height: 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .description {
      color: #464646;
      max-height: 450px;
      overflow: auto;
      display: none;

      :deep(p) {
        margin: 4px 0px;
        font-weight: 700;
      }
      :deep(li) {
        margin-left: 4px;
      }
    }

    &.active {
      position: absolute;
      transform: translateY(5%);
      left: 0;
      top: -70px;
      width: 100%;
      height: 150%;
      z-index: 1;
      max-height: 100%;
      cursor: default;

      @media (max-width: $screen-md-min) {
        transform: translateY(-5%);
      }

      .description {
        display: block;
      }
    }
  }

  .divider {
    border-top: 1px solid #dbdbdb;
    max-width: 500px;
    margin-left: 0;
  }

  .title {
    color: $DARK-GRAY;
    font-size: 20px;

    .activity-name {
      font-size: 18px;
      font-family: $TITLE-FONT;
      padding-top: 1px;
    }

    span {
      font-family: $TITLE-FONT;
    }

    .category {
      color: $PRIMARY;
    }
  }

  .proposal {
    margin-top: 4px;
  }

  .primary {
    color: $PRIMARY;
  }

  .people,
  .expiration {
    .icon {
      width: $XSMALL;
      color: $DARK-GRAY;
    }
  }

  .location-icon {
    width: $XSMALL;
    color: $DARK-GRAY;
  }

  input,
  .date-picker-wrapper,
  textarea {
    margin: 0 12px 15px 0;
  }

  .date-picker-wrapper {
    display: inline-block;
    vertical-align: top;
  }

  .WrapperFull {
    width: 100%;
  }

  textarea {
    width: 348px;
  }

  // StyleFix for selectCustom
  :deep(.input-wrapper) {
    margin-right: -24px;
  }

  .date-picker-wrapper {
    width: 245px;
  }

  .unactive {
    opacity: 0;
  }

  .ReservationForm {
    margin-bottom: 52px;
  }
</style>
