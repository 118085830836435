// Queries
import PARTNER_LOGGED from '@/assets/graphql/queries/PartnerLogged.graphql';

// Mutations
import LOGOUT_MUTATION from '@/assets/graphql/mutations/login/LogoutMutation.graphql';
import RECOVER_PARTNER_PASSWORD_MUTATION from '@/assets/graphql/mutations/login/RecoverPartnerPasswordMutation.graphql';
import RESET_PARTNER_PASSWORD_MUTATION from '@/assets/graphql/mutations/login/ResetPartnerPasswordMutation.graphql';
import LOGIN_MUTATION from '@/assets/graphql/mutations/login/LoginMutation.graphql';

// service
import apolloService from '@/services/apolloService';

import type {
  PartnerLoggedResponse,
  RecoverPasswordResponse,
  ResetPasswordResponse,
  ResetPasswordPayload,
} from './types';

export default class {
  // QUERIES
  static partnerLogged() {
    return apolloService.query<PartnerLoggedResponse>(
      PARTNER_LOGGED,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  // MUTATIONS
  static logout(variables: any) {
    return apolloService.mutate(LOGOUT_MUTATION, variables);
  }
  static recoverPassword(variables: { email: string }) {
    return apolloService.mutate<RecoverPasswordResponse>(
      RECOVER_PARTNER_PASSWORD_MUTATION,
      variables,
    );
  }
  static resetPassword(variables: ResetPasswordPayload) {
    return apolloService.mutate<ResetPasswordResponse>(
      RESET_PARTNER_PASSWORD_MUTATION,
      variables,
    );
  }
  static login(variables: any) {
    return apolloService.mutate(LOGIN_MUTATION, variables);
  }
}
