<template>
  <datepicker
    v-model="date"
    mode="date"
    locale="es"
    is-required
    :popover="false"
    :disabled-dates="disabledDates"
    :first-day-of-week="1"
  >
    <template #default="{ togglePopover }">
      <div @click="!disabled && togglePopover()">
        <slot>
          <input-custom
            read-only
            :class-name="className"
            :placeholder="placeholder"
            :text="selectedDate ?? ''"
            :disabled="disabled"
            :icon="CalendarIcon"
          />
        </slot>
      </div>
    </template>
  </datepicker>
</template>
<script setup lang="ts">
  import { DatePicker as Datepicker } from 'v-calendar';
  import InputCustom from '@/components/Common/InputCustom/InputCustom.vue';
  import { formatDMYSlash } from '@/helpers/dateHelpers';
  import 'v-calendar/style.css';
  import { ref, watch } from 'vue';

  import CalendarIcon from '@/assets/icons/Calendar.svg';

  const emit = defineEmits<{ select: [Date] }>();

  type ClassName = (string | { [key: string]: boolean })[];

  interface Props {
    className?: string | ClassName;
    disabledDates?: DisabledDates; // More info in: https://v2.vcalendar.io/disable-dates.html
    value: Date | string | null;
    disabled?: boolean;
    placeholder?: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    className: '',
    disabledDates: () => [],
    disabled: false,
    placeholder: 'dd/mm/yyyy',
  });

  const selectedDate = ref(formatedValue(true));
  const date = ref(formatedValue());

  watch(date, newValue => {
    const dateToUpdate = newValue as Date;
    selectedDate.value = formatDMYSlash(dateToUpdate);
    emit('select', dateToUpdate);
  });

  watch(
    () => props.value,
    () => {
      selectedDate.value = formatedValue(true);
      date.value = formatedValue();
    },
  );

  /*
   * Formats a value in either string or Date format.
   * the wey we do it its because safari dosent support array as constructor
   * have this format: DD/MM/YYYY
   * @param {boolean} returnHasString - Indicates whether to return the value as a string or a Date.
   * @returns {string|Date} The formatted value as either a string or a Date.
   */
  function formatedValue(returnHasString = false): Props['value'] {
    let value = props.value;
    // TODO: revisar logica
    // Si value viene null, devuelve directamente null, esto es porque typeof null == object
    if (!value) {
      return value;
    }
    // TODO: Despues de la migración hay que fijar el tipo de dato de props.value, ya que no esta bueno variar entre String y Date.
    switch (typeof value) {
      case 'string':
        if (!returnHasString) {
          const [day, month, year] = value.split('/');
          return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
        }
        return value;

      case 'object':
        return returnHasString ? formatDMYSlash(value) : value;

      default:
        return returnHasString ? formatDMYSlash(new Date()) : new Date();
    }
  }
</script>
<style lang="scss">
  .vc-focus:focus-within {
    box-shadow: none;
  }

  .vc-header .vc-arrow:hover,
  .vc-nav-arrow:hover,
  .vc-nav-header button:hover,
  .vc-title:hover {
    color: $PRIMARY;
    background-color: transparent;
    border-radius: $BORDER-RADIUS;
    outline: none;
  }

  .vc-nav-item.is-current,
  .vc-highlight-content-light {
    color: $PRIMARY;
  }

  .vc-highlight-content-solid {
    color: $WHITE;
  }

  .vc-highlight-bg-light,
  .vc-highlight-bg-outline,
  .vc-highlight-bg-none,
  .vc-highlight-content-outline,
  .vc-highlight-content-none {
    background-color: $PRIMARY-HOVER-3;
    color: $PRIMARY;
    border: none;
  }
  .vc-nav-item.is-active,
  .vc-highlight-bg-solid {
    background-color: $PRIMARY;
  }

  .vc-header button,
  .vc-nav-items button,
  .vc-nav-header button {
    background: transparent;
  }
</style>
