<template>
  <div class="help-center-message">
    <slot></slot>
    <transition name="fade">
      <div v-if="show" v-on-click-outside="closeMessage" class="help-message">
        <CloseIcon
          class="close-icon"
          aria-label="Cerrar"
          role="img"
          @click="closeMessage"
        />
        <div class="bb-column bb-semi-bold bb-description-3 bb-mt-1">
          <p>
            ¿{{ translate('Querés') }} conocer cómo funciona el nuevo panel?
          </p>
          <p class="bb-mt-4">
            {{ translate('Mirá') }} el manual
            <a
              :href="showManualByCountry"
              class="bb-text-primary bb-regular"
              target="_blank"
              @click="closeMessage"
              >{{ translate('acá') }}</a
            >
          </p>
          <p class="bb-mt-4">
            Para saber más {{ translate('ingresá') }} al
            <a :href="helpCenterLink" target="_blank">
              <p class="bb-text-primary bb-regular" @click="closeMessage">
                Centro de ayuda
              </p>
            </a>
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup lang="ts">
  import { computed } from 'vue';
  import translate from '@/helpers/translate';
  import { vOnClickOutside } from '@vueuse/components';
  import CloseIcon from '@/assets/icons/Cross.svg';
  import { TLD } from '@/helpers/getLastTld';

  const emit = defineEmits<{ close: [] }>();

  type Props = {
    show: boolean;
  };

  withDefaults(defineProps<Props>(), {
    show: false,
  });

  const isArgCountry = computed(() => {
    return TLD === 'ar';
  });

  const partnersManual = computed(() => {
    return 'https://storage.googleapis.com/web-bigbox/Manual%20de%20prestadores/MANUAL%20GENERAL%20DE%20PRESTADORES.pdf';
  });

  const partnersManualArg = computed(() => {
    return 'https://storage.googleapis.com/partners-public/docs/manual/Manual%20Partners%20.pdf';
  });

  const showManualByCountry = computed(() => {
    return isArgCountry.value ? partnersManualArg.value : partnersManual.value;
  });

  const helpCenterLink = computed(() => {
    return 'https://ayuda.partners.bigbox.com.ar/lang/es_ar/';
  });

  const closeMessage = () => emit('close');
</script>
<style lang="scss" scoped>
  .help-center-message {
    position: relative;

    .help-message {
      width: 293px;
      height: 196px;
      position: absolute;
      border: 1px solid $GRAY-LIGHT-3;
      border-radius: 8px 0px 8px 8px;
      background-color: $WHITE;
      padding: $SMALL;
      z-index: 1010;
      top: calc(100% + 10px);
      right: calc(100% - 20px);
    }

    .close-icon {
      position: absolute;
      color: $DARK-GRAY;
      width: $BASE;
      height: $BASE;
      right: 5px;
      top: 5px;
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
      transition: opacity 0.3s;
    }
    .fade-leave-from,
    .fade-enter-to {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
</style>
