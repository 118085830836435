<template>
  <component
    :is="isExternalLink ? 'a' : 'router-link'"
    v-bind="getLinkAttributes"
    class="button-link"
    @mouseover="playStopAnimation('play')"
    @mouseleave="playStopAnimation('stop')"
  >
    <div class="bb-row button-link-container">
      <Vue3Lottie
        ref="lottieAnimation"
        :animation-data="lottieName"
        :height="32"
        :width="32"
        :auto-play="false"
        class="lottie-icon"
        @on-animation-loaded="statusLoadingLottie"
      />
      <p
        class="button-link-text bb-pt-1 bb-pl-2"
        :class="[{ '--hide': hideText }]"
      >
        {{ text }}
        <span v-if="showNew" class="button-link-new bb-ml-2">NUEVO</span>
      </p>
    </div>
  </component>
</template>
<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { Vue3Lottie } from 'vue3-lottie';
  import { type RouteLocationRaw } from 'vue-router';

  type Props = {
    text: string;
    to: RouteLocationRaw | string;
    idButton: string;
    hideText?: boolean;
    showNew?: boolean;
    lottieName?: object | null;
  };

  const props = withDefaults(defineProps<Props>(), {
    hideText: false,
    showNew: false,
    lottieName: null,
  });

  const loadingLotties = ref(false);

  const lottieAnimation = ref<InstanceType<typeof Vue3Lottie> | null>(null);

  const isExternalLink = computed(() => {
    return props.to.toString().includes('http');
  });

  const getLinkAttributes = computed(() => {
    if (isExternalLink.value) {
      return {
        id: props.idButton,
        href: props.to,
        target: '_blank',
      };
    }
    return {
      id: props.idButton,
      to: props.to,
    };
  });

  function playStopAnimation(status: 'play' | 'stop'): void {
    if (loadingLotties.value) {
      const animation = lottieAnimation.value;
      if (animation) {
        status === 'play' ? animation.play() : animation.stop();
      }
    }
  }
  function statusLoadingLottie() {
    loadingLotties.value = true;
  }
</script>
<style lang="scss" scoped>
  .button-link {
    position: relative;

    &-text {
      max-height: 15px;
      width: 190px;
      display: inline-block;
      white-space: nowrap;
      visibility: visible;
      opacity: 1;
      transition: all 0.3s ease;
      padding-right: 24px;
    }
    &-icon {
      padding-top: 4px;
    }
    &-new {
      background-color: $PRIMARY;
      color: $WHITE;
      border-radius: 4px;
      padding: 4px;
      font-size: 12px;
      font-weight: 500;
    }
    &-container:hover {
      .button-link-text {
        color: $PRIMARY;
        transition: color 0.3s ease;
      }
    }
    .--hide {
      width: 0px;
      padding-right: 0;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease;
    }
  }

  .lottie-icon {
    :deep(svg) {
      path {
        stroke: $DARK-GRAY;
      }
    }
  }

  .router-link-exact-active {
    color: $PRIMARY;
    .lottie-icon {
      :deep(svg) {
        path {
          stroke: $PRIMARY;
        }
      }
    }
  }
</style>
