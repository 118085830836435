<template>
  <div class="notifications-item">
    <hr class="hr-line bb-mb-5" />
    <div class="bb-row relative bb-ml-2">
      <div class="icon-wrapper">
        <Vue3Lottie
          v-if="notificationInformation.lottie"
          ref="lottieAnimation"
          :animation-data="notificationInformation.lottie"
          :height="42"
          :width="42"
          auto-play
        />
      </div>
      <div class="bb-column bb-ml-2">
        <div class="bb-row">
          <h1
            class="notification-title bb-text-left bb-text-darkgray bb-title-1"
          >
            {{ notification.title }}
          </h1>
          <div class="hour-wrapper bb-column bb-fcenter">
            <p class="hour-notification bb-regular bb-description-1">
              {{ formatedDateTime }}
            </p>
          </div>
        </div>
        <router-link :to="notificationInformation.route">
          <div class="status-wrapper relative">
            <p
              class="router-button bb-description-2 bb-text-primary bb-mt-2"
              @click="$emit('close')"
            >
              {{ notificationInformation.text }}
            </p>
            <span
              class="notification-status"
              :class="{ '--unread': notification.status === 'sent' }"
            ></span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { Vue3Lottie } from 'vue3-lottie';

  import calendarJSON from '@/assets/lotties/calendar.json';
  import cashJSON from '@/assets/lotties/cash.json';
  import settingJSON from '@/assets/lotties/setting.json';
  import starNotificationJSON from '@/assets/lotties/starNotification.json';
  import paperJSON from '@/assets/lotties/paper.json';

  import { timeFromNow } from '@/helpers/dateHelpers';
  import { computed } from 'vue';
  import { type RouteLocationRaw } from 'vue-router';
  import { type PartnerNotification } from '@/models/NotificationInterface';

  interface NotificationInfo {
    lottie: object;
    text: string;
    route: RouteLocationRaw;
  }

  interface Props {
    notification: PartnerNotification;
  }

  const props = defineProps<Props>();

  defineEmits<{ close: [] }>();

  const formatedDateTime = computed(() => {
    return timeFromNow(props.notification.date_created);
  });
  const notificationInformation = computed(() => {
    let notification = {} as NotificationInfo;
    switch (props.notification.kind) {
      case 'new_reservation':
        notification = {
          lottie: calendarJSON,
          route: { name: 'schedule' },
          text: 'Ver reservas',
        };
        break;
      case 'cancel_reservation':
        notification = {
          lottie: settingJSON,
          route: { name: 'schedule' },
          text: 'Ver mi agenda',
        };
        break;
      case 'activity_quality_survey':
        notification = {
          lottie: starNotificationJSON,
          route: { name: 'reviews' },
          text: 'Ver reviews',
        };
        break;
      case 'approved_liquidation':
        notification = {
          lottie: cashJSON,
          route: { name: 'liquidations' },
          text: 'Ver liquidaciones',
        };
        break;
      default:
        notification = {
          lottie: paperJSON,
          route: { name: 'liquidations' },
          text: 'Ver liquidaciones',
        };
        break;
    }
    return notification;
  });
</script>
<style lang="scss" scoped>
  .notifications-item {
    position: relative;

    .notification-status {
      position: absolute;
      width: 8px;
      height: 8px;
      top: 5px;
      left: 110px;
      border-radius: 50%;
      margin-left: 160px;
      background-color: transparent;

      &.--unread {
        background-color: $PRIMARY;
      }
    }

    .icon-wrapper {
      width: 56px;
      height: 56px;
      padding-top: 6px;
      background: rgba(255, 108, 94, 0.2);
      box-shadow: 0px 3.5px 14px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }

    .status-wrapper {
      width: 120px;
    }

    .hour-wrapper {
      width: max-content;
      padding: 0 4px 0 4px;
      height: 20px;
      background: #f2f2f2;
      border-radius: 8px;
    }

    .hour-notification {
      color: #828282;
      text-transform: lowercase;
    }

    .hour-notification:first-letter {
      text-transform: uppercase;
    }

    .notification-title {
      width: 230px;
    }

    .router-button {
      font-weight: 600;
    }
  }
</style>
