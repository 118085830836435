// Queries
import PARTNER_NOTIFICATIONS from '@/assets/graphql/queries/home/PartnerNotifications.graphql';
import EXPERIENCE_SURVEY from '@/assets/graphql/queries/home/SetExperienceSurvey.graphql';

//  Mutations
import SEND_CONTACT_NOTIFICACION from '@/assets/graphql/mutations/home/SendContactNotificacion.graphql';
import SEND_REQUEST_EXPERIENCE_SURVEY from '@/assets/graphql/mutations/home/SendRequestExperienceSurvey.graphql';

// service
import apolloService from '@/services/apolloService';

import * as Types from './types';

export default class {
  // QUERIES
  static getPartnerNotifications() {
    return apolloService.query<Types.PartnerNotificationsResponse>(
      PARTNER_NOTIFICATIONS,
    );
  }

  static getExperienceSurvey() {
    return apolloService.query<Types.PartnerExperienceSurveyResponse>(
      EXPERIENCE_SURVEY,
    );
  }

  // MUTATIONS
  static sendContactNotification(variables: { newsletter_id: number }) {
    return apolloService.mutate<Types.SendContactNotificationResponse>(
      SEND_CONTACT_NOTIFICACION,
      variables,
    );
  }

  static sendRequestExperienceSurvey(variables: { external_form: string }) {
    return apolloService.mutate<Types.SendRequestExperienceSurveyResponse>(
      SEND_REQUEST_EXPERIENCE_SURVEY,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
}
