<template>
  <div class="button-custom">
    <button
      class="btn bb-row bb-fcenter-y"
      :disabled="isButtonDisabled"
      :class="[
        ...parsedClassName,
        { disabled: disabled, '--loading': isLoading },
      ]"
      @click.prevent="clicked"
    >
      <props.icon v-if="icon" class="bb-row bb-mr-1" :class="classIcon" />
      <span v-if="!isLoading" class="text">{{ text }}</span>
    </button>
  </div>
</template>
<script setup lang="ts">
  import { computed, type FunctionalComponent, type SVGAttributes } from 'vue';
  import { useRouter } from 'vue-router';

  const router = useRouter();

  type ClassName = (string | { [key: string]: boolean })[];

  interface Props {
    to?: string;
    href?: string;
    text?: string;
    disabled?: boolean;
    className?: string | ClassName;
    classIcon?: string;
    isLoading?: boolean;
    icon?: FunctionalComponent<SVGAttributes> | null;
  }

  const props = withDefaults(defineProps<Props>(), {
    to: '',
    href: '',
    text: 'Button Title',
    disabled: false,
    className: '',
    classIcon: '',
    isLoading: false,
    icon: null,
  });

  const emit = defineEmits<{ click: [] }>();

  const isButtonDisabled = computed<boolean>(() => {
    return props.disabled || props.isLoading;
  });

  const parsedClassName = computed<ClassName>(() => {
    const className = props.className;
    return Array.isArray(className) ? className : className.split(' ');
  });

  function clicked(): void {
    if (props.to) router.push({ name: props.to });
    else if (props.href) {
      const win = window.open(props.href, '_blank');
      win?.focus();
    } else if (!props.disabled) {
      emit('click');
    }
  }
</script>
<style lang="scss" scoped>
  .button-custom {
    position: relative;

    .btn {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: $PRIMARY;
      text-align: center;
      padding: 5px 14px;
      border: none;
      border-radius: 8px;
      min-height: 50px;
      cursor: pointer;
      transition: background-color 0.3s;
      max-width: 100%;

      .text {
        color: $WHITE;
        font-weight: 600;
      }

      &:hover,
      &:focus {
        background-color: $PRIMARY-HOVER-1;
        outline: none;
      }

      &.--outline {
        background-color: $WHITE;
        border: 1px solid $PRIMARY;

        .text {
          color: $PRIMARY;
        }
      }

      &.--wp-100 {
        width: 100%;
      }

      &.--w-136 {
        width: 136px;
        min-height: 38px;
      }

      &.--w-150 {
        width: 150px;
      }

      &.--w-170 {
        width: 170px;
      }

      &.--w-200 {
        width: 200px;
      }

      &.--w-220 {
        width: 220px;
      }

      &.--w-225 {
        width: 225px;
      }

      &.--w-250 {
        width: 250px;
      }

      &.--w-300 {
        width: 300px;
      }

      &.--w-314 {
        width: 314px;
      }

      &.--w-325 {
        width: 325px;
      }

      &.--w-440 {
        width: 440px;
      }

      &.--w-full {
        display: flex;
      }

      &.--h-small {
        min-height: 39px;
      }

      &.dark {
        background-color: $GRAY-LIGHT;
      }

      &.violet {
        background-color: #510bff;
      }

      &.--underline {
        color: white;
        text-decoration: underline;
      }

      &.disabled {
        background-color: $INACTIVE;
        cursor: not-allowed;
      }

      &.--loading {
        pointer-events: none;
        &:after {
          @include bb-loading-icon;
          display: block;
          left: 50%;
          margin-left: -8px;
          margin-top: -8px;
          top: 50%;
          position: absolute !important;
          content: '';
        }

        @keyframes spinAround {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }

      &.--disabled {
        .text {
          color: $INACTIVE;
        }
      }
      &.--tertiary {
        border: none;
        background-color: $WHITE;
        color: $PRIMARY;
        padding: 0px;

        .text {
          color: $PRIMARY;
        }
      }

      &.--transparent {
        border: none;
        background-color: transparent;
        padding: 0px;

        .text {
          color: $WHITE;
        }
      }

      &.--secondary {
        height: 50px;
        border-radius: 8px;
        border: solid 1px $PRIMARY;
        background-color: unset;
        color: $PRIMARY;

        .text {
          color: $PRIMARY;
        }

        &:hover {
          background-color: $PRIMARY;
          border-color: $PRIMARY;

          .text {
            color: $WHITE;
          }
        }

        &.disabled {
          background-color: $INACTIVE;
          cursor: not-allowed;
          border: solid 1px $INACTIVE;

          .text {
            color: $WHITE;
          }
        }
      }
    }

    .icon--primary {
      :deep(svg) {
        width: 18px;
        height: auto;
        text-align: center;

        path {
          stroke: $PRIMARY;
        }
      }
    }

    .icon--white {
      :deep(svg) {
        width: 18px;
        height: auto;
        text-align: center;

        path {
          stroke: $WHITE;
        }
      }
    }
  }
</style>
