import dayjs, { type ManipulateType, type OpUnitType } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import minMax from 'dayjs/plugin/minMax';

import 'dayjs/locale/es';
dayjs.locale('es');
dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(minMax);

type InclusityType = '[]' | '()' | '[)' | '(]' | undefined;
export type StartEndType = 'startOf' | 'endOf';

/**
 * Returns the name and the number of the day, example: 'Lunes 01'
 * @param {string|Date} date
 * @returns {string} 'dddd DD'
 */
export function writtenDay(date: string | Date): string {
  const dateString = dayjs(date).format('dddd DD');
  return capitalize(dateString);
}

/**
 * Returns the name and the number of the day and the name of the month, example: 'Lunes 01 de marzo'
 * @param {string|Date} date
 * @returns {string} 'dddd DD [de] MMMM'
 */
export function writtenDayMonth(date: string | Date): string {
  const dateString = dayjs(date).format('dddd DD [de] MMMM');
  return capitalize(dateString);
}

/**
 * Formates a date as 'DD/MM/YYYY'
 * @param {string|Date} date
 * @param {boolean} withTime if true the format will be 'DD/MM/YYYY hh:mm A', example '06/04/2022 10:17 AM'
 * @returns {string} 'DD/MM/YYYY'
 */
export function formatDMYSlash(
  date: string | Date,
  withTime: boolean = false,
): string {
  const format = withTime ? 'DD/MM/YYYY hh:mm A' : 'DD/MM/YYYY';
  return dayjs(date).format(format);
}

/**
 * Formates a date as 'YYYY-MM-DD'
 * @param {string|Date} date
 * @returns {string} 'YYYY-MM-DD'
 */
export function formatYMDDash(date: string | Date): string {
  return dayjs(date).format('YYYY-MM-DD');
}

/**
 * Formates a date as 'HH:mm' (hour and minutes) example: '16:30'
 * @param {string|Date} date
 * @returns 'HH:mm'
 */
export function formatHm(date: string | Date) {
  return dayjs(date).format('HH:mm');
}

/**
 * Takes a date and its custom format and returns a Date object
 * @param {string|Date} customDate
 * @param {string} customFormat
 * @returns {Date}
 */
export function customFormatToDate(
  customDate: string | Date,
  customFormat: string,
): Date {
  return dayjs(customDate, customFormat).toDate();
}

/**
 * @param {string|Date} date
 * @param {number} amount the amount of units
 * @param {string} type can be 'day', 'week', 'month', etc.
 * @param {function} formatter a function to format the result
 * @returns {Date} by default returns Date object
 */
export function dateSubtract(
  date: string | Date,
  amount: number = 0,
  type: ManipulateType = 'day',
  formatter: Function = (d: any) => d,
): Date {
  const substracted = dayjs(date).subtract(amount, type);
  return formatter(substracted.toDate());
}

/**
 * Returns the difference between two dates
 * @param {string|Date} dateFrom
 * @param {string|Date} dateToDiff
 * @param {string} type can be 'day', 'week', 'month', etc.
 * @returns {number}
 */
export function dateDifference(
  dateFrom: string | Date,
  dateToDiff: string | Date,
  type: ManipulateType = 'day',
): number {
  return dayjs(dateFrom).diff(dateToDiff, type);
}

/**
 * Takes a date and set it to the start/end of a unit of time
 * ```
 *  dateSubtract('03/20/2022','endOf','month') // => Thu Mar 31 2022 23:59:59...
 * ```
 * @param {string|Date} date
 * @param {string} type can be 'startOf' or 'endOf'
 * @param {string} unit can be 'day', 'week', 'month', etc.
 * @returns {Date}
 */
export function dateStartEnd(
  date: string | Date,
  type: StartEndType = 'startOf',
  unit: OpUnitType = 'day',
): Date {
  const types: StartEndType[] = ['startOf', 'endOf'];
  const clean_type = types.includes(type) ? type : types[0];
  const newDate = dayjs(date)[clean_type](unit);
  return newDate.toDate();
}

/**
 * Indicates if a date is the same or after another date
 * @param {string|Date} dateFrom
 * @param {string|Date} dateToCompare
 * @returns {boolean}
 */
export function sameOrAfter(
  dateFrom: string | Date,
  dateToCompare: string | Date,
): boolean {
  return dayjs(dateFrom).isSameOrAfter(dateToCompare);
}

export function timeFromNow(dateFrom: string | Date) {
  return dayjs(dateFrom).fromNow();
}

function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

/**
 * Returns true if date is between dateStart and dateEnd
 * @param {string|Date} date
 * @param {string|Date} dateStart
 * @param {string|Date} dateEnd
 * @param {string} granularity can be 'year', 'month', 'day', etc up to milisesond.
 * @param {string} inclusivity can be '[]', '()', '[)', '(]'
 * @returns {boolean}
 */
export function isDateBetween(
  date: string | Date,
  dateStart: string | Date,
  dateEnd: string | Date,
  granularity: OpUnitType | null | undefined = 'day',
  inclusivity: InclusityType = '[]',
): boolean {
  return dayjs(date).isBetween(dateStart, dateEnd, granularity, inclusivity);
}

/**Return the most distant date or today if array is empty
 * @param {array} datesArr
 * @returns {Date}
 */
export function maxDate(datesArr: Array<Date> = []): Date {
  return dayjs.max(datesArr.map(date => dayjs(date)))?.toDate() ?? new Date();
}

/**
 * Receives full time and returns only hour and minutes
 * @param {string} time "20:15:00"
 * @returns {string} "20:15"
 */
export function removeSecondsFromTime(time: string): string {
  return dayjs(time, 'HH:mm:ss').format('HH:mm');
}
