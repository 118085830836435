import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import queryString from 'query-string';

import currentSiteMapper from '@/config/currentSiteMapper';

const graphqlApiEndpoint: string | undefined = import.meta.env
  .VITE_GRAPHQL_API_ENDPOINT;
const graphqlFileApiEndpoint: string | undefined = import.meta.env
  .VITE_GRAPHQL_FILE_API_ENDPOINT;

function customFetch(uri: string, options: RequestInit) {
  const gqlParams = JSON.parse(options.body as string);
  const queryParams = {
    operationName: gqlParams.operationName,
  };
  uri += `?${queryString.stringify(queryParams)}`;
  return fetch(uri, options);
}

interface ApolloClientBaseOption {
  fetch: typeof customFetch;
}

class ApolloClientBase {
  endpoint: string | undefined;
  options: ApolloClientBaseOption;
  cache: InMemoryCache;
  backendBaseUrl: string;

  constructor() {
    this.endpoint = graphqlApiEndpoint;
    this.options = {
      fetch: customFetch,
    };

    this.cache = new InMemoryCache();

    this.backendBaseUrl = currentSiteMapper();
  }

  get authLink() {
    return setContext((_, { headers }) => {
      const Authorization =
        typeof window !== 'undefined' && localStorage.getItem('access');
      const authorizationHeader = Authorization
        ? { authorization: `JWT ${Authorization}` }
        : {};
      return {
        headers: {
          ...headers,
          ...authorizationHeader,
        },
      };
    });
  }

  get backendUrl() {
    const url = this.backendBaseUrl;
    return `https://${url}/${this.endpoint}/`;
  }

  getLinkObject(): HttpLink | ApolloLink {
    return new HttpLink(
      Object.assign(
        {
          uri: this.backendUrl,
          credentials: 'same-origin',
        },
        this.options,
      ),
    );
  }

  getApolloClient() {
    const link = this.authLink.concat(this.getLinkObject());
    return new ApolloClient({ link, cache: this.cache });
  }
}

class ApolloClientUpload extends ApolloClientBase {
  constructor() {
    super();
    this.endpoint = graphqlFileApiEndpoint;
  }
  getLinkObject() {
    return createUploadLink(
      Object.assign({
        uri: this.backendUrl,
        credentials: 'same-origin',
      }),
    );
  }
}

export const apolloClient = new ApolloClientBase().getApolloClient();
export const apolloUploadClient = new ApolloClientUpload().getApolloClient();

export default ApolloClientBase;
