<template>
  <transition name="fade">
    <div
      v-if="show"
      class="base-modal bb-column bb-justify-content-center bb-align-items-center"
    >
      <div class="overlay" @click="$emit('close')"></div>
      <div
        class="wrapper"
        :class="[fullScreen, className]"
        :style="stylesImage"
      >
        <CrossIcon class="close" @click="$emit('close')" />
        <div class="content" :class="{ 'scroll-content': scrollBar }">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup lang="ts">
  import CrossIcon from '@/assets/icons/Cross.svg';
  import { computed } from 'vue';

  type Props = {
    show: boolean; //  prop to toggle modal.
    full?: boolean; // prop to show modal in full screen mode.
    widthMedium?: boolean;
    backGround?: string;
    className?: string;
    scrollBar?: boolean;
  };

  const props = withDefaults(defineProps<Props>(), {
    show: false,
    full: false,
    widthMedium: false,
    backGround: '',
    className: '',
    scrollBar: false,
  });

  defineEmits<{ close: [] }>();

  const fullScreen = computed(() => {
    return props.full ? '--full-screen' : '';
  });
  const stylesImage = computed(() => {
    return props.backGround
      ? `background-color: #e0e0e0;background-image: url(${props.backGround});background-repeat: no-repeat;background-size: cover;`
      : '';
  });
</script>
<style lang="scss" scoped>
  .base-modal {
    position: fixed;
    z-index: $HEADER-Z-INDEX + 1;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    .overlay {
      position: fixed;
      z-index: $HEADER-Z-INDEX + 1;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
    }

    .close {
      position: absolute;
      color: $DARK-GRAY;
      width: 42px;
      height: 42px;
      right: 5px;
      top: 5px;
      cursor: pointer;
      z-index: 2;
    }
  }

  .wrapper {
    background-color: $WHITE;
    text-align: center;
    position: relative;
    z-index: $HEADER-Z-INDEX + 100;
    width: 80%;
    max-width: 700px;
    padding: 48px 40px;
    border-radius: 8px;

    @media (max-width: $screen-sm-max) {
      overflow-y: scroll;
      padding: 20px;
      width: 100%;
      max-width: 500px;
      max-height: 100%;

      &.--full-screen {
        max-width: 100%;
        height: 100%;
        width: 100%;
      }
    }

    &.--w-420 {
      max-width: 420px;
    }

    &.--w-520 {
      max-width: 520px;
    }

    &.--w-620 {
      max-width: 620px;
    }

    &.--w-700 {
      max-width: 700px;
    }

    &.--w-730 {
      max-width: 730px;
    }

    .content {
      min-height: 100px;
      height: 100%;

      &.scroll-content {
        overflow-y: auto;
      }
    }
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
    transition: opacity 0.3s;
  }
  .fade-leave-from,
  .fade-enter-to {
    opacity: 1;
    transition: opacity 0.3s;
  }
</style>
