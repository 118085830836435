<template>
  <v-onboarding-wrapper ref="tourWrapper" :steps="parsedSteps">
    <template #default="{ previous, next, step, isFirst, isLast }">
      <transition name="fade">
        <v-onboarding-step>
          <div v-if="step.content" class="step-content">
            <p v-if="step.content.title" class="step-title">
              {{ step.content.title }}
            </p>
            <div v-if="step.content.description" class="step-description">
              <p>{{ step.content.description }}</p>
            </div>
          </div>
          <div class="step-actions">
            <button-custom
              v-if="!isFirst"
              class-name="--secondary"
              text="Anterior"
              @click="previous"
            />
            <button-custom
              v-else
              class-name="--secondary"
              text="Saltar"
              @click="finishPartnerTour"
            />
            <button-custom v-if="!isLast" text="Siguiente" @click="next" />
            <button-custom v-else text="Terminar" @click="finishPartnerTour" />
          </div>
        </v-onboarding-step>
      </transition>
    </template>
  </v-onboarding-wrapper>
</template>
<script setup lang="ts">
  import {
    VOnboardingWrapper,
    VOnboardingStep,
    useVOnboarding,
  } from 'v-onboarding';
  import { computed, onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import {
    useTourStepsStore,
    type Popper,
    type PartnerUserTourInterface,
  } from '@/stores/tourStepsStore';
  import ButtonCustom from '@/components/Common/ButtonCustom/ButtonCustom.vue';
  import { useMutation } from '@tanstack/vue-query';
  import ToursApiService from '@/api/tours';

  const tourWrapper = ref<InstanceType<typeof VOnboardingWrapper> | null>(null);
  const { start: startTour, finish: finishTour } = useVOnboarding(tourWrapper);

  const tourStepsStore = useTourStepsStore();
  const route = useRoute();

  const toursPendingToShow = ref<PartnerUserTourInterface[]>([]);
  const currentTour = ref<PartnerUserTourInterface | null>(null);

  const parsedSteps = computed(() =>
    tourStepsStore.steps.map(step => {
      const { placement, ...popperOptions } = step.options.popper;
      if (placement !== 'auto') {
        (popperOptions as Popper).placement = placement;
      }

      return {
        ...step,
        options: {
          ...step.options,
          overlay: {
            ...step.options.overlay,
            borderRadius: 10,
            padding: 2,
          },
          popper: {
            ...popperOptions,
            modifiers: [
              {
                name: 'computeStyles',
                options: {
                  gpuAcceleration: false,
                },
              },
            ],
          },
          scrollToStep: {
            ...step.options.scrollToStep,
            enabled: true,
            options: {
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            },
          },
        },
      };
    }),
  );

  const { mutate: updatePartnerTourMutation } = useMutation({
    mutationFn: ToursApiService.updatePartnerTour,
  });

  const shouldStartTour = (tour: PartnerUserTourInterface): boolean => {
    return (
      tour.tour_pointer_view.includes(String(route.name)) ||
      tour.tour_pointer_view.includes('layout')
    );
  };

  const finishPartnerTour = () => {
    finishTour();
    setCurrentTourRead();
    checkForNextTour();
  };

  const checkForNextTour = async () => {
    for (const tour of toursPendingToShow.value) {
      if (shouldStartTour(tour)) {
        await tourStepsStore.getPartnerUserTourSteps(tour.id);
        if (!document.querySelector(tourStepsStore.steps[0].attachTo.element))
          continue; // Temporal fix
        currentTour.value = tour;
        startTour();
        break;
      }
    }
  };

  const setCurrentTourRead = () => {
    updatePartnerTourMutation({
      tour_id: currentTour.value!.id,
      is_read: true,
    });
    toursPendingToShow.value = toursPendingToShow.value.filter(
      tour => tour.id != currentTour.value?.id,
    );
    currentTour.value = null;
  };

  onMounted(async () => {
    await tourStepsStore.getPartnerUserTour();
    toursPendingToShow.value = tourStepsStore.tourNames;
    checkForNextTour();
  });

  watch(
    () => route.name,
    () => {
      if (
        toursPendingToShow.value.length &&
        currentTour.value?.tour_pointer_view != 'layout'
      ) {
        finishTour();
        checkForNextTour();
      }
    },
  );
</script>
<style lang="scss" scoped>
  .step-content {
    font-weight: 400;
    max-width: 312px;

    .step-title {
      margin: -10px -10px 5px;
      padding: 5px;
      font-weight: 600;
      font-size: 18px;
    }
    .step-description {
      margin: 0 0 10px;
      padding-bottom: 10px;
      font-size: 14px;
    }
  }

  :deep([data-popper-placement]) {
    margin: 10px !important;
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    color: $DARK-GRAY;
    text-align: center;

    .step-actions {
      display: flex;
      justify-content: center;

      button {
        width: 125px;
        margin: 0 0.2rem;
      }
    }

    // Arrow style and position
    [data-popper-arrow]:before {
      content: '';
      background: white;
      top: 0;
      left: 0;
      transition:
        transform 0.2s ease-out,
        visibility 0.2s ease-out;
      visibility: visible;
      transform: translate(10px) rotate(45deg);
      transform-origin: center;
      width: 10px;
      height: 10px;
      position: absolute;
      z-index: -1;
    }
  }

  :deep([data-popper-placement^='top']) {
    & > [data-popper-arrow] {
      bottom: 5px;
    }
  }

  :deep([data-popper-placement^='right']) {
    & > [data-popper-arrow] {
      left: -15px;
    }
  }

  :deep([data-popper-placement^='bottom']) {
    & > [data-popper-arrow] {
      top: -5px;
    }
  }

  :deep([data-popper-placement^='left']) {
    & > [data-popper-arrow] {
      right: 15px;
    }
  }
</style>
