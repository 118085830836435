import { defineStore } from 'pinia';

export const useAlertsStore = defineStore('alerts', {
  state: () => ({
    position: 'top-right',
    alerts: [] as AlertData[],
  }),
  getters: {},
  actions: {
    add(alertData: AlertData) {
      alertData.id = Date.now();

      if (alertData.position) {
        this.position = alertData.position;
      }

      if (alertData.closeDelay) {
        setTimeout(() => {
          this.remove(alertData.id);
        }, alertData.closeDelay);
      }
      this.alerts.push(alertData);
    },
    remove(id: number) {
      const newAlerts = this.alerts.filter(alert => alert.id !== id);
      this.alerts = newAlerts;
    },
    // TODO: Refactor
    addReplace(alertData: Partial<AlertData>) {
      const newAlertData = { ...alertData, id: Date.now() } as AlertData;

      if (newAlertData.position) {
        this.position = newAlertData.position;
      }

      if (newAlertData.closeDelay) {
        setTimeout(() => {
          this.remove(newAlertData.id);
        }, newAlertData.closeDelay);
      }
      this.alerts = [newAlertData];
    },
    setPosition(position: string) {
      this.position = position;
    },
  },
});

export interface AlertData {
  id: number;
  position:
    | 'top-right'
    | 'bottom-right'
    | 'top-left'
    | 'bottom-left'
    | 'bottom-center'
    | 'top-center';
  closeDelay: number;
  message: string;
  type: 'success' | 'info' | 'error';
}
