// Queries
import PARTNER_USER_TOUR_STEPS from '@/assets/graphql/queries/tour/PartnerUserTourSteps.graphql';
import PARTNER_USER_TOURS from '@/assets/graphql/queries/tour/PartnerUserTours.graphql';
//  Mutations
import UPDATE_PARTNER_TOUR_MUTATION from '@/assets/graphql/mutations/tour/UpdatePartnerTourMutation.graphql';

// service
import apolloService from '@/services/apolloService';
import * as Types from './types';

export default class {
  // QUERIES
  static getPartnerUserTourSteps(variables: { tour_id: string }) {
    return apolloService.query<Types.PartnerUserTourStepsResponse>(
      PARTNER_USER_TOUR_STEPS,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getPartnerUserTours() {
    return apolloService.query<Types.PartnerUserTourResponse>(
      PARTNER_USER_TOURS,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  // MUTATIONS
  static updatePartnerTour(variables: { tour_id: string; is_read: boolean }) {
    return apolloService.mutate<Types.UpdatePartnerTourResponse>(
      UPDATE_PARTNER_TOUR_MUTATION,
      variables,
    );
  }
}
