<template>
  <div>
    <base-modal
      :show="show"
      class-name="--w-700"
      :back-ground="data.background_image_url"
      @close="closeModal"
    >
      <div class="news-letter-modal bb-px-6">
        <div v-if="data.logo_url" class="bb-row bb-fcenter bb-mb-4">
          <img :src="data.logo_url" alt="image-logo" class="image-logo" />
        </div>
        <div
          class="letter-content bb-description-4 bb-text-white bb-text-center bb-mt-4"
        >
          <span v-html="textContent"></span>
        </div>
        <div class="button-wrapper bb-fcenter bb-mt-6">
          <button-custom
            v-if="data.has_contact_button"
            :is-loading="isLoading"
            text="Quiero que me contacten"
            class-name="--w-440 violet"
            @click="sendContactInformation"
          ></button-custom>
          <button-custom
            text="No volver a mostrar este mensaje"
            class-name="--w-300 --transparent --underline"
            @click="closeIndefinitelyModal"
          ></button-custom>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script setup lang="ts">
  import ButtonCustom from '@/components/Common/ButtonCustom/ButtonCustom.vue';
  import BaseModal from '@/components/Common/BaseModal/BaseModal.vue';

  import { computed, ref } from 'vue';
  import { useAlertsStore } from '@/stores/alertsStore';
  import { useMutation } from '@tanstack/vue-query';
  import HomeApiService from '@/api/home';
  import NewsletterApiService from '@/api/newsletter';

  const alertsStore = useAlertsStore();
  const { mutateAsync: sendContactNotification } = useMutation({
    mutationFn: HomeApiService.sendContactNotification,
  });
  const { mutateAsync: showNewsLetter } = useMutation({
    mutationFn: NewsletterApiService.setShowNewsletter,
  });

  export interface NewsLetterContent {
    id: string;
    content: string;
    background_image_url: string;
    logo_url: string;
    has_contact_button: boolean;
  }

  type Props = {
    show: boolean;
    data?: NewsLetterContent;
  };

  const props = withDefaults(defineProps<Props>(), {
    data: () => ({
      id: '',
      content: '',
      background_image_url: '',
      logo_url: '',
      has_contact_button: false,
    }),
  });

  const textContent = computed(() => {
    return props.data.content
      ?.replace(/\n/g, '<br />')
      ?.replace(/<h1>/g, '<h1 style="font-size: 32px;">')
      ?.replace(/<h2>/g, '<h2 style="font-size: 24px;">');
  });

  const emit = defineEmits<{ close: [boolean] }>();

  const isLoading = ref(false);

  function closeModal() {
    emit('close', true);
  }
  async function sendContactInformation() {
    isLoading.value = true;
    const response = await sendContactNotification({
      newsletter_id: Number(props.data.id),
    });
    if (response.data?.send_newsletter_contact_notificacion.message) {
      isLoading.value = false;
      alertsStore.addReplace({
        type: 'success',
        message: 'Solicitud enviada con éxito',
        position: 'bottom-left',
      });
      emit('close', true);
    }
  }
  async function closeIndefinitelyModal() {
    const response = await showNewsLetter({
      newsletter_id: Number(props.data.id),
    });
    if (response.data?.set_show_newsletter.message) {
      emit('close', true);
    }
  }
</script>

<style lang="scss" scoped>
  .news-letter-modal {
    max-height: 80vh;
  }
  .image-logo {
    max-width: 200px;
    max-height: 100px;
  }
  .letter-content {
    max-height: 500px;
    overflow-y: auto;
    @media (max-height: 768px) {
      max-height: 350px;
    }
    :deep(a) {
      color: blue;
      text-decoration: underline;
    }
  }
  .button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-around;
  }

  .button-underline {
    text-decoration: underline;
  }
</style>
