<template>
  <nav class="navigation-bar">
    <div
      class="navigation-bar-container bb-fcenter-y bb-justify-content-between"
    >
      <div
        class="bb-row bb-mr-4 bb-m-2 bb-fcenter-x logo-container bb-align-items-center"
      >
        <BurguerIcon class="bb-pr-2 burguer-menu" @click="showNavBarMobile" />
        <router-link :to="{ name: 'home' }">
          <LogoIcon class="bb-logo" aria-label="Bigbox Partners" role="img" />
        </router-link>
        <b v-if="environment && !isProduction" class="env">
          {{ environment.toUpperCase() }}
        </b>
      </div>
      <div class="bb-row bb-fcenter-y">
        <button
          id="home_5"
          class="bb-m-2 create-btn bb-description-3 bb-hidden-xs-min"
          :class="{ 'create-btn-active': showCreationModal }"
          @click="showModal"
        >
          + Reserva
        </button>
        <button
          class="bb-m-2 bb-mr-4 create-btn-mobile bb-description-3 bb-hidden-lg bb-show-xs-min"
          @click="showModal"
        >
          +
        </button>
        <profile-dropdown id="profile_1" />
        <qr-code-dropdown
          v-if="qrCodeInfo"
          class="bb-mr-5 bb-ml-1 bb-hidden-xs-min qr-code"
          @show-modal="toggleQRCodeModal"
        />
        <help-center-message
          class="bb-mr-2"
          :show="showHelpMessage"
          @close="helpMessageStatus(false)"
        >
          <QuestionIcon
            class="navigation-bar-icon-question bb-row bb-cursor-pointer"
            @click="helpMessageStatus(true)"
          />
        </help-center-message>
        <notifications-dropdown-menu class="bb-ml-2" />
      </div>
    </div>
    <base-modal
      :show="showCreationModal"
      :class-name="modalWidth"
      full
      @close="showModal"
    >
      <create-reservation-modal
        @steps="setModalWidth"
        @close-modal="showModal"
      ></create-reservation-modal>
    </base-modal>
    <qr-code-modal :show="showQRCodeModal" @close="toggleQRCodeModal" />
    <status-modal
      v-if="creationStatusModalState"
      :options="creationStatusModalState"
      @close="closeStatusModal"
    />
  </nav>
</template>
<script setup lang="ts">
  import CreateReservationModal, {
    type StepKeys,
  } from '@/components/PartnerManualReservation/CreateReservationModal/CreateReservationModal.vue';
  import StatusModal, {
    type StatusModalOptions,
  } from '@/components/Common/StatusModal/StatusModal.vue';
  import NotificationsDropdownMenu from '@/components/Notifications/DropdownMenu/DropdownMenu.vue';
  import QrCodeDropdown from '@/components/QrCode/QrCodeDropdown/QrCodeDropdown.vue';
  import QrCodeModal from '@/components/QrCode/QrCodeModal/QrCodeModal.vue';
  import HelpCenterMessage from '@/components/Navigation/HelpcenterMessage/HelpcenterMessage.vue';
  import BaseModal from '@/components/Common/BaseModal/BaseModal.vue';
  import ProfileDropdown from '@/components/Navigation/ProfileDropdown/ProfileDropdown.vue';

  import LogoIcon from '@/assets/icons/LogoPartnersPrimary.svg';
  import BurguerIcon from '@/assets/icons/Burguer.svg';
  import QuestionIcon from '@/assets/icons/Question.svg';

  import { computed, ref } from 'vue';
  import { usePartnerInfoStore } from '@/stores/partnerInfoStore';
  import { useReservationScheduleStore } from '@/stores/reservationScheduleStore';

  const reservationScheduleStore = useReservationScheduleStore();
  const partnerInfoStore = usePartnerInfoStore();
  const emit = defineEmits<{ navBar: [] }>();

  const showCreationModal = ref(false);
  const showQRCodeModal = ref(false);
  const showHelpMessage = ref(false);
  const modalWidth = ref('--w-520');

  const environment = import.meta.env.VITE_ENVIRONMENT;

  const isProduction = computed<boolean>(() => {
    return environment === 'prod';
  });

  const creationStatusModalState = computed<StatusModalOptions | null>(() => {
    return reservationScheduleStore.creationStatusModal;
  });
  const qrCodeInfo = computed(() => {
    return Boolean(partnerInfoStore.profile.qr_code);
  });

  function helpMessageStatus(status: boolean) {
    showHelpMessage.value = status;
  }
  function showModal() {
    showCreationModal.value = !showCreationModal.value;
  }
  function toggleQRCodeModal() {
    showQRCodeModal.value = !showQRCodeModal.value;
  }
  function showNavBarMobile() {
    emit('navBar');
  }
  function setModalWidth(step: StepKeys) {
    if (step == 'reservationForm') {
      modalWidth.value = '--w-620';
    } else {
      modalWidth.value = '--w-520';
    }
  }
  function closeStatusModal() {
    reservationScheduleStore.creationStatusModal = null;
  }
</script>
<style lang="scss" scoped>
  .navigation-bar {
    padding-top: 70px;

    &-icon {
      :deep(svg) {
        width: auto;
        height: 18px;
        text-align: center;

        path {
          stroke: $DARK-GRAY;
        }
      }
      &-notification {
        :deep(svg) {
          width: auto;
          height: 24px;
          text-align: center;

          path {
            stroke: $DARK-GRAY;
          }
        }
      }
      &-question {
        width: 28px;
        height: 28px;
        fill: transparent;
        color: $PRIMARY;
        transition: all 0.3s ease;

        &:hover {
          color: $WHITE;
          fill: $PRIMARY;
        }
      }
    }
    .profile-menu-icon {
      :deep(svg) {
        width: auto;
        height: 18px;
        text-align: center;

        path {
          stroke: $DARK-GRAY;
          transition: all 0.8s ease;
        }
      }
      @media (max-width: $screen-sm-max) {
        display: none;
      }
    }
    &-container {
      height: 70px;
      background-color: $WHITE;
      border-bottom: 1px solid #e0e0e0;
      position: fixed;
      top: 0px;
      width: 100%;
      padding: 0 25px;
      z-index: $HEADER-Z-INDEX;

      @media (max-width: $screen-sm-max) {
        padding: 0 5px;
        width: 100vw;
      }
    }
    .bb-logo {
      cursor: pointer;
      width: 100px;
      height: auto;
      text-align: center;

      @media (max-width: 768px) {
        width: 90px;
        height: auto;
        text-align: center;
      }
    }

    .burguer-menu {
      display: none;
      width: auto;
      height: 20px;
      text-align: center;
      color: $DARK-GRAY;

      @media (max-width: 768px) {
        display: block;
      }
    }

    .icon-container {
      margin-top: 24px;
    }
  }

  .logo-container {
    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }

  .create-btn {
    overflow: hidden;
    background-color: $PRIMARY;
    border: 1px $PRIMARY solid;
    border-radius: 100px;
    cursor: pointer;
    color: $WHITE;
    font-weight: 600;
    min-width: 170px;
    height: 48px;
    position: relative;
    text-align: center;
    background: {
      image: linear-gradient(45deg, $WHITE 50%, transparent 50%);
      position: 100%;
      size: 400%;
    }
    transition: all 0.9s ease;

    &:hover {
      background-position: 0;
      transition: all 0.9s ease;
      color: $PRIMARY;
    }

    &-active {
      background-position: 0;
      transition: all 0.9s ease;
      color: $PRIMARY;
    }

    &-mobile {
      background-color: $PRIMARY;
      width: 32px;
      height: 32px;
      border: none;
      border-radius: 50%;
      color: $WHITE;
    }
  }

  .nav-link-text {
    &:hover {
      color: $PRIMARY;
      cursor: pointer;
      user-select: none;
    }

    &.--active {
      color: $PRIMARY;
    }
  }

  .qr-code {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .env {
    background-color: $PRIMARY;
    border-radius: 20px;
    color: white;
    padding: 5px 10px;
    margin-left: 10px;
  }
</style>
