<template>
  <div class="StatusModal">
    <base-modal :show="options.show" class-name="--w-420" @close="close">
      <section v-if="options.reservationInfo">
        <div>
          <ConfirmIcon v-if="options.status" class="status-icon success" />
          <CrossIcon v-else class="status-icon error" />
          <div class="modal-header">
            <h2 class="modal-title">{{ options.title }}</h2>
          </div>
          <p>
            {{ options.reservationInfo?.client.first_name }}
            <span> {{ options.reservationInfo?.client.last_name }}</span>
          </p>
          <p class="bb-mt-4">
            {{ options.reservationInfo?.participants }} personas
          </p>
          <p class="bb-mt-4">
            {{ options.reservationInfo.activity?.internal_name }}
          </p>
          <p class="bb-mt-4 date-content bb-text-darkgray bb-description-3">
            {{ formatedDate(options.reservationInfo?.reservation_date) }} hs
          </p>
          <p class="bb-mt-4">{{ options.reservationInfo?.location.address }}</p>
          <div class="modal-footer">
            <div class="bb-mt-4">
              <button-custom
                :text="options.button"
                class-name="--w-250"
                @click="close"
              ></button-custom>
            </div>
          </div>
        </div>
      </section>
      <section v-else>
        <ConfirmIcon v-if="options.status" class="status-icon success" />
        <CrossIcon v-else class="status-icon error" />
        <div class="modal-header">
          <h2 class="modal-title">{{ options.title }}</h2>
        </div>
        <div class="modal-content">
          <span class="status-message"> {{ options.message }} </span>
        </div>
        <div class="modal-footer">
          <div class="modal-buttons-wrapper">
            <button-custom
              :text="options.button"
              class-name="--w-250"
              @click="close"
            ></button-custom>
          </div>
        </div>
      </section>
    </base-modal>
  </div>
</template>
<script setup lang="ts">
  import BaseModal from '@/components/Common/BaseModal/BaseModal.vue';
  import ButtonCustom from '@/components/Common/ButtonCustom/ButtonCustom.vue';

  import ConfirmIcon from '@/assets/icons/ConfirmIcon.svg';
  import CrossIcon from '@/assets/icons/Cross.svg';
  import { useRouter } from 'vue-router';
  import dayjs from 'dayjs';
  import type { ReservationInterface } from '@/models/ReservationInterface';

  const emit = defineEmits<{ close: [] }>();

  const router = useRouter();

  export interface StatusModalOptions {
    title: string;
    message: string;
    status: boolean;
    show: boolean;
    redirect?: boolean;
    reservationInfo?: ReservationInterface;
    button: string;
  }

  type Props = {
    options: StatusModalOptions;
  };

  const props = defineProps<Props>();

  function formatedDate(date: Date | string) {
    return date ? dayjs(date).format('DD/MM/YYYY - HH:mm') : '';
  }

  function close() {
    emit('close');
    if (props.options.redirect) {
      router.push({ name: 'schedule' });
    }
  }
</script>
<style lang="scss" scoped>
  .StatusModal {
    position: relative;

    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .status-icon {
      color: $WHITE;
      width: 60px;
      height: 60px;

      &.error {
        fill: $ERROR;
      }

      &.success {
        fill: $SUCCESS;
      }
    }

    .date-content {
      font-weight: 600;
    }

    .modal-footer,
    .modal-header {
      padding: 10px;
    }

    .modal-title {
      font-size: 24px;
      color: #464646;
      font-weight: 500;
      margin-bottom: 40px;
    }

    .modal-buttons-wrapper {
      margin-top: 50px;
    }
  }
</style>
