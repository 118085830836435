<template>
  <div class="dropdown-menu">
    <div data-test="open-menu" @click="openMenu">
      <slot></slot>
    </div>
    <div v-if="show" v-on-click-outside="closeMenu">
      <div class="tail-menu" :class="positionDropdown"></div>
      <div :style="styleDropdown" class="menu" :class="positionDropdown">
        <slot name="content" :close="closeMenu"></slot>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { vOnClickOutside } from '@vueuse/components';
  import { computed, ref, type StyleValue } from 'vue';

  interface Props {
    maxWidth?: string;
    maxHeight?: string;
    toRight?: boolean;
    toLeft?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    maxWidth: '',
    maxHeight: '',
    toRight: false,
    toLeft: false,
  });

  const show = ref<boolean>(false);

  const positionDropdown = computed<'--to-right' | '--to-left'>(() => {
    return props.toRight ? '--to-right' : '--to-left';
  });
  const styleDropdown = computed<StyleValue>(() => {
    return {
      width: props.maxWidth + 'px',
    };
  });

  function openMenu(): void {
    show.value = true;
  }
  function closeMenu(): void {
    show.value = false;
  }

  defineExpose({ closeMenu });
</script>
<style lang="scss">
  .dropdown-menu {
    position: relative;

    .menu {
      position: absolute;
      border: solid 1px $GRAY-LIGHT;
      border-radius: 4px;
      background-color: $WHITE;
      padding: $SMALL;
      z-index: 1000;
      top: calc(100% + 15px);

      &.--to-left {
        right: -10px;
      }

      &.--to-right {
        left: -15px;
      }
    }

    .tail-menu {
      position: absolute;
      width: 14px;
      height: 14px;
      box-shadow: -1px -1.5px 1.5px -1px rgba(0, 0, 0, 0.15);
      background-color: $WHITE;
      transform: rotate(45deg);
      z-index: 1001;
      top: calc(100% + 10px);

      &.--to-left {
        right: 5px;
      }
      &.--to-right {
        right: 5px;
      }
    }
  }
</style>
