import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'maintenance', params: {} },
  },
  {
    path: '/mantenimiento',
    name: 'maintenance',
    component: () => import('@/views/MaintenanceView/MaintenanceView.vue'),
  },
];
export const maintenanceRouter = createRouter({
  history: createWebHistory(),
  routes,
});
