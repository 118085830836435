// Queries
import PAGINATED_REVIEWS from '@/assets/graphql/queries/reviews/PaginatedReviews.graphql';
import REVIEWS_ACTIVITIES from '@/assets/graphql/queries/reviews/ReviewsActivities.graphql';
import REVIEWS_INFORMATION from '@/assets/graphql/queries/reviews/ReviewsInformation.graphql';

// service
import apolloService from '@/services/apolloService';
import * as Types from './types';

export default class {
  // QUERIES
  static getPaginatedReviews(variables: Types.PaginatedReviewsPayload) {
    return apolloService.query<Types.PaginatedReviewsResponse>(
      PAGINATED_REVIEWS,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
  static getPartnerReviewsActivities() {
    return apolloService.query<Types.PartnersReviewsActivityResponse>(
      REVIEWS_ACTIVITIES,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
  static getPartnerReviewsInformation() {
    return apolloService.query<Types.PartnersReviewsInformationResponse>(
      REVIEWS_INFORMATION,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
}
