import type { Activity } from '@/models/ActivityInterface';
import type { PartnerLocation } from '@/models/ProfileInterface';

interface GenericReservation {
  partner_client: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  };
  reservation: {
    location: PartnerLocation;
    reservation_date: string | Date;
    comments: string;
    participants: number;
  };
}
type BigboxReservation = GenericReservation & {
  reservation: {
    code: string | null;
    activity: Activity | null;
  };
};
/**
 *  Defines reservations structure.
 */
const reservation = {
  partner_client: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  },
  reservation: {
    location: null,
    reservation_date: null,
    comments: null,
    participants: null,
  },
  utc: null,
};

const bigboxReservation: BigboxReservation = JSON.parse(
  JSON.stringify(reservation),
);
bigboxReservation.reservation.code = null;
bigboxReservation.reservation.activity = null;

export {
  reservation,
  bigboxReservation,
  type GenericReservation,
  type BigboxReservation,
};
