<template>
  <div class="create-reservation-modal">
    <ArrowIcon
      v-if="currentStep !== 'checkCode'"
      class="arrow-icon bb-mt-2"
      @click="changeStep()"
    />
    <h3
      class="bb-mb-7 bb-regular bb-description-6 bb-text-darkgray bb-letter-spacing-1"
    >
      Nueva reserva
    </h3>
    <p
      v-if="currentStep === 'checkCode'"
      class="bb-mb-2 bb-text-darkgray subtitle"
    >
      Seleccioná el tipo de reserva que quieras realizar
    </p>

    <section class="content-wrapper">
      <input-radio-custom
        v-if="currentStep === 'checkCode'"
        id="bb"
        label="Reserva Bigbox"
        name="reservation"
        option="PartnerActivity"
        :value="selectedValue"
        @update:value="reset"
      />
      <!-- TODO: Habilitar cuando lo pidan las product owner -->
      <!-- <input-radio-custom
            :label="`Reserva ${$store.state.partnerInfo.profile.fantasy_name}`"
            name="reservation"
            option="other"
            id="partner"
            :value="selectedValue"
            @update:value="reset"
        /> -->
      <div
        v-if="currentStep === 'checkCode'"
        class="bb-modal-line-divider divider bb-my-4"
      ></div>
      <div class="content">
        <check-code-step
          v-if="currentStep === 'checkCode'"
          @update-reservation="updateReservation"
        />
        <select-activity-locations-step
          v-if="currentStep === 'selectActivityLocations'"
          :activities-available="activitiesAvailable"
          @confirm-activity-location="showReservationForm"
        />
        <bigbox-reservation-form-step
          v-if="currentStep === 'reservationForm'"
          :bigbox-reservation="bigboxReservation"
          @close-modal="closeModal"
        />
        <!-- Igual aca -->
        <!-- <reservation-new-other v-if="selectedValue === 'other'"/> -->
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
  import InputRadioCustom from '@/components/Common/InputRadioCustom/InputRadioCustom.vue';
  import BigboxReservationFormStep from '@/components/PartnerManualReservation/BigboxReservationFormStep/BigboxReservationFormStep.vue';

  import CheckCodeStep from '@/components/PartnerManualReservation/CheckCodeStep/CheckCodeStep.vue';
  import SelectActivityLocationsStep, {
    type ActivityLocation,
  } from '@/components/PartnerManualReservation/SelectActivityLocationsStep/SelectActivityLocationsStep.vue';

  import ArrowIcon from '@/assets/icons/BackArrow.svg';

  import {
    bigboxReservation as reservationObject,
    type BigboxReservation,
  } from '@/helpers/reservationObjects';
  import { computed, onMounted, ref } from 'vue';
  import { useReservationScheduleStore } from '@/stores/reservationScheduleStore';
  import type { Activity } from '@/models/ActivityInterface';
  import type { PartnerLocation } from '@/models/ProfileInterface';

  const reservationScheduleStore = useReservationScheduleStore();

  const emit = defineEmits<{ steps: [StepKeys]; closeModal: [] }>();

  export type StepKeys =
    | 'checkCode'
    | 'selectActivityLocations'
    | 'reservationForm';
  type StepValue = {
    prev: StepKeys | null;
    next: StepKeys | null;
  };
  type Steps = Record<StepKeys, StepValue>;

  const selectedValue = ref('PartnerActivity');
  const currentStep = ref<StepKeys>('checkCode');
  const skipStepTwo = ref(false);
  const bigboxReservation = ref<BigboxReservation>(
    JSON.parse(JSON.stringify(reservationObject)),
  );
  const activitiesAvailable = ref<Activity[]>([]);
  const activitiesLocationsAvailable = ref<PartnerLocation[]>([]);

  const steps = computed<Steps>(() => {
    return {
      checkCode: {
        prev: null,
        next: skipStepTwo.value ? 'reservationForm' : 'selectActivityLocations',
      },
      selectActivityLocations: {
        prev: 'checkCode',
        next: 'reservationForm',
      },
      reservationForm: {
        prev: skipStepTwo.value ? 'checkCode' : 'selectActivityLocations',
        next: null,
      },
    };
  });

  onMounted(() => {
    reset();
  });

  function changeStep(step: keyof StepValue = 'prev') {
    if (currentStep.value) {
      currentStep.value = steps.value[currentStep.value][step]!;
    }
    // currentStep.value &&= steps.value[currentStep.value][step];
    emit('steps', currentStep.value);
  }
  function reset() {
    reservationScheduleStore.currentReservationActivity = [];
  }
  function updateReservation(code: string) {
    bigboxReservation.value.reservation.code = code;
    const { partner_client } = reservationScheduleStore;
    if (partner_client) {
      Object.assign(bigboxReservation.value.partner_client, partner_client);
    }
    fetchActivitiesAndLocations();
  }
  async function fetchActivitiesAndLocations() {
    // check again to prevent non locations errors.
    activitiesAvailable.value =
      reservationScheduleStore.currentReservationActivity;
    await getActivityLocations(activitiesAvailable.value[0].id);
    // Jump to step 3 if theres only 1 activity and 1 location
    if (
      activitiesAvailable.value.length === 1 &&
      activitiesLocationsAvailable.value.length === 1
    ) {
      skipStepTwo.value = true;
      showReservationForm({
        currentActivity: activitiesAvailable.value[0],
        currentActivityLocation: activitiesLocationsAvailable.value[0],
      });
    } else {
      changeStep('next');
    }
  }
  //TODO: Estoy duplicando codigo en SelectActivityLocationsStep, simplificar
  async function getActivityLocations(activity: string) {
    await reservationScheduleStore.checkActivityLocations(activity);
    activitiesLocationsAvailable.value =
      reservationScheduleStore.currentReservationActivityLocations;
  }
  function showReservationForm({
    currentActivity,
    currentActivityLocation,
  }: ActivityLocation) {
    bigboxReservation.value.reservation.activity = currentActivity;
    bigboxReservation.value.reservation.location = currentActivityLocation;
    changeStep('next');
  }
  function closeModal() {
    emit('closeModal');
  }
</script>
<style lang="scss" scoped>
  .create-reservation-modal {
    position: relative;
    text-align: left;
    height: 100%;
    min-height: 360px;
  }

  label {
    display: block;
    color: $DARK-GRAY;
  }

  .subtitle {
    font-weight: 500;
  }

  .content {
    min-height: 30px;
    margin: auto 5px;
  }

  .content-wrapper {
    padding: 0;
    max-height: calc(100% - 63px);

    overflow-y: auto;
    @media (max-width: $screen-md-min) {
      height: calc(100% - 63px);
    }
  }

  .divider {
    max-width: 150px;
  }

  .bigbox-reservation-form-step {
    max-height: 60vh;
  }

  .arrow-icon {
    cursor: pointer;
    color: $DARK-GRAY;
    height: 20px;
    left: -25px;
    position: absolute;
    top: -45px;
  }
</style>
