<template>
  <nav class="navigation-bar-mobile bb-column">
    <header class="navigation-bar-mobile-header">
      <div
        class="bb-row bb-fcenter-y bb-justify-content-between bb-m-2 bb-pt-2"
      >
        <CloseIcon
          class="close-icon"
          aria-label="Cerrar"
          role="img"
          @click="closeNavBar"
        />
        <router-link :to="{ name: 'home' }">
          <LogoIcon class="bb-logo" aria-label="Bigbox Partners" role="img" />
        </router-link>
      </div>
      <hr class="line" />
    </header>
    <section class="navigation-bar-mobile-profile bb-pt-2">
      <div class="bb-row bb-fcenter-y bb-justify-content-between">
        <router-link
          :to="{ name: 'profile' }"
          class="bb-row navigation-bar-mobile-element bb-my-2 bb-ml-6"
        >
          <ProfileIcon
            class="navigation-bar-mobile-icon bb-pr-3"
            aria-label="Perfil"
            role="img"
          />
          <p class="navigation-bar-mobile-text" @click="closeNavBar">
            {{ profileTitle }}
          </p>
        </router-link>
        <qr-code-dropdown
          v-if="qrCodeInfo"
          class="bb-m-2"
          aria-label="Código QR"
          role="img"
          @show-modal="toggleQRCodeModal"
        />
      </div>
      <hr class="line" />
    </section>
    <section class="elements-top bb-column bb-pt-2">
      <ul
        v-for="element in availableElements"
        :key="element.name"
        class="bb-my-3 bb-ml-6 navigation-bar-mobile-element"
        @click="closeNavBar"
      >
        <router-link :to="element.to" class="bb-row">
          <component
            :is="element.icon"
            class="navigation-bar-mobile-icon bb-pr-3"
            :aria-label="element.text"
            role="img"
          />
          <li class="navigation-bar-mobile-text">{{ element.text }}</li>
        </router-link>
      </ul>
    </section>
    <section class="elements-bottom" @click="closeNavBar">
      <hr class="line" />

      <a
        :href="link"
        class="bb-row navigation-sidebar-element bb-pt-2 bb-pb-2 bb-ml-6"
      >
        <ChatIcon
          class="navigation-bar-mobile-icon bb-pr-3"
          aria-label="Centro de ayuda"
          role="img"
        />
        <p class="navigation-sidebar-text">Centro de ayuda</p>
      </a>
      <div
        class="bb-cursor-pointer bb-row navigation-sidebar-element bb-pt-2 bb-pb-2 bb-ml-6"
        @click="logout"
      >
        <LogoutIcon
          class="navigation-bar-mobile-icon bb-pr-3"
          aria-label="Cerrar sesión"
          role="img"
        />
        <p class="navigation-sidebar-text">Cerrar sesión</p>
      </div>
    </section>
  </nav>
</template>
<script setup lang="ts">
  import {
    computed,
    onMounted,
    ref,
    shallowRef,
    type FunctionalComponent,
    type SVGAttributes,
  } from 'vue';
  import { useAuth } from '@websanova/vue-auth/src/v3';
  import { useRouter, type RouteLocationRaw } from 'vue-router';
  import { apolloClient } from '@/config/apolloClientConstructor';
  import { usePartnerInfoStore } from '@/stores/partnerInfoStore';
  import type { PartnersProfile } from '@/models/ProfileInterface';

  import QrCodeDropdown from '@/components/QrCode/QrCodeDropdown/QrCodeDropdown.vue';

  import LogoIcon from '@/assets/icons/LogoPartnersPrimary.svg';
  import CloseIcon from '@/assets/icons/Cross.svg';
  import ProfileIcon from '@/assets/icons/Profile.svg';
  import HomeIcon from '@/assets/icons/Home.svg';
  import CalendarIcon from '@/assets/icons/Calendar.svg';
  import PaperIcon from '@/assets/icons/Paper.svg';
  import SettingIcon from '@/assets/icons/Setting.svg';
  import FolderIcon from '@/assets/icons/Folder.svg';
  import StarIcon from '@/assets/icons/Star.svg';
  import ChartIcon from '@/assets/icons/Chart.svg';
  import ChatIcon from '@/assets/icons/Chat.svg';
  import LogoutIcon from '@/assets/icons/Logout.svg';
  import CashIcon from '@/assets/icons/Cash.svg';

  const partnerInfoStore = usePartnerInfoStore();
  const auth = useAuth();
  const router = useRouter();

  const emit = defineEmits<{ closeNavBar: [] }>();

  const link = ref('https://ayuda.partners.bigbox.com.ar/lang/es_ar/');

  type SidebarSchema = {
    name: string;
    text: string;
    icon: FunctionalComponent<SVGAttributes>;
    to: RouteLocationRaw;
    userAvailability: boolean;
    showNew?: boolean;
  };

  const showQRCodeModal = ref(false);
  const elements = shallowRef<SidebarSchema[]>([]);

  const profileTitle = computed(() => {
    if (partnerInfoState.value.user.first_name) {
      return partnerInfoState.value.user.first_name;
    } else {
      return partnerInfoState.value.fantasy_name.length > 19
        ? partnerInfoState.value.fantasy_name.slice(0, 19) + '...'
        : partnerInfoState.value.fantasy_name;
    }
  });
  const qrCodeInfo = computed(() => {
    /* TODO: It is hidden until changes are made in the back
      return Boolean(partnerInfoStore.profile.qr_code); */
    return false;
  });
  const partnerInfoState = computed<PartnersProfile>(() => {
    return partnerInfoStore.profile;
  });
  const availableElements = computed<SidebarSchema[]>(() => {
    return elements.value.filter(element => element.userAvailability);
  });

  // created
  elements.value = [
    {
      name: 'home',
      text: 'Inicio',
      icon: HomeIcon,
      to: { name: 'home' },
      userAvailability: true,
    },
    {
      name: 'schedule',
      text: 'Agenda',
      icon: CalendarIcon,
      to: { name: 'schedule' },
      userAvailability: true,
    },
    {
      name: 'liquidations',
      text: 'Facturación',
      icon: PaperIcon,
      to: { name: 'liquidations' },
      userAvailability: true,
    },
    {
      name: 'reservation-configuration',
      text: 'Configurar reservas',
      icon: SettingIcon,
      to: { name: 'reservation-configuration' },
      userAvailability: true,
    },
    {
      name: 'my-experiences',
      text: 'Experiencias',
      icon: FolderIcon,
      to: { name: 'my-experiences' },
      userAvailability: true,
    },
    {
      name: 'reviews',
      text: 'Reseñas',
      icon: StarIcon,
      to: { name: 'reviews' },
      userAvailability: true,
    },
    {
      name: 'charts',
      text: 'Métricas',
      icon: ChartIcon,
      to: { name: 'charts' },
      userAvailability: true,
    },
    {
      name: 'benefits',
      text: 'Beneficios',
      icon: CashIcon,
      to: { name: 'benefits' },
      userAvailability: true,
    },
  ] as SidebarSchema[];

  onMounted(() => {
    userNavAvailability();
  });

  function routeName(name: string): SidebarSchema | undefined {
    return elements.value.find(route => route.name === name);
  }
  function userNavAvailability() {
    const { role } = partnerInfoState.value;

    const routeProfile = routeName('profile');
    const routeLiquidations = routeName('liquidations');
    switch (role) {
      case 'validation':
        routeProfile && (routeProfile.userAvailability = false);
        routeLiquidations && (routeLiquidations.userAvailability = false);
        break;
      case 'liquidation':
        routeProfile && (routeProfile.userAvailability = false);
        break;
    }
  }
  function closeNavBar() {
    emit('closeNavBar');
  }
  function toggleQRCodeModal() {
    showQRCodeModal.value = !showQRCodeModal.value;
  }
  async function logout() {
    if (auth.options) {
      localStorage.removeItem(auth.options.refreshTokenKey);
      localStorage.removeItem(auth.options.staySignedInKey);
    }
    await apolloClient.resetStore();
    auth.logout();
    closeNavBar();
    router.push({ name: 'login' });
  }
</script>
<style lang="scss" scoped>
  .navigation-bar-mobile {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: $WHITE;
    z-index: $HEADER-Z-INDEX + 10;
    &-icon {
      width: auto;
      height: 20px;
      color: $DARK-GRAY;
    }

    &-header {
      height: 70px;
    }
    &-profile {
      height: 70px;
    }

    .bb-logo {
      width: 80px;
      height: auto;
      text-align: center;
    }

    .close-icon {
      width: $LARGE;
      margin: -5px;
      height: auto;
      text-align: center;
      color: $DARK-GRAY;
    }
    .line {
      width: 100%;
      border-top: 1px solid #e0e0e0;
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
    }

    .router-link-exact-active {
      color: $PRIMARY;

      svg {
        color: $PRIMARY;
      }
    }

    .elements-top {
      justify-content: flex-start;
      overflow: auto;
      @media (min-width: 540px) and (max-width: 750px) {
        padding-top: 200px;
        overflow: auto;
      }
      @media (width: 768px) {
        justify-content: start;
      }
    }
    .elements-bottom {
      height: 140px;
      @media (width: 640px) {
        width: 100%;
        position: fixed;
        bottom: 0px;
        background-color: $WHITE;
      }
    }
  }
</style>
