import { ref } from 'vue';
import axios, { type AxiosInstance } from 'axios';
import type { TokenResponse, SsoInterface } from './types';

class SsoAuth {
  private credentials: SsoInterface;
  private useMockUser: boolean;
  private connector: AxiosInstance;
  public serviceInitialized = ref(false);

  constructor() {
    this.useMockUser = import.meta.env.VITE_ENVIRONMENT === 'dev';
    this.credentials = {
      url: import.meta.env.VITE_SSO_URL,
      realm: import.meta.env.VITE_SSO_REALM,
      clientId: import.meta.env.VITE_SSO_CLIENT,
      clientSecret: import.meta.env.VITE_SSO_CLIENT_SECRET,
    };
    this.connector = axios.create({
      baseURL: `${this.credentials.url}/realms/${this.credentials.realm}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (this.useMockUser) {
      this.serviceInitialized.value = true;
    } else {
      this.generateToken();
    }
  }

  public async generateToken(seconds: number = 0) {
    setTimeout(() => {
      this.connector
        .post<TokenResponse>('/protocol/openid-connect/token', {
          grant_type: 'client_credentials',
          client_id: this.credentials.clientId,
          client_secret: this.credentials.clientSecret,
        })
        .then(response => {
          this.credentials.token = response.data.access_token;
          this.generateToken(response.data.expires_in);
        })
        .finally(() => {
          this.serviceInitialized.value = true;
        });
    }, seconds * 1000);
  }

  public getToken() {
    if (this.useMockUser) return;
    return `Bearer ${this.credentials.token}`;
  }

  public getUserInfo() {
    if (this.useMockUser) {
      return {
        id: 'd85d6d63-95c1-4415-bd94-c655a54e27f5',
        username: 'partners@bigbox.com.ar',
        firstName: 'Admin',
        lastName: 'User',
        email: 'partners@bigbox.com.ar',
        emailVerified: true,
      };
    }
    if (!this.credentials.token) {
      throw new Error('Token not found');
    }

    return this.parseJwt(this.credentials.token);
  }

  private parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  public hasRole(role: string) {
    if (this.useMockUser) return true;
    else if (!this.credentials.token) return false;

    const token = this.parseJwt(this.credentials.token);
    if (token.realm_access.roles.includes('admin')) return true;

    const resourceAccess = token.resource_access['partners-backend-api'];
    if (!resourceAccess) return false;
    return resourceAccess.roles.includes(role);
  }

  public hasAnyRole(roles: string[]) {
    return roles.some(role => this.hasRole(role));
  }
}

const ssoAuth = new SsoAuth();

export default ssoAuth;
