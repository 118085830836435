<template>
  <dropdown-menu max-width="384" to-left>
    <div
      class="bb-cursor-pointer"
      @click="toggleQRCodeDropdown"
      @mouseover="playStopAnimation('play')"
      @mouseleave="playStopAnimation('stop')"
    >
      <Vue3Lottie
        ref="lottieAnimation"
        :animation-data="QRCodeJSON"
        :height="36"
        :width="36"
        :auto-play="false"
        @on-animation-loaded="statusLoadingLottie"
      />
    </div>
    <template #content>
      <p class="bb-description-4">
        {{ translate('Imprimí') }} tu QR para mostrarle a tus clientes qué
        incluyen tus experiencias.
      </p>
      <button-custom
        class="bb-text-primary bb-title-1 bb-bold download-container"
        class-name=" --w-225 --tertiary"
        text="Generar QR para imprimir"
        :icon="DownloadIcon"
        class-icon="download-qr-dropdown"
        @click="triggerQRCodeModal"
      />
    </template>
  </dropdown-menu>
</template>
<script setup lang="ts">
  import { ref } from 'vue';
  import { Vue3Lottie } from 'vue3-lottie';
  import translate from '@/helpers/translate';

  import QRCodeJSON from '@/assets/lotties/qrcode.json';

  import DropdownMenu from '@/components/Common/DropdownMenu/DropdownMenu.vue';
  import ButtonCustom from '@/components/Common/ButtonCustom/ButtonCustom.vue';

  import DownloadIcon from '@/assets/icons/Download.svg';

  const emit = defineEmits<{ 'show-modal': [] }>();

  //tempalte ref
  const lottieAnimation = ref<InstanceType<typeof Vue3Lottie> | null>(null);

  const showDropdown = ref(false);
  const loadingLotties = ref(false);

  const playStopAnimation = (status: 'play' | 'stop') => {
    if (lottieAnimation.value && loadingLotties.value) {
      status === 'play'
        ? lottieAnimation.value.play()
        : lottieAnimation.value.stop();
    }
  };

  const statusLoadingLottie = () => {
    loadingLotties.value = true;
  };

  const toggleQRCodeDropdown = () => {
    showDropdown.value = !showDropdown.value;
  };

  const triggerQRCodeModal = () => {
    emit('show-modal');
    showDropdown.value = false;
  };
</script>
<style lang="scss">
  .qr-code-icon {
    height: 22px;
    width: 22px;
    cursor: pointer;
  }

  .download-container {
    margin-top: 22px;
    display: flex;
    gap: 10px;
  }

  .download-qr-dropdown {
    width: 18px;
    height: 18px;
    color: $PRIMARY;
  }
</style>
