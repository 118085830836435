// Queries
import BLOCKED_APPOINTMENTS from '@/assets/graphql/queries/locks/BlockedAppointments.graphql';
import TOTAL_ACTIVE_RESERVATIONS from '@/assets/graphql/queries/locks/TotalActiveReservations.graphql';
//  Mutations
import DELETE_APPOINTMENT_BLOCK from '@/assets/graphql/mutations/locks/DeleteAppointmentBlock.graphql';
import UPDATE_APPOINTMENT_BLOCK from '@/assets/graphql/mutations/locks/UpdateAppointmentBlock.graphql';
import CREATE_APPOINTMENT_LOCK from '@/assets/graphql/mutations/locks/CreateAppointmentLock.graphql';
// service
import apolloService from '@/services/apolloService';
import * as Types from './types';

export default class LocksApi {
  // QUERIES
  static getBlockedAppointments(variables: {
    reservation_config_id: string | null;
  }) {
    return apolloService.query<Types.BlockedAppointentsResponse>(
      BLOCKED_APPOINTMENTS,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
  static getTotalActiveReservations(
    variables: Types.TotalActiveReservationsPayload,
  ) {
    return apolloService.query<Types.TotalActiveReservationsResponse>(
      TOTAL_ACTIVE_RESERVATIONS,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  // MUTATIONS
  static createAppointmentBlock(
    variables: Types.CreateAppointmentBlockPayload,
  ) {
    return apolloService.mutate<Types.CreateAppointmentBlockResponse>(
      CREATE_APPOINTMENT_LOCK,
      variables,
    );
  }
  static deleteAppointmentBlock(variables: { block_id: string }) {
    return apolloService.mutate<Types.DeleteAppointmentBlockResponse>(
      DELETE_APPOINTMENT_BLOCK,
      variables,
    );
  }
  static updateAppointmentBlock(
    variables: Types.UpdateAppointmentBlockPayload,
  ) {
    return apolloService.mutate<Types.UpdateAppointmentBlockResponse>(
      UPDATE_APPOINTMENT_BLOCK,
      variables,
    );
  }
}
