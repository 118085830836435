<template>
  <label class="InputRadioCustom" :for="id">
    <input
      :id="id"
      v-model="radioButtonValue"
      class="hidden"
      type="radio"
      :name="name"
      :value="option"
    />
    <span class="label"></span>
    <p class="text">{{ label }}</p>
  </label>
</template>
<script setup lang="ts">
  import { computed } from 'vue';

  type Props = {
    id?: string;
    name?: string;
    label: string;
    value?: string;
    option?: string;
  };

  const props = withDefaults(defineProps<Props>(), {
    id: 'opt',
    name: 'radios',
    label: 'Opción default',
    value: '',
    option: '',
  });

  const emit = defineEmits<{ 'update:value': [string | boolean] }>();

  const radioButtonValue = computed({
    get() {
      // if `value === option`, return `option`, else return `false`
      return props.value === props.option && props.option;
    },
    set(value) {
      emit('update:value', value);
    },
  });
</script>
<style lang="scss" scoped>
  .InputRadioCustom {
    color: $DARK-GRAY;
    cursor: pointer;
    display: inline-block;
    line-height: 20px;
    margin: 15px 15px 15px 0;
    position: relative;

    .text {
      font-family: Figtree, sans-serif;
      font-size: 16px;
      min-width: 150px;
    }

    .label {
      -webkit-tap-highlight-color: transparent;
      border-radius: 100%;
      border: 2px solid $GRAY-LIGHT-1;
      display: block;
      float: left;
      height: 20px;
      margin-right: 10px;
      position: relative;
      width: 20px;

      &:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: $PRIMARY;
        transform: scale(0);
        transition: all 0.2s ease;
        opacity: 0.08;
        pointer-events: none;
      }
    }

    &:hover {
      .label:after {
        transform: scale(3.6);
      }
    }
  }

  input[type='radio']:checked + .label {
    border-color: $GRAY-1;

    &:after {
      transform: scale(1);
      transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
      opacity: 1;
    }
  }

  .hidden {
    display: none;
  }
</style>
