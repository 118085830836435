function liquidationStatus(liq: string) {
  switch (liq) {
    case 'APPROVED':
      return 'Aprobada';
    case 'IN_PROCESS':
      return 'En proceso';
    case 'PAYED':
      return 'Pagada';
    case 'REJECTED':
      return 'Rechazada';
    case 'PENDING':
      return 'Pendiente';
    case 'ARCHIVED':
      return 'Archivada';
    default:
      return '';
  }
}

export default liquidationStatus;
