<template>
  <div class="notifications-dropdown">
    <div v-on-click-outside="closeNotifications" class="overlay">
      <div v-if="allNotifications.length" class="dropdown-container bb-py-1">
        <h3
          class="bb-description-2 bb-text-darkgray bb-mt-1 bb-ml-3 bb-text-left"
        >
          Notificaciones
        </h3>
        <div v-for="notification of allNotifications" :key="notification.id">
          <notifications-item
            :notification="notification"
            @close="closeNotifications"
          />
        </div>
      </div>
      <div v-else class="dropdown-container bb-py-1 bb-column bb-center-y">
        <h3
          class="bb-description-3 bb-text-darkgray bb-text-left bb-m-1 bb-ml-3"
        >
          No {{ translate('tenés') }} notificaciones
        </h3>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { computed } from 'vue';
  import translate from '@/helpers/translate';
  import { vOnClickOutside } from '@vueuse/components';
  import NotificationsItem from '@/components/Notifications/NotificationsItem/NotificationsItem.vue';
  import { useNotificationsStore } from '@/stores/notificationsStore';

  const notificationsStore = useNotificationsStore();
  const emit = defineEmits<{ close: [] }>();

  const allNotifications = computed(
    () => notificationsStore.partnerNotifications,
  );

  const closeNotifications = () => emit('close');
</script>
<style lang="scss" scoped>
  .notifications-dropdown {
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(70, 70, 70, 0.5);
    top: 0;
    left: 0;

    .overlay {
      position: fixed;
      z-index: 2000;
      top: 60px;
      right: 30px;
    }

    .dropdown-container {
      position: relative;
      width: 440px;
      max-height: 600px;
      border-radius: 8px;
      background-color: $WHITE;
      border: solid 1px $GRAY-LIGHT;
      box-shadow: rgba($DARK-GRAY, 0.1) 2px 2px 2px;
      top: calc(100% + 10px);
      overflow: auto;
    }
  }
</style>
