const countries = [
  '.com.ar',
  '.com.uy',
  '.cl',
  '.com.pe',
  '.com.mx',
  '.es',
] as const;

const localPort = import.meta.env.DEV
  ? `:${import.meta.env.VITE_BACKEND_LOCAL_PORT}`
  : '';
const getTld = countries.find(TLD => location.hostname.includes(TLD));

export default function getBackendURL() {
  if (!getTld) {
    throw new Error('Url does not matchs schema');
  }
  return `${import.meta.env.VITE_BASE_API}${getTld}${localPort}`;
}
