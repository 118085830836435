function getLastTld() {
  const regex = window.location.hostname.match(/\.([^.]+)$/);
  return regex ? regex[1] : 'ar';
}

export const TLD = getLastTld();

export function getCountryIDFromTld() {
  const tld = getLastTld();
  switch (tld) {
    case 'ar':
      return 10;
    case 'cl':
      return 228;
    case 'es':
      return 232;
    case 'mx':
      return 231;
    case 'pe':
      return 229;
    case 'uy':
      return 227;
    default:
      return 10;
  }
}

export function getCountryNameFromTld() {
  const tld = getLastTld();
  switch (tld) {
    case 'ar':
      return 'Argentina';
    case 'cl':
      return 'Chile';
    case 'es':
      return 'España';
    case 'mx':
      return 'México';
    case 'pe':
      return 'Perú';
    case 'uy':
      return 'Uruguay';
    default:
      return 'Argentina';
  }
}

export default getLastTld;
