import {
  apolloClient,
  apolloUploadClient,
} from '@/config/apolloClientConstructor';
import type { MutationOptions, QueryOptions } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import type { OperationVariables } from '@apollo/client';

const ApolloClient = {
  default: apolloClient,
  upload: apolloUploadClient,
} as const;

type ApolloClientKey = keyof typeof ApolloClient;

type OmittedQueryOptions = Omit<QueryOptions, 'query' | 'variables'>;
type OmittedMutationOptions = Omit<MutationOptions, 'mutation' | 'variables'>;

export default {
  query: <TResponse>(
    graphqlQuery: DocumentNode,
    variables: OperationVariables | undefined = undefined,
    options: OmittedQueryOptions | null = null,
    client: ApolloClientKey = 'default',
  ) => {
    const response = ApolloClient[client].query<TResponse>({
      query: graphqlQuery,
      variables,
      ...options,
    });

    return response;
  },

  mutate: <TResponse>(
    graphqlMutation: DocumentNode,
    variables: OperationVariables | undefined = undefined,
    options: OmittedMutationOptions | null = null,
    client: ApolloClientKey = 'default',
  ) => {
    const response = ApolloClient[client].mutate<TResponse>({
      mutation: graphqlMutation,
      variables,
      ...options,
    });

    return response;
  },
};
