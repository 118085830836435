// Queries
import PARTNER_NEWS_LETTER from '@/assets/graphql/queries/newsLetter/PartnerNewsLetter.graphql';

//  Mutations
import SET_SHOW_NEWSLETTER from '@/assets/graphql/mutations/home/SetShowNewsletter.graphql';

import apolloService from '@/services/apolloService';
import type {
  PartnerNewsletterResponse,
  SetShowNewsletterResponse,
} from './types';

export default class {
  // QUERIES
  static getPartnerNewsletters() {
    return apolloService.query<PartnerNewsletterResponse>(PARTNER_NEWS_LETTER);
  }

  // MUTATIONS
  static setShowNewsletter(variables: any) {
    return apolloService.mutate<SetShowNewsletterResponse>(
      SET_SHOW_NEWSLETTER,
      variables,
    );
  }
}
