<template>
  <div class="check-code-step code-validator">
    <label class="bb-mb-3" for="validator"
      >{{ translate('Ingresá') }} el código Bigbox</label
    >
    <input
      ref="codeInputRef"
      v-model="code"
      maxlength="10"
      name="validator"
      type="text"
      class="bb-input --short --w-300"
      placeholder="Ej: abj2akx5"
      autocomplete="off"
      :disabled="isDisabled"
      @keyup.enter="validateCode"
    />
    <div v-if="errors" class="form-error">{{ errors }}</div>
    <button-custom
      class-name="--w-325 bb-mt-5"
      text="Verificar código"
      :disabled="!code"
      @click="validateCode"
    />
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, onMounted } from 'vue';
  import ButtonCustom from '@/components/Common/ButtonCustom/ButtonCustom.vue';
  import translate from '@/helpers/translate';
  import { useReservationScheduleStore } from '@/stores/reservationScheduleStore';
  import ScheduleApiService from '@/api/schedule';

  const reservationScheduleStore = useReservationScheduleStore();
  const emit = defineEmits<{ updateReservation: [string] }>();

  //tempalte ref
  const codeInputRef = ref<HTMLInputElement | null>(null);

  const isDisabled = ref(false);
  const code = ref('');
  const checkCodeStatus = ref<{ errors?: any }>({});

  /**
   * Display reservation errors, this errors comes form de BE.
   * @returns {string}
   */
  const errors = computed(() => {
    if (checkCodeStatus.value?.errors) {
      return checkCodeStatus.value.errors.code
        ? checkCodeStatus.value.errors.code[0]
        : checkCodeStatus.value.errors.__all__[0];
    }
    return '';
  });

  const toggleDisabled = (flag = true) => {
    isDisabled.value = flag;
  };

  /**
   * Check if the code is valid and get the activities and locations.
   *
   * @return {Promise<void>} - no return value
   */
  const validateCode = async () => {
    toggleDisabled();
    code.value = code.value.replace(' ', '').toUpperCase();
    await checkCode(code.value);
    if (!errors.value) {
      emit('updateReservation', code.value);
    } else {
      toggleDisabled(false);
    }
  };

  async function checkCode(code: string) {
    const response = await ScheduleApiService.checkCode({ code });

    const ok = response.data.partners_check_code.errors;

    if (!ok) {
      reservationScheduleStore.setCodeInformation(
        response.data.partners_check_code,
      );
      checkCodeStatus.value = {};
    } else {
      checkCodeStatus.value = { errors: ok };
    }
  }

  onMounted(() => {
    codeInputRef.value?.focus();
  });
</script>
<style lang="scss" scoped>
  .check-code-step {
    position: relative;

    label {
      color: $DARK-GRAY;
      display: block;
      font-weight: 500;
    }

    .form-error {
      font-size: 14px;
      margin-left: 2px;
      margin-top: 2px;
      color: $PRIMARY;
    }
  }
</style>
