import ar from '@/locales/es-ar';
import la from '@/locales/es-la';
import { TLD } from '@/helpers/getLastTld';

type LaType = keyof typeof la;
type ArType = keyof typeof ar;

function translate(text: LaType | ArType) {
  let response: string;

  if (TLD === 'ar') {
    response = ar[text as ArType] || text;
  } else {
    response = la[text as LaType] || text;
  }

  return response;
}

export default translate;
