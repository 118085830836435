// Queries
import PARTNERS_COMERCIAL_PROFILE from '@/assets/graphql/queries/profile/PartnersComercialProfile.graphql';
import PARTNER_COMPANY_INFORMATION from '@/assets/graphql/queries/profile/PartnerCompanyInformation.graphql';
import PROFILE from '@/assets/graphql/queries/profile/Profile.graphql';
import PARTNER_BENEFITS from '@/assets/graphql/queries/benefits/PartnerBenefits.graphql';

//  Mutations
import UPDATE_ACCOUNTING_DOCUMENT_MUTATION from '@/assets/graphql/mutations/profile/UpdateAccountingDocumentMutation.graphql';
import UPDATE_EMAILS_PROFILE_MUTATION from '@/assets/graphql/mutations/profile/UpdateEmailsProfileMutation.graphql';
import UPDATE_PARTNER_ADMINISTRATION_PROFILE_MUTATION from '@/assets/graphql/mutations/profile/UpdatePartnerAdministrationProfileMutation.graphql';
import UPDATE_PARTNER_COMERCIAL_PROFILE_MUTATION from '@/assets/graphql/mutations/profile/UpdatePartnerComercialProfileMutation.graphql';
import UPDATE_PASSWORD_PARTNER_PROFILE_MUTATION from '@/assets/graphql/mutations/profile/UpdatePasswordPartnerProfileMutation.graphql';

// service
import apolloService from '@/services/apolloService';
import * as Types from './types';

export default class {
  // QUERIES
  static getPartnerComercialProfile() {
    return apolloService.query<Types.PartnersComercialProfileResponse>(
      PARTNERS_COMERCIAL_PROFILE,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getPartnerCompanyInformation() {
    return apolloService.query<Types.PartnerCompanyInformationResponse>(
      PARTNER_COMPANY_INFORMATION,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getPartnerProfile() {
    return apolloService.query<Types.PartnerProfileResponse>(
      PROFILE,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getPartnerBenefits() {
    return apolloService.query<Types.PartnerBenefitsResponse>(PARTNER_BENEFITS);
  }

  // MUTATIONS

  static updateAccountingDocument(variables: {
    document: File;
    document_name: string;
  }) {
    return apolloService.mutate<Types.UpdateAccountingDocumentMutation>(
      UPDATE_ACCOUNTING_DOCUMENT_MUTATION,
      variables,
      {},
      'upload',
    );
  }
  static updatePartnerEmails(variables: {
    email_administration: string;
    email_comercial: string;
  }) {
    return apolloService.mutate<Types.UpdateEmailsProfileResponse>(
      UPDATE_EMAILS_PROFILE_MUTATION,
      variables,
    );
  }

  static updatePartnersAdministrationProfile(variables: {
    administration_email: string;
    administration_phone: string;
  }) {
    return apolloService.mutate<Types.UpdatePartnerAdministrationProfileResponse>(
      UPDATE_PARTNER_ADMINISTRATION_PROFILE_MUTATION,
      variables,
    );
  }

  static updatePartnersComercialProfile(
    variables: Types.UpdateupdatePartnersComercialProfilePayload,
  ) {
    return apolloService.mutate<Types.UpdatePartnerComercialProfileResponse>(
      UPDATE_PARTNER_COMERCIAL_PROFILE_MUTATION,
      variables,
    );
  }

  static updatePartnerPassword(variables: {
    new_password: string;
    new_password_rep: string;
    old_password: string;
  }) {
    return apolloService.mutate<Types.UpdatePasswordPartnerProfileMutationResponse>(
      UPDATE_PASSWORD_PARTNER_PROFILE_MUTATION,
      variables,
    );
  }
}
