<template>
  <div class="notifications-menu">
    <div
      class="notification-icon"
      :class="{ bell: isRinging }"
      @click="displayNotifications"
    >
      <NotificationIcon class="bb-text-darkgray bb-row" />
      <div v-if="notifications.length" class="bb-fcenter notifications-alert">
        <span>{{ notifications.length }}</span>
      </div>
    </div>
    <transition name="fade">
      <notifications-list-dropdown
        v-if="isDropdownDisplayed"
        @close="closeDropdown"
      />
    </transition>
  </div>
</template>
<script setup lang="ts">
  import NotificationIcon from '@/assets/icons/Notification.svg';

  import NotificationsListDropdown from '@/components/Notifications/NotificationsListDropdown/NotificationsListDropdown.vue';
  import { computed, ref } from 'vue';
  import { useNotificationsStore } from '@/stores/notificationsStore';

  const notificationsStore = useNotificationsStore();

  const isDropdownDisplayed = ref(false);
  const isRinging = ref(false);

  const notifications = computed(() => {
    return notificationsStore.unreadNotifications;
  });

  function displayNotifications() {
    if (!isDropdownDisplayed.value) {
      notificationsStore.watchNotifications();
    } else {
      notificationsStore.updateNotificationsStatus();
    }
    isDropdownDisplayed.value = !isDropdownDisplayed.value;
  }
  function closeDropdown() {
    isDropdownDisplayed.value = false;
    notificationsStore.watchNotifications();
  }
</script>
<style lang="scss" scoped>
  .notifications-menu {
    position: relative;

    .notification-icon {
      width: 24px;
      position: relative;
      cursor: pointer;
    }

    .notifications-alert {
      position: absolute;
      right: -3px;
      top: -3px;
      min-width: 14px;
      height: 14px;
      background-color: $PRIMARY;
      color: white;
      border-radius: 14px;
      font-size: 10px;
      padding: 1px;

      &.--max-notifications {
        font-size: 8px;
        padding: 1px;
      }
    }

    .bell {
      animation: ring 0.3s infinite;
      transform-origin: 50% 0;
    }
    @keyframes ring {
      0% {
        transform: rotateZ(0);
      }
      20% {
        transform: rotateZ(30deg);
      }
      40% {
        transform: rotateZ(0);
      }
      60% {
        transform: rotateZ(-30deg);
      }
      80% {
        transform: rotateZ(0);
      }
      90% {
        transform: rotateZ(30deg);
      }
      100% {
        transform: rotateZ(0);
      }
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
      transition: opacity 0.3s;
    }
    .fade-leave-from,
    .fade-enter-to {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
</style>
