// Queries
import PARTNER_ACTIVITIES from '@/assets/graphql/queries/experiencies/AllPartnerActivities.graphql';
import ACTIVITY_SUBFILTERS from '@/assets/graphql/queries/experiencies/ActivitySubfilters.graphql';

// Mutations
import MODIFIED_EXPERIECE_ACTIVITY from '@/assets/graphql/mutations/experience/ModifiedExperieceActivity.graphql';
import SET_PARTNERS_SUBFILTER from '@/assets/graphql/mutations/experience/SetPartnersSubfilter.graphql';

// service
import apolloService from '@/services/apolloService';

import type {
  ActivityInformationResponse,
  ActivitySubfiltersResponse,
  ModifyExperiencesActivityResponse,
  SetPartnersSubfilterResponse,
} from './types';

export default class {
  // QUERIES
  static getAllPartnerActivities() {
    return apolloService.query<ActivityInformationResponse>(
      PARTNER_ACTIVITIES,
      undefined,
      {
        fetchPolicy: 'network-only',
      },
    );
  }
  static getActivitySubfilters(variables: { activity_id: number }) {
    return apolloService.query<ActivitySubfiltersResponse>(
      ACTIVITY_SUBFILTERS,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  // MUTATIONS
  static modifiyExperienceActivity(variables: {
    activity: number;
    change_request: string;
  }) {
    return apolloService.mutate<ModifyExperiencesActivityResponse>(
      MODIFIED_EXPERIECE_ACTIVITY,
      variables,
    );
  }

  static setPartnersSubfilter(variables: {
    subfilters: string[];
    activity_id: number;
  }) {
    return apolloService.mutate<SetPartnersSubfilterResponse>(
      SET_PARTNERS_SUBFILTER,
      variables,
    );
  }
}
