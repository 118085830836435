import { defineStore } from 'pinia';
import * as Sentry from '@sentry/vue';
import { useNotificationsStore } from '@/stores/notificationsStore';
import { useAlertsStore } from '@/stores/alertsStore';

import {
  type PartnersProfile,
  type UpdateProfilePayload,
  type PartnerComercialProfileInput,
  type CompanyInformation,
  type PartnerExperienceSurvey,
} from '@/models/ProfileInterface';

import {
  type Review,
  type PaginatedReviewsPayload,
  type ReviewsActivities,
} from '@/models/ReviewsInterface';
import type {
  ActivityInformation,
  PartnerBenefits,
} from '@/models/ProfileInterface';
import ReviewsApiService from '@/api/reviews';
import ProfileApiService from '@/api/profile';
import ExperiencesApiService from '@/api/experiences';
import NewsletterApiService from '@/api/newsletter';
import type { PartnerNewsletter } from '@/models/Newsletter';
import HomeApiService from '@/api/home';
import { TLD } from '@/helpers/getLastTld';
import { axiosApi } from '@/config/http';

export const usePartnerInfoStore = defineStore('partnerInfo', {
  state: () => ({
    profile: {
      id: '',
      profile_image: '',
      fantasy_name: '',
      full_name: '',
      qr_code: null,
      partner_locations: [],
      user: {
        first_name: '',
        last_name: '',
        email: '',
      },
      currency: '$',
      has_to_update_mails: false,
      role: '',
      partner_type: '',
    } as PartnersProfile,
    statusProfile: {
      errors: '',
      message: '',
    },
    reviews: [] as Review[],
    reviewsTotal: 0,
    reviewsAverageRating: 0,
    reviewsActivities: [] as ReviewsActivities[],
    reviewsPage: 1,
    reviewsHasNext: false,
    loadingReviews: false,
    partnerActivities: [] as ActivityInformation[],
    selectedPartnerActivity: null as number | null,
    comercialProfile: {
      id: '',
      first_name: '',
      last_name: '',
      contact_phone: '',
      contact_email: '',
    },
    submitStatus: {
      form: '',
      type: '',
      message: '',
    },
    newsLetter: [] as PartnerNewsletter[],
    benefits: [] as PartnerBenefits[],
    experienceSurvey: null as PartnerExperienceSurvey | null,
    companyInformation: {
      email_administration: '',
      phone_administration: '',
      company_cuit: '',
      company_name: '',
      company_payment_mode: '',
      company_email: '',
      mercadopago_email: '',
      company_phone: '',
      first_file: '',
      second_file: '',
      third_file: '',
    } as CompanyInformation,
  }),
  actions: {
    async fetchPartnerProfile() {
      const response = await ProfileApiService.getPartnerProfile();

      const { partners_profile } = response.data;
      if (partners_profile) {
        const store = useNotificationsStore();
        Sentry.setUser({ email: partners_profile.user.email });
        this.profile = partners_profile;
        store.stablishWebsocketConnection({
          partnerUserId: partners_profile.id,
        });
      }
    },
    async updateAccountingDocument(payload: {
      document: any;
      document_name: string;
    }) {
      await ProfileApiService.updateAccountingDocument(payload);

      await this.getPartnerCompanyInformation();
    },
    setProfile(payload: PartnersProfile) {
      this.profile = payload;
    },
    async updateProfile({
      password,
      newPassword,
      newPasswordRep,
    }: UpdateProfilePayload) {
      const response = await ProfileApiService.updatePartnerPassword({
        old_password: password,
        new_password: newPassword,
        new_password_rep: newPasswordRep,
      });

      const { message, errors } = response.data!.partners_update_password;
      if (message) {
        this.statusProfile.message = message;
      } else {
        this.statusProfile.errors = errors.old_password[0];
      }
    },
    async getReviews(payload: PaginatedReviewsPayload) {
      this.loadingReviews = true;
      const response = await ReviewsApiService.getPaginatedReviews({
        page: payload.page,
        date: payload.date,
        code: payload.code,
        activity: payload.activity,
        rating: payload.rating,
        review: payload.review,
        location: payload.location,
      });
      const {
        results: reviews,
        page_info: { number: page, has_next: hasNext },
      } = response.data.paginated_partners_reviews;
      if (page === 1) {
        this.reviews = reviews;
      } else {
        this.reviews = this.reviews.concat(reviews);
      }
      this.reviewsPage = page;
      this.reviewsHasNext = hasNext;
      this.loadingReviews = false;
    },

    async getReviewsInformation() {
      const response = await ReviewsApiService.getPartnerReviewsInformation();
      this.reviewsAverageRating =
        response.data.partners_reviews_information.reviews_average;
      this.reviewsTotal =
        response.data.partners_reviews_information.reviews_total;
    },

    async getNewsLetter() {
      const response = await NewsletterApiService.getPartnerNewsletters();
      this.newsLetter = response.data.partner_newsletter;
    },

    async getBenefits() {
      const alertsStore = useAlertsStore();
      axiosApi
        .get('v1/benefits')
        .then(response => {
          this.benefits = response?.data?.response;
        })
        .catch(errors => {
          alertsStore.addReplace({
            type: 'error',
            message: errors?.message,
          });
        });
    },

    async getExperienceSurvey() {
      const response = await HomeApiService.getExperienceSurvey();

      this.experienceSurvey = response.data.partner_user_external_form;
    },

    async getReviewsActivities() {
      const response = await ReviewsApiService.getPartnerReviewsActivities();
      const defaultValue = [
        {
          name: 'Todas',
          value: '',
        },
      ];
      const data = response.data.partners_reviews_activities.map(item => {
        return {
          name: item.activity_internal_name,
          value: item.activity.id,
        };
      });
      const activities = defaultValue.concat(data);
      this.reviewsActivities = activities;
    },
    resetReviews() {
      const payload = {
        loadingReviews: true,
        reviewsHasNext: true,
        reviews: [],
        reviewsPage: 0,
      };
      this.loadingReviews = payload.loadingReviews;
      this.reviewsHasNext = payload.reviewsHasNext;
      this.reviews = payload.reviews;
      this.reviewsPage = payload.reviewsPage;
    },
    async getAllPartnerActivities() {
      const response = await ExperiencesApiService.getAllPartnerActivities();

      const { partner_activities } = response.data;
      this.partnerActivities = partner_activities;
    },
    setSelectedPartnerActivity(id: null | number) {
      this.selectedPartnerActivity = id;
    },
    async getPartnersComercialProfile() {
      const response = await ProfileApiService.getPartnerComercialProfile();
      const { contact_email, contact_phone, first_name, last_name, id } =
        response.data.partners_comercial_profile;
      this.comercialProfile = {
        id,
        contact_email,
        contact_phone,
        first_name,
        last_name,
      };
    },
    async updatePartnerComercialProfile(payload: PartnerComercialProfileInput) {
      const input_data = { ...payload };

      const response = await ProfileApiService.updatePartnersComercialProfile({
        input_data,
      });

      const { errors, message } =
        response.data!.update_partner_comercial_profile;
      this.setSubmitStatus({ form: 'comercial', errors, message });
      await this.getPartnersComercialProfile();
    },
    async updatePartnerAdministrationProfile(payload: {
      administration_email: string;
      administration_phone: string;
    }) {
      const response =
        await ProfileApiService.updatePartnersAdministrationProfile(payload);

      const { errors, message } =
        response.data!.update_partner_administration_profile;
      this.setSubmitStatus({ form: 'administration', errors, message });
      await this.getPartnerCompanyInformation();
    },
    setSubmitStatus({
      errors,
      message,
      form,
    }: {
      errors: string[];
      message: string;
      form: string;
    }) {
      if (!errors) {
        this.submitStatus = { form, type: 'success', message };
      } else {
        this.submitStatus = {
          form,
          type: 'error',
          message: `${Object.values(errors)[0]}`,
        };
      }
      setTimeout(() => {
        this.submitStatus = { form: '', type: '', message: '' };
      }, 5000);
    },
    async getPartnerCompanyInformation() {
      const response = await ProfileApiService.getPartnerCompanyInformation();

      this.companyInformation = response.data.partners_profile;
    },
  },
  getters: {
    getProfile: state => state.profile,
    taxIdName: () => {
      const taxName = {
        ar: 'CUIT',
        pe: 'RUC',
        cl: 'RUT/RUN',
        uy: 'RUT',
      } as const;
      return taxName[TLD as keyof typeof taxName];
    },
    documents: state => {
      const docs = {
        ar: {
          first: {
            title: 'Constancia AFIP',
            url: state.companyInformation.first_file,
            documentName: 'gross_income_document',
          },
          second: {
            title: 'Constancia IIBB',
            url: state.companyInformation.second_file,
            documentName: 'gross_income_document_brutos',
          },
          third: {
            title: 'Certificados de exención/exclusión',
            url: state.companyInformation.third_file,
            documentName: 'gross_income_document_impositivo',
          },
        },
        cl: {
          first: {
            title: 'Consulta Tributaria de terceros',
            url: state.companyInformation.first_file,
            documentName: 'gross_income_document',
          },
        },
        pe: {
          first: {
            title: 'Constancia de ficha RUC',
            url: state.companyInformation.first_file,
            documentName: 'gross_income_document',
          },
        },
        uy: {
          first: {
            title: 'Tarjeta RUT',
            url: state.companyInformation.first_file,
            documentName: 'gross_income_document',
          },
        },
        mx: {
          first: {
            title: 'Constancia de Situación Fiscal',
            url: state.companyInformation.first_file,
            documentName: 'gross_income_document', //TODO atajar desde el back
          },
          second: {
            title: 'Carátula Bancaria',
            url: state.companyInformation.second_file,
            documentName: 'gross_income_document_brutos', //TODO atajar desde el back
          },
        },
      } as const;
      return docs[TLD as keyof typeof docs];
    },
    partnerProfile: state => {
      const partnerProfileData = [
        {
          title: 'Razón social',
          info: state.companyInformation.company_name,
        },
        {
          title: 'CUIT',
          info: state.companyInformation.company_cuit,
        },

        {
          title: 'Modo de pago',
          info: state.companyInformation.company_payment_mode,
        },
        {
          title: 'Contacto de administración',
          info: state.companyInformation.email_administration,
        },
        {
          title: 'Teléfono de administración',
          info: state.companyInformation.phone_administration,
        },
      ];
      return partnerProfileData;
    },
    getComercialProfile: state => {
      const commercialProfileData = [
        {
          title: 'Nombre',
          info: state.comercialProfile.first_name,
        },
        {
          title: 'Apellido',
          info: state.comercialProfile.last_name,
        },
        {
          title: 'Teléfono',
          info: state.comercialProfile.contact_phone,
        },
        {
          title: 'Correo electrónico',
          info: state.comercialProfile.contact_email,
        },
      ];
      return commercialProfileData;
    },
  },
});
