<template>
  <transition-group
    name="list-adjust"
    tag="div"
    class="bs-toast-wrapper"
    :class="[position]"
  >
    <bs-toast
      v-for="alertData in alerts"
      :id="alertData.id"
      :key="alertData.id"
      :message="alertData.message"
      :type="alertData.type"
      class="list-adjust-item full-width"
      @close-alert="removeAlert"
    />
  </transition-group>
</template>
<script setup lang="ts">
  import BsToast from '@/components/Common/BsToastNotification/BsToastNotification.vue';
  import { computed } from 'vue';
  import { useAlertsStore } from '@/stores/alertsStore';

  const alertsStore = useAlertsStore();

  type Props = {
    initialPosition?: string;
  };

  const props = withDefaults(defineProps<Props>(), {
    initialPosition: '',
  });

  //created
  if (props.initialPosition) {
    alertsStore.setPosition(props.initialPosition);
  }

  const alerts = computed(() => {
    return alertsStore.alerts;
  });
  // 'top-right', 'bottom-right', 'top-left', 'bottom-left', 'bottom-center', 'top-center'
  const position = computed(() => {
    return alertsStore.position;
  });

  function removeAlert(id: number) {
    alertsStore.remove(id);
  }
</script>
<style lang="scss">
  .bs-toast-wrapper {
    z-index: 10000;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: fixed;
    width: 100%;

    &.top-left,
    &.top-right,
    &.top-center {
      top: 0;
    }

    &.bottom-left,
    &.bottom-right,
    &.bottom-center {
      bottom: 0;
    }

    &.top,
    &.bottom {
      &-left {
        left: 0;
      }

      &-right {
        right: 0;
      }

      &-center {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
</style>
