<template>
  <div class="select-activity-locations-step">
    <main>
      <div class="people bb-mb-3 bb-row">
        <p class="bb-description-5" :style="{ color: categoryColor }">
          {{ reservationScheduleStore.currentBoxName }}
        </p>
        <span
          v-if="isCurrentActivitySelected && selectedActivity"
          class="bb-row bb-pt-1"
        >
          <TwoUsersIcon class="icon bb-mx-1" />
          <p class="bb-description-3">
            Para {{ selectedActivity.participants }} {{ people }}.
          </p>
        </span>
      </div>
      <p class="bb-pb-2">Seleccioná actividad</p>
      <Dropdown
        placeholder="Seleccionar"
        :options="props.activitiesAvailable"
        option-label="name"
        class="full-width"
        :model-value="selectedActivity"
        @update:model-value="setActivityValue"
      />
      <p v-if="selectedActivity" class="bb-mt-4">Seleccioná sucursal</p>
      <Dropdown
        v-if="selectedActivity"
        class="bb-mt-2 full-width"
        :options="activitiesLocationsAvailable"
        option-label="name"
        placeholder="Seleccionar"
        :model-value="currentActivityLocation"
        @update:model-value="setActivityLocation"
      />
    </main>
    <button-custom
      :disabled="selectedActivity === null || currentActivityLocation === null"
      text="Continuar"
      class-name="--w-250"
      class="bb-mt-5 bb-ml-1"
      @click="showInputs"
    />
  </div>
</template>
<script setup lang="ts">
  import ButtonCustom from '@/components/Common/ButtonCustom/ButtonCustom.vue';

  import TwoUsersIcon from '@/assets/icons/TwoUsers.svg';
  import pluralizate from '@/helpers/pluralizate';
  import { computed, ref } from 'vue';
  import { useReservationScheduleStore } from '@/stores/reservationScheduleStore';

  import type { Activity } from '@/models/ActivityInterface';
  import type { PartnerLocation } from '@/models/ProfileInterface';
  import Dropdown from 'primevue/dropdown';

  const reservationScheduleStore = useReservationScheduleStore();

  interface Props {
    activitiesAvailable: Activity[];
  }

  export interface ActivityLocation {
    currentActivity: Activity;
    currentActivityLocation: PartnerLocation;
  }

  const props = defineProps<Props>();

  const emit = defineEmits<{
    'confirm-activity-location': [ActivityLocation];
  }>();

  const activitiesLocationsAvailable = ref<PartnerLocation[]>([]);
  const isCurrentActivitySelected = ref(false);
  const selectedActivity = ref<Activity>();
  const currentActivityLocation = ref<PartnerLocation | null>(null);

  const categoryColor = computed(() => {
    return (
      reservationScheduleStore.currentReservationActivity[0].category?.color ??
      '#000'
    );
  });

  /**
   * Verify the participants quantity to display an s.
   * @returns {string} cant be null.
   */
  const people = computed(() => {
    return selectedActivity.value
      ? pluralizate(selectedActivity.value.participants, 'persona')
      : '';
  });

  /**
   * Set the activity and get the locations based in the activity id.
   * @param activity {Object} cant be null.
   */
  function setActivityValue(activity: Activity) {
    isCurrentActivitySelected.value = true;
    selectedActivity.value = activity;
    activitiesLocationsAvailable.value = [];
    getActivityLocations(activity.id);

    reservationScheduleStore.currentBoxName =
      selectedActivity.value?.public_box_name ?? '';
  }
  /**
   * Handle select custom event to store the location.
   * @param location
   */
  function setActivityLocation(location: PartnerLocation) {
    currentActivityLocation.value = location;
  }
  async function getActivityLocations(activity: string) {
    await reservationScheduleStore.checkActivityLocations(activity);
    activitiesLocationsAvailable.value =
      reservationScheduleStore.currentReservationActivityLocations;
  }
  function showInputs() {
    emit('confirm-activity-location', {
      currentActivity: selectedActivity.value!,
      currentActivityLocation: currentActivityLocation.value!,
    });
  }
</script>
<style lang="scss" scoped>
  .select-activity-locations-step {
    position: relative;
    min-height: 290px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .people {
      .icon {
        width: 20px;
        color: $DARK-GRAY;
      }
    }

    .WrapperFull {
      width: 100%;
    }
  }
</style>
