function getCurrency() {
  const regex = window.location.hostname.match(/\.([^.]+)$/) ?? [];

  if (regex[1] === 'pe') {
    return 'S/';
  } else if (regex[1] === 'es') {
    return '€';
  } else {
    return '$';
  }
}

export default getCurrency;
