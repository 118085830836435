<template>
  <div class="BaseView">
    <toast-wrapper initial-position="bottom-left" />
    <router-view></router-view>
    <VueQueryDevtools />
  </div>
</template>
<script setup lang="ts">
  import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
  import ToastWrapper from '@/components/Common/BsToastWrapper/BsToastWrapper.vue';

  const environment = import.meta.env.VITE_ENVIRONMENT;
  if (environment && environment != 'prod') {
    document.title = `${environment.toUpperCase()} / ${document.title}`;
  }
</script>
<style lang="scss" scoped>
  .BaseView {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
  }
</style>
