<template>
  <div class="bs-toast-notification" :class="[alertType.className]">
    <div class="toast-content">
      <alertType.icon class="toast-icon" @click="closeAlert" />
      <span class="toast-message" v-html="message"></span>
    </div>
    <CrossIcon class="toast-close-btn" @click="closeAlert" />
  </div>
</template>
<script setup lang="ts">
  import CrossIcon from '@/assets/icons/Cross.svg';
  import SuccessIcon from '@/assets/icons/ConfirmIcon.svg';
  import InfoIcon from '@/assets/icons/Info.svg';
  import { computed } from 'vue';

  type Props = {
    id: number;
    message?: string;
    type?: 'success' | 'info' | 'error';
  };

  const props = withDefaults(defineProps<Props>(), {
    id: 0,
    message: '',
    type: 'success',
  });

  const emit = defineEmits<{ closeAlert: [id: number] }>();

  const alertType = computed(() => {
    const types = {
      success: {
        className: 'success-type',
        icon: SuccessIcon,
      },
      error: {
        className: 'error-type',
        icon: CrossIcon,
      },
      info: {
        className: 'info-type',
        icon: InfoIcon,
      },
    };
    return types[props.type];
  });

  function closeAlert() {
    emit('closeAlert', props.id);
  }
</script>
<style lang="scss">
  .bs-toast-notification {
    position: relative;
    border-radius: 8px;
    border: 1px solid;
    padding: 12px;
    margin: 12px;
    padding-right: 46px;

    .toast-content {
      display: flex;
      align-items: center;
      gap: 12px;

      .toast-message {
        font-weight: 500;
      }
    }

    .toast-icon {
      display: flex;
      width: 20px;
      height: 20px;
    }

    .toast-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 8px;
      margin-right: 8px;
      cursor: pointer;
      border: 0;
      background-color: transparent;
      width: 26px;
      height: 26px;
      color: $DARK-GRAY;
    }

    &.success-type {
      background-color: #f0faef;
      border-color: #42bf2e80;

      .toast-icon {
        color: $WHITE;
        fill: $SUCCESS;
      }
    }

    &.error-type {
      background-color: #ffecf1;
      border-color: #ff074a80;

      .toast-icon {
        color: $WHITE;
        fill: $ERROR;
        width: $HUGE;
        margin: -10px;
      }
    }

    &.info-type {
      background-color: #eff7fd;
      border-color: #2e93e580;

      .toast-icon {
        color: #2e93e5;
        width: $HUGE;
      }
    }
  }
</style>
