<template>
  <div class="counter-display">
    <div
      class="icon-wrapper decrement"
      :class="{ disable: !allowDecrement }"
      @click="decrement"
    >
      -
    </div>
    <div class="value bb-bold bb-text-center bb-title-3">{{ value }}</div>
    <div
      class="icon-wrapper increment"
      :class="{ disable: !allowIncrement }"
      @click="increment"
    >
      +
    </div>
  </div>
</template>
<script setup lang="ts">
  import { ref } from 'vue';

  interface Props {
    min?: number;
    max?: number;
    defaultValue?: number;
  }

  const props = withDefaults(defineProps<Props>(), {
    min: 1,
    max: 1,
    defaultValue: 0, // Number different from minValue
  });

  const emit = defineEmits<{ change: [number] }>();

  const value = ref<number>(1);
  const allowIncrement = ref<boolean>(true);
  const allowDecrement = ref<boolean>(false);

  // Created
  value.value = props.defaultValue || props.min;

  if (props.min === props.max) {
    allowIncrement.value = false;
    allowDecrement.value = false;
  }
  if (value.value > props.min) {
    allowDecrement.value = true;
  }
  if (value.value >= props.max) {
    allowIncrement.value = false;
  }

  function increment(): void {
    if (value.value < props.max) {
      value.value += 1;
      emit('change', value.value);
      allowDecrement.value = true;
      allowIncrement.value = !(value.value === props.max);
    }
  }
  function decrement(): void {
    if (value.value > props.min) {
      value.value -= 1;
      emit('change', value.value);
      allowIncrement.value = true;
      allowDecrement.value = !(value.value === props.min);
    }
  }
</script>
<style lang="scss" scoped>
  .counter-display {
    position: relative;
    user-select: none;

    > div {
      display: inline-block;
      vertical-align: top;
    }

    .value {
      color: $PRIMARY;
      min-width: 25px;
      line-height: 35px;
      margin: 0 3px;
    }

    .icon-wrapper {
      position: relative;
      font-family: $COPY-FONT;
      font-weight: bold;
      line-height: 33px;
      font-size: 20px;
      text-align: center;
      min-width: 20px;
      cursor: pointer;

      &.decrement {
        font-size: 25px;
      }

      &.disable {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
</style>
