<template>
  <div class="partners-app">
    <div v-if="!isLoading">
      <navigation-bar @nav-bar="showNavBar" />
      <div class="bb-row">
        <navigation-sidebar class="navigation-sidebar" />
        <transition name="slide-navbar">
          <navigation-bar-mobile
            v-if="navBarMobile"
            @close-nav-bar="showNavBar"
          ></navigation-bar-mobile>
        </transition>
        <router-view
          v-if="!navBarMobile"
          class="view bb-flex-grow"
        ></router-view>
      </div>
      <email-confirmation-modal
        :show="showEmailModal"
        @close="closeEmailModal"
      />
      <experience-survey-modal
        :show="showSurveyModal"
        @close="closeSurveyModal"
      />
      <news-letter-modal
        v-if="firstNewsLetterModal"
        :show="showFirstNewsModal"
        :data="firstNewsLetterModal"
        @close="closeFirstLetterModal"
      />
      <news-letter-modal
        v-if="secondNewsLetterModal"
        :show="showSecondNewsModal"
        :data="secondNewsLetterModal"
        @close="closeSecondLetterModal"
      />
      <tour-steps v-if="showTourSteps" />
    </div>
    <div v-else class="partners-app-loader bb-column bb-justify-content-center">
      <Vue3Lottie
        :animation-data="loaderJSON"
        :height="200"
        :width="200"
        :auto-play="true"
      />
    </div>
  </div>
  <VueQueryDevtools />
</template>
<script setup lang="ts">
  import { Vue3Lottie } from 'vue3-lottie';
  import { VueQueryDevtools } from '@tanstack/vue-query-devtools';

  import NavigationBar from '@/components/Navigation/NavigationBar/NavigationBar.vue';
  import EmailConfirmationModal from '@/components/Common/EmailConfirmationModal/EmailConfirmationModal.vue';
  import NavigationSidebar from '@/components/Navigation/NavigationSidebar/NavigationSidebar.vue';
  import NavigationBarMobile from '@/components/Navigation/NavigationBarMobile/NavigationBarMobile.vue';
  import TourSteps from '@/components/Common/TourSteps/TourSteps.vue';
  import NewsLetterModal, {
    type NewsLetterContent,
  } from '@/components/Common/NewsLetterModal/NewsLetterModal.vue';

  import loaderJSON from '@/assets/lotties/loader.json';
  import ExperienceSurveyModal from '@/components/Home/ExperienceSurveyModal/ExperienceSurveyModal.vue';

  import { TLD } from '@/helpers/getLastTld';
  import { computed, onBeforeMount, ref } from 'vue';
  import { useReservationConfigurationStore } from '@/stores/reservationConfigurationStore';
  import { usePartnerInfoStore } from '@/stores/partnerInfoStore';
  import { useRouter } from 'vue-router';
  import { useLiquidationsStore } from '@/stores/liquidationsStore';
  import { useNotificationsStore } from '@/stores/notificationsStore';

  import AuthApiService from '@/api/auth';
  import HomeApiService from '@/api/home';

  const partnerInfoStore = usePartnerInfoStore();
  const liquidationsStore = useLiquidationsStore();
  const notificationsStore = useNotificationsStore();
  const reservationConfigurationStore = useReservationConfigurationStore();

  const router = useRouter();

  const isLogged = ref(false);
  const showEmailModal = ref(false);
  const showInstructives = ref(false);
  const navBarMobile = ref(false);
  const isLoading = ref(true);
  const showTourSteps = ref(false);
  const showSurveyModal = ref(false);
  const showFirstNewsModal = ref(false);
  const showSecondNewsModal = ref(false);

  const hasToUpdateEmailsInformation = computed(() => {
    return partnerInfoStore.profile.has_to_update_mails;
  });
  const firstNewsLetterModal = computed<NewsLetterContent>(() => {
    const [newsLetter] = partnerInfoStore.newsLetter;
    return newsLetter;
  });
  const secondNewsLetterModal = computed<NewsLetterContent>(() => {
    const [, newsLetter] = partnerInfoStore.newsLetter;
    return newsLetter;
  });
  const experienceSurveyModalStatus = computed(() => {
    return partnerInfoStore.experienceSurvey !== null;
  });
  const hasExperienceSurveyModal = computed(() => {
    return (
      experienceSurveyModalStatus.value &&
      partnerInfoStore.experienceSurvey!.visible === true
    );
  });

  onBeforeMount(async () => {
    const response = await AuthApiService.partnerLogged();

    isLogged.value = await response.data.partners_logged.logged;
    if (!isLogged.value) {
      router.push({ name: 'login' });
    } else {
      Promise.allSettled([
        partnerInfoStore.fetchPartnerProfile(),
        partnerInfoStore.getPartnerCompanyInformation(),
        liquidationsStore.getLiquidationsPreferences(),
        partnerInfoStore.getNewsLetter(),
        partnerInfoStore.getBenefits(),
        partnerInfoStore.getExperienceSurvey(),
        reservationConfigurationStore.getExternalForms(),
        getPartnerNotifications(),
      ]).then(() => {
        isLoading.value = false;
        showInstructivesArg();
        showNewsLetterModal();
      });
    }
  });

  async function getPartnerNotifications() {
    const response = await HomeApiService.getPartnerNotifications();

    notificationsStore.partnerNotifications =
      response.data.partner_notifications;
  }

  function showModal() {
    hasToUpdateEmailsInformation.value
      ? (showEmailModal.value = true)
      : showTour();
  }
  function showExperienceSurveyModal() {
    hasExperienceSurveyModal.value
      ? (showSurveyModal.value = true)
      : showModal();
  }
  function closeEmailModal() {
    showEmailModal.value = false;
    showTour();
  }
  function closeSurveyModal() {
    showSurveyModal.value = false;
    showModal();
  }
  function showTour() {
    showTourSteps.value = true;
  }
  function showInstructivesArg() {
    if (TLD === 'ar') {
      showInstructives.value = true;
    }
  }
  function showNavBar() {
    navBarMobile.value = !navBarMobile.value;
  }
  async function showNewsLetterModal() {
    firstNewsLetterModal.value
      ? (showFirstNewsModal.value = true)
      : showExperienceSurveyModal();
  }
  function closeFirstLetterModal(event: boolean) {
    showFirstNewsModal.value = false;
    if (secondNewsLetterModal.value) {
      showSecondNewsModal.value = event;
    } else if (!showSecondNewsModal.value) {
      showExperienceSurveyModal();
    }
  }
  function closeSecondLetterModal() {
    showSecondNewsModal.value = false;
    showExperienceSurveyModal();
  }
</script>
<style lang="scss" scoped>
  .partners-app {
    position: relative;
    height: 100%;
    width: 100%;

    &-loader {
      width: 100%;
      height: 100vh;
    }

    .view {
      background-color: lighten($GRAY-LIGHT-1, 10%);
    }

    .navigation-sidebar {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
</style>
