const esLa = {
  Activá: 'Activa',
  acá: 'aquí',
  Agregá: 'Agrega',
  Ahorrá: 'Ahorra',
  Completá: 'Completa',
  comunicate: 'comunícate',
  configurá: 'configura',
  contactá: 'contacta',
  contás: 'cuentas',
  copiá: 'copia',
  Creá: 'Crea',
  Debés: 'Debes',
  debés: 'debes',
  Descargá: 'Descarga',
  descartá: 'descarta',
  desconocés: 'desconoces',
  Deseás: 'Deseas',
  Elegí: 'Elige',
  eliminará: 'eliminara',
  Empezá: 'Empieza',
  Establecé: 'Establece',
  guardá: 'guarda',
  Ingresá: 'Ingresa',
  ingresá: 'ingresa',
  Imprimí: 'Imprime',
  Mirá: 'Mira',
  Mostralo: 'Muéstralo',
  Podés: 'Puedes',
  podés: 'puedes',
  Querés: 'Quieres',
  querés: 'quieres',
  quitá: 'quita',
  Recordá: 'Recuerda',
  sabés: 'sabes',
  Seleccioná: 'Selecciona',
  Subí: 'Sube',
  Tenés: 'Tienes',
  tenés: 'tienes',
  Verificá: 'Verifica',
  verificá: 'verifica',
} as const;

export default esLa;
